import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => {
    return {
        root: theme.v2
            ? {
                  padding: '0 28px 28px',
              }
            : {
                  paddingTop: '0',
                  '@media print': {
                      paddingRight: '1px',
                  },
                  '.printMedia &': {
                      paddingRight: '1px',
                  },
              },

        //Table styles
        table: {
            '--table-row-end-padding': theme.v2 ? '15px' : '28px',
            '--table-cell-padding': theme.v2 ? '0 25px 0 0' : '0 4px',
            '--table-cell-normal-width-max': '140px',
            '--table-cell-height': '40px',
            '--table-header-height': theme.v2 ? '40px' : '44px',

            borderSpacing: '0',
            borderCollapse: 'separate',
            display: 'grid',
        },
        tbody: {
            display: 'contents',
        },
        thead: {
            display: 'contents',
        },
        tr: {
            display: 'contents',
            ':hover td': {
                backgroundColor: theme.colors.bgHover,
            },

            [`&:last-child > .${classes.td}`]: {
                borderBottom: theme.v2 ? 'none' : null,
            },

            [`&:nth-of-type(even) > .${classes.td}`]: {
                backgroundColor: theme.v2 ? null : theme.colors.lightGrey40,
            },
        },
        th: {
            ...theme.mixin.truncate,
            display: 'block',
            textAlign: 'left',
            height: 'var(--table-header-height)',
            lineHeight: 'var(--table-header-height)',
            padding: 'var(--table-cell-padding)',

            ...(theme.v2
                ? {
                      ...theme.typgrphy.bodyStrong,
                      color: theme.colors.textSubtle,
                      borderBottom: `1px solid ${theme.colors.borders}`,
                  }
                : {
                      background: theme.colors.lightGrey40,
                  }),

            '&:first-of-type': {
                paddingLeft: theme.v2 ? 0 : 'var(--table-row-end-padding)',
            },
            '&:last-child': {
                paddingRight: theme.v2 ? 0 : 'var(--table-row-end-padding)',
            },
        },
        td: {
            display: 'block',
            padding: 'var(--table-cell-padding)',
            height: 'var(--table-cell-height)',
            lineHeight: 'var(--table-cell-height)',
            ...theme.mixin.truncate,

            ...(theme.v2
                ? {
                      borderBottom: `1px dashed ${theme.colors.borders}`,
                      color: theme.colors.textSubtle,
                      ...theme.mixin.bodyNormal,

                      button: {
                          color: theme.colors.textSubtle,
                      },
                  }
                : {}),

            [`&.${classes.bold}`]: {
                ...(theme.v2
                    ? theme.typgrphy.bodyStrong
                    : {
                          fontWeight: theme.font.bold,
                      }),
            },
            '&:first-of-type': {
                paddingLeft: 'var(--table-row-end-padding)',
                paddingRight: theme.v2 ? '8px' : null,
            },
            '&:last-of-type': {
                paddingRight: 'var(--table-row-end-padding)',
            },
            '.printMedia &': {
                display: 'flex',
                overflow: 'visible',
                paddingTop: '4px',
                paddingBottom: '4px',
                alignItems: 'center',
                height: 'auto',
                minHeight: 'var(--table-cell-height)',
            },
            '@media print': {
                display: 'flex',
                overflow: 'visible',
                paddingTop: '4px',
                paddingBottom: '4px',
                alignItems: 'center',
                height: 'auto',
                minHeight: 'var(--table-cell-height)',
            },
        },

        //options
        textAlign_center: {
            textAlign: 'center',
        },

        textAlign_right: {
            textAlign: 'right',
        },

        bold: {}, //intentionally blank - needed so it can be combined with td

        //Generic elements
        horizontalBar: {
            width: '100%',
            marginTop: 'calc(calc(var(--table-cell-height) - 24px) / 2)',
        },

        favicon: {
            width: '16px',
            height: '16px',
            marginRight: '10px',
            verticalAlign: 'middle',
        },

        //TODO move into it's own component
        navigation: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            padding: theme.v2 ? '12px 12px 0' : '12px',

            '& div': {
                margin: '0 12px',

                ...(theme.v2
                    ? theme.typgrphy.bodyNormal
                    : {
                          fontSize: theme.elements.cardTable?.fontSize,
                      }),
            },
            '& .MuiIconButton-root': {
                border: theme.v2 ? `1px solid ${theme.colors.uiGrayDarker}` : null,
                backgroundColor: theme.v2 ? null : theme.colors.primaryBlue20,
                padding: theme.v2 ? '4px' : null,

                '& svg': {
                    fill: theme.v2 ? theme.colors.icons : theme.colors.primaryBlue100,
                },
                '@media print': {
                    display: 'none',
                },
                '.printMedia &': {
                    display: 'none',
                },
            },
            '& .MuiIconButton-root.Mui-disabled': {
                backgroundColor: theme.v2 ? theme.colors.bgDisabled : theme.colors.lightGrey40,
                border: theme.v2 ? '0' : null,
                '& svg': {
                    fill: theme.v2 ? theme.colors.textDisabled : null,
                },
            },
        },
    };
});
