import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';
import merge from 'lodash/merge';
import {CardTableField} from '..';

export type TableStyleArgs = {
    fields: CardTableField<any>[];
    items: any[];
    rowHeight?: string;
};

export default makeStyles<Theme, TableStyleArgs>((theme: Theme) => {
    const versionStyles = theme.elements.cardTable;

    const cell = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        '&:first-child': {
            justifyContent: 'start',
        },
        '&.sorting': {
            fontWeight: theme.font.bold,
        },
        ...(versionStyles.cell || {}),
    };

    return {
        table: ({fields, items, rowHeight}: TableStyleArgs) => ({
            display: 'grid',
            gridTemplateColumns: fields.map((d) => d.width || '1fr').join(' '),
            gridTemplateRows: `${versionStyles.headerHeight} repeat(${items.length}, ${
                rowHeight || versionStyles.rowHeight
            })`,
            cursor: 'default',
        }),

        header: {
            display: 'contents',
        },

        row: {
            display: 'contents',
            ...(versionStyles.row || {}),
        },

        cell,

        cellContent: {
            ...theme.mixin.truncate,
        },

        headerCell: merge(
            {
                cursor: 'pointer',
                paddingRight: '3px',
                fontSize: '13px',
                '&:last-child': {
                    paddingRight: 0,
                },
            },
            cell,
            versionStyles.headerCell,
        ),

        disabledSort: {
            cursor: 'default',
        },

        sorting: {},

        headerCellContent: {
            display: 'flex',
            alignItems: 'center',
            gap: '3px',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
            fontWeight: theme.font.bold,
            '&.sorting:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                left: 0,
                bottom: 0,
                right: 0,
                width: '100%',
                borderBottom: `4px solid ${versionStyles.headerSortingColor}`,
            },
        },

        headerLabel: {
            ...theme.mixin.truncate,
            '.imgExport &': {
                display: 'block',
                position: 'relative',
                wordBreak: 'break-all',
                lineHeight: '19px',
                maxHeight: '20px',
                paddingRight: '1em',
                whiteSpace: 'inherit',
                textOverflow: 'clip',
                '&:before': {
                    content: '"…"',
                    position: 'absolute',
                    right: '0px',
                    bottom: '1px',
                },
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    right: '0',
                    width: '1em',
                    height: '1em',
                    marginTop: '0.2em',
                    background: 'white',
                },
            },
        },

        arrowIcon: {
            display: 'flex',
            alignItems: 'center',
            '&:not($sorting)': {
                visibility: 'hidden',
            },
            ...(versionStyles.arrowIcon || {}),
        },
    };
});
