type ApiErrorType = {
    /**
     * Translation key for the error title
     */
    titleId: string;
    //title: null,//Not used
    /**
     * Translation key for the error description
     */
    descId?: string;
    /**
     * Raw description, used as a default if we want to take teh error direct from the API
     */
    desc: string;
    /**
     * If there is link text in the description add it here and make sure [link] is in the desc
     */
    linkId?: string;
};

export function errorMap(error: string): ApiErrorType {
    if (error === 'Query name must be unique') {
        return {
            titleId: 'api.error4.title',
            descId: 'api.error4.desc',
            desc: error,
        };
    }
    if (error === 'Proximity syntax not supported.') {
        return {
            titleId: 'api.error2',
            descId: 'api.error5.desc',
            desc: error,
            linkId: 'api.error5.link',
        };
    }
    return {
        titleId: 'api.error2',
        desc: error,
    };
}

function errorFilter(error: ApiErrorType) {
    return error.desc !== 'Please limit your query to 600 characters or fewer.';
}

export function parseError(error?: string) {
    if (!error) return null;
    const mappedError = errorMap(error);
    return !errorFilter(mappedError) ? null : mappedError;
}

export function parseErrors(rawErrors: string[] | null) {
    if (!rawErrors) return null;
    const mappedErrors = rawErrors.map(errorMap);
    const filteredErrors = mappedErrors.filter(errorFilter);
    return !filteredErrors.length ? null : filteredErrors;
}
