import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    GeographyCardData,
    TopauthorsBySearchCardData,
    TopAuthorsCardData,
    TophashtagsBySearchCardData,
    TopSitesBySearchCardData,
    TopTopicsBySearchCardData,
    TotalVolumeBySearchCardData,
} from 'hsi/types/cards';

type CardTablePageSetType = {[key: number]: number};
type CardTableSortType<T = any> = {
    sortDir: 'asc' | 'desc';
    sortKey: T extends any ? string : keyof T;
};

//Key = saved search ID
type CardTableSortSetType<T = any> = {[key: number]: CardTableSortType<T>};

export type CardTablesStateType = {
    page: {
        topauthors: CardTablePageSetType;
        tophashtags: CardTablePageSetType;
        topsites: CardTablePageSetType;
        topSharedURLs: CardTablePageSetType;
        geography: CardTablePageSetType;
    };
    sort: {
        topauthors: CardTableSortSetType<TopAuthorsCardData>;
        tophashtags: CardTableSortSetType<TopAuthorsCardData>;
        totalVolumeBySearch: CardTableSortSetType<TotalVolumeBySearchCardData>;
        toptopicsBySearch: CardTableSortSetType<TopTopicsBySearchCardData>;
        topsitesBySearch: CardTableSortSetType<TopSitesBySearchCardData>;
        topauthorsBySearch: CardTableSortSetType<TopauthorsBySearchCardData>;
        tophashtagsBySearch: CardTableSortSetType<TophashtagsBySearchCardData>;
        geography: CardTableSortSetType<GeographyCardData>;
    };
};

export type PaginatableTableType = keyof CardTablesStateType['page'];
export type SortableTableType = keyof CardTablesStateType['sort'];

export function isPaginableTableType(type: string): type is PaginatableTableType {
    return initialState.page.hasOwnProperty(type);
}

const initialState: CardTablesStateType = {
    page: {
        topauthors: {},
        tophashtags: {},
        topsites: {},
        topSharedURLs: {},
        geography: {},
    },
    sort: {
        topauthors: {},
        tophashtags: {},
        totalVolumeBySearch: {},
        toptopicsBySearch: {},
        topsitesBySearch: {},
        topauthorsBySearch: {},
        tophashtagsBySearch: {},
        geography: {},
    },
};

type UpdatePageAction = PayloadAction<{
    type: keyof CardTablesStateType['page'];
    savedSearchId: number;
    value: number;
}>;

type UpdateSortAction = PayloadAction<{
    type: keyof CardTablesStateType['sort'];
    savedSearchId: number;
    value: CardTableSortType;
}>;

const slice = createSlice({
    name: 'cardTables',
    initialState,
    reducers: {
        updatePage: (state, {payload: {type, savedSearchId, value}}: UpdatePageAction) => {
            if (!savedSearchId) return;

            state.page[type][savedSearchId] = value;
        },
        updateSort: (state, {payload: {type, savedSearchId, value}}: UpdateSortAction) => {
            if (!savedSearchId) return;

            state.sort[type][savedSearchId] = value;
        },
    },
});

export const {updatePage, updateSort} = slice.actions;
export default slice.reducer;
