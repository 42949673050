//A collection of methods used to convert the data returned from API calls into
//the format required by the various chart components
//Only used by the loadData method

//Utils
import capitalize from 'lodash/capitalize';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import upperfirst from 'lodash/upperFirst';

import {isArray} from 'hsi/utils/array';
import {extractEmotionId} from 'hsi/utils/chart';
import {getChangePercentage} from 'hsi/utils/misc';

import ColorScale from 'hsi/classes/ColorScale';

//Constants
import {
    EMOTIONS_CATEGORIES as emotions,
    GENDERS as genders,
    SENTIMENT_CATEGORIES as sentiment,
    TOTAL_VOLUME_TYPES as totalVolumes,
    WEEKDAYS as weekdays,
} from 'hsi/constants/config';

const getCategoricalColors = ({primaryColor, random}) => {
    const colors = Array.isArray(random) ? random : [primaryColor];
    let idx = -1;
    return () => {
        idx = (idx + 1) % colors.length;
        return colors[idx];
    };
};
const getCatsColors = ({savedSearchId, additionalQueries, linkedinChannelIds, queryColors}) => {
    const queries = [savedSearchId, ...(additionalQueries ?? []), ...(linkedinChannelIds ?? [])];
    return (id) => queryColors[queries.indexOf(id)];
};

function formatBenchmark({data, breakdown, config}) {
    const knownValuesPerBreakdown = {
        pageTypes: config.pageTypes.map((pagetype) => pagetype.value),
        emotions,
        sentiment,
    };

    const currentPeriodData = data['current' + upperfirst(breakdown)];
    const previousPeriodData = data['previous' + upperfirst(breakdown)];

    if (!currentPeriodData?.results.length > 0 && !previousPeriodData?.results.length > 0) {
        return null;
    }

    const initialAcc = [];
    switch (breakdown) {
        case 'pageTypes':
        case 'sentiment':
        case 'emotions':
            knownValuesPerBreakdown[breakdown].forEach((cat) => {
                initialAcc[cat] = {
                    name: cat,
                    currentValue: 0,
                    previousValue: 0,
                    id: cat,
                };
            });
            break;
        default:
            currentPeriodData.results.forEach((cat) => {
                initialAcc[cat.id] = {
                    name: cat.name,
                    currentValue: 0,
                    previousValue: 0,
                    id: cat.id,
                };
            });
            break;
    }
    const aggregatedData = previousPeriodData.results.reduce(
        (acc, item) => {
            item.values.forEach((entry) => {
                const id = breakdown === 'emotions' ? extractEmotionId(item.id) : item.id;
                if (acc[id]) {
                    acc[id].previousValue += entry.value;
                }
            });
            return acc;
        },
        currentPeriodData.results.reduce((acc, item) => {
            item.values.forEach((entry) => {
                const id = breakdown === 'emotions' ? extractEmotionId(item.id) : item.id;
                if (acc[id]) {
                    acc[id].currentValue += entry.value;
                }
            });
            return acc;
        }, initialAcc),
    );

    //remove zero values
    let categories = [];
    Object.values(aggregatedData).forEach((cat) => {
        if (+cat.currentValue !== 0 || +cat.previousValue !== 0) {
            categories.push(cat);
        }
    });
    categories.sort((a, b) => b.currentValue - a.currentValue);

    return {
        categories,
        currentTotal: categories.reduce((t, i) => t + i.currentValue, 0),
        previousTotal: categories.reduce((t, i) => t + i.previousValue, 0),
    };
}

function formatBenchmarkBySearch({
    data,
    additionalQueries,
    linkedinChannelIds,
    queryContext,
    config: {
        themeColors: {primaryColor, random, queryColors},
    },
}) {
    const savedSearch = data?.currentAll?.results;

    if (!data || !isArray(savedSearch)) {
        return null;
    }

    const getColor = getCatsColors({
        savedSearchId: queryContext.savedSearchId,
        additionalQueries,
        linkedinChannelIds,
        queryColors,
    });

    const getValue = (values) =>
        values.map(({id, name, value}) => ({
            id,
            name,
            value,
            color: getColor(id),
        }));

    return {
        currentValues: getValue(data.currentAll.results[0].values),
        previousValues: getValue(data.previousAll.results[0].values),
    };
}

// TODO: Maybe create a base format history function with configs?
function formatEmotionHistory({data, config: {themeColors}}) {
    const defaultResults = {
        categories: [],
        interval:
            data?.dimension1 && data?.dimension1 !== 'weeks' && data?.results.length < 25
                ? data.dimension1
                : 'days',
        series: [],
    };

    const savedSearch = data?.results;

    if (!data && !savedSearch) {
        return defaultResults;
    }

    const values = savedSearch.flatMap(({values}) => values);
    const uniqCategories = uniqBy(values, (value) => value.id);
    const sortedCategories = sortBy(uniqCategories, ({id}) => emotions.indexOf(id));

    return {
        ...defaultResults,
        categories: sortedCategories.map(({id, name}) => ({
            id: extractEmotionId(id),
            name: capitalize(name),
            color: themeColors?.emotion?.[extractEmotionId(id)] || themeColors?.primaryColor,
        })),
        series: savedSearch.map((item, index) => ({
            date: item.id,
            ...item.values.reduce(
                (acc, item) => ({
                    ...acc,
                    [extractEmotionId(item.id)]: item.value,
                }),
                {},
            ),
            index,
        })),
    };
}

function formatEmotionVolume({
    data,
    config: {
        themeColors: {emotion: colors},
    },
}) {
    const emotionResults = data?.results[0]?.values;

    if (!data || !emotionResults || !isArray(emotionResults)) {
        return null;
    }

    return emotionResults
        .filter(({id}) => emotions.includes(extractEmotionId(id)))
        .map(({id, name, value}) => {
            const emotion = extractEmotionId(id);
            return {
                color: colors[emotion],
                id: emotion,
                name: `emotions.${emotion}` || name, //T()
                value,
            };
        })
        .sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            }

            if (a.id > b.id) {
                return 1;
            }

            return 0;
        });
}

function formatGender({data}) {
    const genderResults = data?.results[0]?.values;

    if (!data || !genderResults || !isArray(genderResults)) {
        return null;
    }

    const getGenderId = (id) => {
        switch (id) {
            case 'F':
            case 'Female':
            case 'female':
                return 'female';
            case 'M':
            case 'Male':
            case 'male':
                return 'male';
            default:
                return 'unknown';
        }
    };

    const getPercentageOfTotal = (value, total, sigFig = 0) =>
        parseFloat((value / total) * 100).toFixed(sigFig);

    const values = genderResults.filter(({id}) => genders.includes(getGenderId(id)));
    const total = values.reduce((acc, {value}) => acc + value, 0);

    if (!total || total === 0) {
        return null;
    }

    return values.map(({id, value}) => ({
        id: getGenderId(id),
        name: capitalize(getGenderId(id)),
        pct: getPercentageOfTotal(value, total),
        value,
    }));
}

function formatHeatmap({
    data,
    config: {
        themeColors: {heatmap: colors, colorScaleDefault: defaultColor},
    },
}) {
    if (!data || !data.results || !data.results.length) return null;

    const allValues = data.results.flatMap(({values}) => values).map(({value}) => value);

    const colorScale = new ColorScale(allValues, colors, defaultColor);

    const days = weekdays.map((weekday) => {
        const day = find(data.results, ({id}) => id === weekday.name || id === weekday.id);

        return {
            id: weekday.id,
            name: `weekday.${weekday.name.toLowerCase()}`, //T()
            values: day?.values ?? [],
        };
    });

    const hours = Array.from(Array(24).keys()).map((key) => String(key).padStart(2, '0'));

    const results = days.map((day) => ({
        id: day.id,
        name: day.name,
        values: hours.map((hour) => {
            const {id, name, value} = find(
                day.values,
                ({name}) => String(name).substring(0, 2) === hour,
            );

            return {
                color: !!value ? colorScale.getColor(value) : colorScale.getColor(0),
                id: id ?? null,
                name: name?.length === 2 ? `${name}:00` : name?.length === 4 ? name : `${hour}:00`,
                value: value ?? 0,
            };
        }),
    }));

    return {
        buckets: !!allValues ? colorScale.getBuckets() : [],
        results: orderBy(results, ['id'], ['asc']),
    };
}

function formatGeography({data, otherResults}) {
    const geoResults = data?.results[0]?.values;

    if (!data || !geoResults || !isArray(geoResults)) {
        return null;
    }

    const authors = otherResults?.results[0]?.values?.slice(0, 10);
    const totalUniqueAuthors = authors?.reduce((acc, {value}) => acc + value, 0);

    const queryIds = data?.results.map(({id}) => id);

    const getValueByQueryId = (values, id, queryId) =>
        find(values, (value) => value.queryId === queryId && value.id === id)?.value || 0;

    const getTotalById = (values, id) =>
        values.filter((value) => value.id === id).reduce((acc, {value}) => acc + value, 0);

    const values = data.results.flatMap(({id: queryId, values}) =>
        values.map(({id, name, value}) => ({
            id,
            name,
            queryId,
            value,
        })),
    );

    return uniqBy(
        values.map(({id, name}) => {
            const authorTotal = find(authors, (author) => author?.id === id)?.value;
            return {
                id,
                name,
                authorTotal: authorTotal ?? 0,
                authorPercent:
                    authorTotal && totalUniqueAuthors ? authorTotal / totalUniqueAuthors : 0,
                total: getTotalById(values, id),
                ...Object.assign(
                    {},
                    ...queryIds.map((queryId) => ({
                        [queryId]: getValueByQueryId(values, id, queryId),
                    })),
                ),
            };
        }),
        'id',
    );
}

function formatMentionsHistory({
    data,
    additionalQueries,
    linkedinChannelIds,
    queryContext,
    config: {
        themeColors: {primaryColor, random, queryColors},
    },
}) {
    const historyResults = data?.results;

    const defaultResults = {
        categories: [],
        interval:
            data?.dimension1 && data?.dimension1 !== 'weeks' && historyResults.length < 25
                ? data.dimension1
                : 'days',
        series: [],
    };

    if (!data || !historyResults) {
        return defaultResults;
    }

    const values = historyResults.flatMap(({values}) => values);
    const uniqCategories = uniqBy(values, (value) => value.id);
    const getColor =
        !!additionalQueries || !!linkedinChannelIds
            ? getCatsColors({
                  savedSearchId: queryContext.savedSearchId,
                  additionalQueries,
                  linkedinChannelIds,
                  queryColors,
              })
            : getCategoricalColors({primaryColor, random});

    const getCategories =
        uniqCategories.length === 1 && uniqCategories[0]?.id === 'all'
            ? [
                  {
                      id: 'all',
                      name: 'Mentions over time',
                      color: primaryColor,
                  },
              ]
            : uniqCategories.map(({id, name}) => ({
                  id,
                  name,
                  color: getColor(id),
              }));

    return {
        ...defaultResults,
        categories: getCategories,
        series: historyResults.map((item, index) => ({
            date: item.id,
            ...item.values.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: item.value,
                }),
                {},
            ),
            index,
        })),
    };
}

function formatNetSentimentHistory({
    data,
    additionalQueries,
    linkedinChannelIds,
    queryContext,
    config: {
        themeColors: {primaryColor, random, queryColors},
    },
}) {
    const defaultResults = {
        categories: [],
        interval:
            data?.dimension1 && data?.dimension1 !== 'weeks' && data?.results.length < 25
                ? data.dimension1
                : 'days',
        series: [],
    };

    const savedSearch = data?.results;

    if (!data || !savedSearch) {
        return defaultResults;
    }

    const values = savedSearch.flatMap(({values}) => values);
    const uniqCategories = uniqBy(values, (value) => value.id);
    const getColor =
        !!additionalQueries || !!linkedinChannelIds
            ? getCatsColors({
                  savedSearchId: queryContext.savedSearchId,
                  additionalQueries,
                  linkedinChannelIds,
                  queryColors,
              })
            : getCategoricalColors({primaryColor, random});

    return {
        ...defaultResults,
        categories: uniqCategories.map(({id, name}) => ({
            id,
            name: capitalize(name),
            color: getColor(id),
        })),
        series: savedSearch.map((item, index) => ({
            date: item.id,
            ...item.values.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: item.value,
                }),
                {},
            ),
            index,
        })),
    };
}

function formatPageTypeBySearch({data, config}) {
    const {
        pageTypes,
        themeColors: {random, pageTypes: colors},
    } = config;
    const savedSearch = data?.results;

    if (!savedSearch || !Array.isArray(savedSearch) || !savedSearch.length) {
        return null;
    }
    return {
        categories: pageTypes.map((pageType, i) => ({
            color: colors[pageType.value] || random[i],
            id: pageType.value,
            name: pageType.label,
        })),
        results: savedSearch.map((result) => ({
            id: result.id,
            name: result.name,
            ...Object.assign(
                {},
                ...result.values.flatMap(({id, value}) => ({
                    [id]: value,
                })),
            ),
        })),
    };
}

function formatSentimentHistory({data, config: {themeColors}}) {
    const defaultResults = {
        categories: [],
        interval:
            data?.dimension1 && data?.dimension1 !== 'weeks' && data?.results.length < 25
                ? data.dimension1
                : 'days',
        series: [],
    };

    const savedSearch = data?.results;

    if (!savedSearch) {
        return defaultResults;
    }

    const values = savedSearch.flatMap(({values}) => values);
    const uniqCategories = uniqBy(values, (value) => value.id);
    const sortedCategories = sortBy(uniqCategories, ({id}) => sentiment.indexOf(id));

    return {
        ...defaultResults,
        categories: sortedCategories.map(({id, name}) => ({
            id,
            name: capitalize(name),
            color: themeColors?.sentiment?.[id] || themeColors?.primaryColor,
        })),
        series: savedSearch.map((item, index) => ({
            date: item.id,
            ...item.values.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.id]: item.value,
                }),
                {},
            ),
            index,
        })),
    };
}

function formatSentimentVolume({
    data,
    config: {
        themeColors: {sentiment: colors},
    },
}) {
    if (!data || !data?.results || !data?.results?.length) {
        return null;
    }

    const aggregated = data.results.reduce((acc, item) => {
        item.values.forEach((iv) => {
            if (!acc[iv.id]) {
                acc[iv.id] = {
                    id: iv.id,
                    color: colors[iv.id],
                    name: capitalize(iv.name),
                    value: iv.value,
                };
            } else {
                acc[iv.id].value += iv.value;
            }
        });
        return acc;
    }, {});

    return {
        categories: sentiment.filter((id) => aggregated[id]).map((id) => aggregated[id]),
        total: Object.values(aggregated).reduce((t, i) => t + i.value, 0),
    };
}

function formatSentimentVolumeBySearch({
    data,
    config: {
        themeColors: {random, sentiment: colors},
    },
}) {
    const savedSearch = data?.results;
    if (!savedSearch || !Array.isArray(savedSearch) || !savedSearch.length) {
        return null;
    }

    return {
        categories: sentiment.map((id) => ({
            color: colors[id] || random,
            id,
            name: capitalize(id),
        })),
        results: savedSearch.map((result) => ({
            id: result.id,
            name: result.name,
            ...Object.assign(
                {},
                ...result.values.flatMap(({id, value}) => ({
                    [id]: value,
                })),
            ),
        })),
    };
}

function formatShareOfVoice({
    data,
    additionalQueries,
    linkedinChannelIds,
    queryContext,
    config: {
        themeColors: {queryColors},
    },
}) {
    if (!data || !data?.results || !data?.results?.length) {
        return null;
    }
    const getColor = getCatsColors({
        savedSearchId: queryContext.savedSearchId,
        additionalQueries,
        linkedinChannelIds,
        queryColors,
    });
    const aggregated = data.results[0].values.map((iv, i) => ({
        id: iv.id,
        name: capitalize(iv.name),
        value: iv.value,
        color: getColor(iv.id),
    }));
    const response = {
        categories: aggregated.map((id) => id),
        total: Object.values(aggregated).reduce((t, i) => t + i.value, 0),
    };
    return response;
}

function formatTopauthors({data}) {
    const topAuthorResults = data?.results;

    if (
        !data ||
        !topAuthorResults ||
        !Array.isArray(topAuthorResults) ||
        !topAuthorResults.length
    ) {
        return null;
    }

    const total = topAuthorResults.reduce(
        (acc, {data: {authorVolume, volume}}) => acc + (authorVolume | volume),
        0,
    );

    const multipleQueriesValues = (id) =>
        find(data?.multipleQueriesResults, (result) => result.id === id)?.breakdown;

    const valuesByQueryId = (id) =>
        multipleQueriesValues(id)?.reduce((acc, {queryId, value}) => {
            acc[queryId] = value;
            return acc;
        }, {});

    return topAuthorResults.map(
        ({
            id,
            name,
            data: {
                volume,
                authorVolume,
                domain,
                reachEstimate,
                twitterFollowers,
                twitterAvatar,
                countryName,
            },
            values,
        }) => ({
            id,
            name,
            displayName: formatAuthorName({name, domain}),
            domain: domain || undefined,
            percentageOfTotal: Number.parseFloat((authorVolume | volume) / total),
            reachEstimate,
            twitterFollowers,
            twitterAvatar,
            countryName,
            value: authorVolume | volume,
            values: multipleQueriesValues(id) || values,
            ...valuesByQueryId(id),
        }),
    );
}

function formatTophashtags({data}) {
    const topHashtagsResults = data?.results;

    if (!data || !topHashtagsResults || !isArray(topHashtagsResults)) {
        return null;
    }

    const multipleQueriesValues = (name) =>
        find(data?.multipleQueriesResults, (result) => result.name === name)?.breakdown;

    const valuesByQueryId = (name) =>
        multipleQueriesValues(name)?.reduce((acc, {queryId, value}) => {
            acc[queryId] = value;
            return acc;
        }, {});

    return topHashtagsResults.map(
        ({name, volume, impressions, reachEstimate, retweets, tweets, data}) => ({
            id: name,
            name,
            impressions,
            reachEstimate,
            retweets,
            tweets,
            value: volume | data?.volume,
            values: multipleQueriesValues(name) || [],
            ...valuesByQueryId(name),
        }),
    );
}

function formatTopicWheel({data}) {
    const savedSearch = data?.groups;

    if (!data || !isArray(savedSearch)) {
        return null;
    }

    return data;
}

function formatTopinterests({data, otherResults}) {
    const quickSearch = data?.results;
    const savedSearch = data?.demographicTotals;

    if (!data || (!quickSearch && !savedSearch)) {
        return null;
    }

    if (quickSearch) {
        return quickSearch.map(({id, name, values}) => ({
            id,
            name,
            total: values[0]?.value,
        }));
    }

    if (savedSearch) {
        const {demographicTotals, timeSeries, totalAuthorsClassified} = data;

        const mentions = otherResults?.results[0]?.values;
        const seriesValues = timeSeries.flatMap(({values}) => values);

        return demographicTotals
            .filter(({value}) => value !== 0)
            .map(({name, total}) => ({
                id: name,
                name,
                authorSeries: seriesValues
                    .filter((series) => series.name === name)
                    .map(({value}) => ({value})),
                authorPercent: total / totalAuthorsClassified ?? 0,
                authorTotal: total,
                total: find(mentions, ({id}) => id === name)?.value,
            }));
    }

    return null;
}

function formatToplanguages({data, otherResults}) {
    const resultValues = data?.results[0]?.values;
    const savedSearchResultLimit = 10;
    //remove "undefined" category from collection
    const filterByValidLanguage = ({id}) => id && id !== 'undefined';

    const languageAuthors = otherResults?.results[0]?.values
        .filter(filterByValidLanguage)
        .slice(0, savedSearchResultLimit)
        .map(({id, value}) => ({
            id,
            total: value,
        }));

    const uniqueAuthorsTotal = !isEmpty(languageAuthors)
        ? languageAuthors.reduce((acc, author) => acc + parseFloat(author.total || 0), 0)
        : 0;

    return resultValues
        .filter(filterByValidLanguage)
        .slice(0, savedSearchResultLimit)
        .map(({id, name, value}) => {
            const authorTotal = find(languageAuthors, {id})?.total;
            return {
                id,
                name,
                total: value,
                authorTotal: authorTotal ?? 0,
                authorPercent: authorTotal ? authorTotal / uniqueAuthorsTotal : 0,
            };
        })
        .sort((a, b) => b.total - a.total);
}

function formatTopsites({data}) {
    const topSitesResults = data?.results;

    if (!data || !topSitesResults || !isArray(topSitesResults)) {
        return null;
    }

    const multipleQueriesValues = (id) =>
        find(data?.multipleQueriesResults, (result) => result.id === id)?.breakdown;

    const valuesByQueryId = (id) =>
        multipleQueriesValues(id)?.reduce((acc, {queryId, value}) => {
            acc[queryId] = value;
            return acc;
        }, {});

    return topSitesResults.map(({id, name, data: {volume}, values}) => ({
        id,
        name,
        value: volume,
        values: multipleQueriesValues(id) || values,
        ...valuesByQueryId(id),
    }));
}

function formatTopSharedURLs({data}) {
    const topSharedURLsResults = data?.results;

    if (!data || !topSharedURLsResults) {
        return null;
    }

    return topSharedURLsResults.map(({name, volume, data}) => ({
        name,
        volume: volume | data?.volume,
    }));
}

function formatToptopicsBySearch({data}) {
    const topics = data?.topics;
    const multipleSearches = data?.multipleQueriesResults;

    if (!data && (!isArray(topics) || !isArray(multipleSearches))) {
        return null;
    }

    if (!!multipleSearches) {
        const multipleQueriesValues = (id) =>
            find(data?.multipleQueriesResults, (result) => result.id === id)?.breakdown;

        const valuesByQueryId = (id) =>
            multipleQueriesValues(id)?.reduce((acc, {queryId, value}) => {
                acc[queryId] = value;
                return acc;
            }, {});

        return topics.map(({id, label, volume, values}) => ({
            id,
            name: label,
            value: volume,
            values: multipleQueriesValues(id) || values,
            ...valuesByQueryId(id),
        }));
    }

    return null;
}

function formatTotalMentions({data}) {
    const mentionsCount = data?.mentionsCount;

    if (!data || !Number.isInteger(mentionsCount)) {
        return null;
    }

    return mentionsCount;
}

// TODO: Each element should be formatted individually
// This is shared between multiple components
function formatTotalVolume({data}) {
    const quickSearch = data?.mentionsCount;
    const savedSearch = data?.volume;

    if (!data || (!quickSearch && !savedSearch)) {
        return null;
    }

    if (quickSearch && Number.isInteger(quickSearch)) {
        return quickSearch;
    }

    if (savedSearch) {
        const getValues = (results) => (results || []).flatMap(({values}) => values);
        const getSumTotal = (results) => {
            const values = getValues(results);
            return (values || [])?.reduce((acc, curr) => acc + curr.value, 0) ?? 0;
        };
        const getPercentageTotal = (value) => Math.round(value * 100 || 0);
        const getTotal = (value) => Math.round(value || 0);

        const totalVolumeTypeIds = totalVolumes.map(({id}) => id);
        const totals = totalVolumeTypeIds
            .filter((id) => !isEmpty(data[id]))
            .map((id) => {
                const {current, previous} = data[id];
                switch (id) {
                    case 'avgFollowers':
                        return {
                            id,
                            changePercentage: getChangePercentage(
                                getTotal(current?.rate),
                                getTotal(previous?.rate),
                            ),
                            current: getTotal(current?.rate),
                            currentValues: null, // Not available in API
                            previous: getTotal(previous?.rate),
                            previousValues: null, // Not available in API
                        };
                    case 'retweetRate':
                        return {
                            id,
                            changePercentage: getChangePercentage(
                                getPercentageTotal(current?.rate),
                                getPercentageTotal(previous?.rate),
                            ),
                            current: getPercentageTotal(current?.rate),
                            currentValues: null, // Not available in API
                            previous: getPercentageTotal(previous?.rate),
                            previousValues: null, // Not available in API
                        };
                    default:
                        return {
                            id,
                            changePercentage: getChangePercentage(
                                getSumTotal(current?.results),
                                getSumTotal(previous?.results),
                            ),
                            current: getSumTotal(current?.results),
                            currentValues: getValues(current?.results),
                            previous: getSumTotal(previous?.results),
                            previousValues: getValues(previous?.results),
                        };
                }
            });

        if (totals.every((total) => total.current === 0)) {
            return null;
        }

        return totals;
    }

    return null;
}

function formatWordcloud({data}) {
    const topics = data?.topics;

    if (!data && !isArray(topics)) {
        return null;
    }

    return {
        topics: topics
            .filter(({volume}) => volume > 0)
            .map(({gender, id, label, sentimentScore, trending, type, volume}, i) => ({
                id: i,
                gender, // Saved search only
                label, // Saved search only
                sentimentScore, // Saved search only
                size: volume, // For text sizes in chart
                topicType: type, //For topicType color, saved search only
                trending, // Saved search only
                type: 'text', // For emojis in chart
                value: type === 'emoji' ? id : label, // Display text
                volume, // For color mapping
            })),
    };
}

const typeToFormatter = {
    benchmark: formatBenchmark,
    benchmarkBySearch: formatBenchmarkBySearch,
    emotionHistory: formatEmotionHistory,
    emotionVolume: formatEmotionVolume,
    gender: formatGender,
    geography: formatGeography,
    heatmap: formatHeatmap,
    mentionsHistory: formatMentionsHistory,
    netSentimentHistory: formatNetSentimentHistory,
    pageTypeBySearch: formatPageTypeBySearch,
    sentimentHistory: formatSentimentHistory,
    sentimentVolume: formatSentimentVolume,
    sentimentVolumeBySearch: formatSentimentVolumeBySearch,
    topauthors: formatTopauthors,
    topauthorsBySearch: formatTopauthors,
    tophashtags: formatTophashtags,
    tophashtagsBySearch: formatTophashtags,
    topicWheel: formatTopicWheel,
    topinterests: formatTopinterests,
    toplanguages: formatToplanguages,
    topSharedURLs: formatTopSharedURLs,
    topsites: formatTopsites,
    topsitesBySearch: formatTopsites,
    toptopicsBySearch: formatToptopicsBySearch,
    totalMentions: formatTotalMentions,
    totalVolume: formatTotalVolume,
    totalVolumeBySearch: formatTotalVolume,
    wordCloud: formatWordcloud,
    shareOfVoice: formatShareOfVoice,
};

const formatAuthorName = ({name, domain}) =>
    (name || '').toLowerCase() === 'anonymous' && typeof domain === 'string' ? domain : name;

export default typeToFormatter;
