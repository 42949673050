import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import find from 'lodash/find';

const sum = (results, searchId) =>
    results.reduce((acc, curr) => acc + (curr.id === searchId ? curr?.value : 0), 0);

const datum = (data, key) => key && find(data, ({id}) => id === key)?.currentValues;

export const useDataParser = ({data, searchIds, type}) => {
    const _metrics = useSelector((state) => state.cardPersistState[type]);

    const metrics = useMemo(
        () => Object.values(_metrics).filter((metricValue) => !!metricValue),
        [_metrics],
    );

    const volumeBySearch = useMemo(() => {
        if (!data) return null;

        return searchIds.reduce((searchRes, id) => {
            searchRes[id] = metrics.reduce((volumeRes, metric) => {
                const results = datum(data, metric.id);
                volumeRes[metric.id] = sum(results || [], id);
                return volumeRes;
            }, {});
            return searchRes;
        }, {});
    }, [data, metrics, searchIds]);

    return {volumeBySearch, metrics};
};
