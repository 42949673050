//TODO this needs to be a tooltip
//Could add option to appear on hover or on click?
//Could add option for tooltip style?
//Could add option for icon size?

import React, {HTMLAttributes, ReactElement} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import MuiIconButton from '@mui/material/IconButton';

//Components
import IconRouter from 'hsi/components/IconRouter';
import Popover from 'hsi/components/Popover';

//Hooks
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';
import {Placement} from '@floating-ui/react-dom-interactions';

interface HelpIconArgs extends HTMLAttributes<HTMLDivElement> {
    tooltip: ReactElement;
    tooltipDistance?: Parameters<typeof Popover>[0]['distance'];
    placement?: Placement;
    size?: 'normal' | 'medium' | 'large';
    icon?: string;
    buttonLbl: string;
    onShow?: () => void;
}

//The component
export default function HelpIcon({
    children,
    tooltip,
    tooltipDistance = undefined,
    placement,
    className = '',
    id = undefined,
    size = 'normal',
    icon = 'info',
    buttonLbl,
    onShow,
    ...rest
}: HelpIconArgs) {
    const {classes, cx} = useStyles();
    id = useUniqueId(id, 'help');

    return (
        <div className={cx(classes.root, classes[size], className)} {...rest}>
            {children && (
                <label className={classes.msg} htmlFor={id}>
                    {children}
                </label>
            )}
            <Popover
                content={tooltip} //TODO link labelId and descriptionId, with aria-props - use a context?
                distance={tooltipDistance}
                placement={placement}
                onShow={onShow}
                portal
            >
                <IconButton
                    aria-label={buttonLbl}
                    className={cx(classes.btn, classes[size])}
                    id={id}
                    size="large">
                    <IconRouter className={cx(classes.icon, classes[size])} name={icon} />
                </IconButton>
            </Popover>
        </div>
    );
}

HelpIcon.propTypes = {
    className: PropTypes.string,
};

const IconButton = withStyles(MuiIconButton, (theme) => ({
    root: {
        //HSI theme
        padding: '3px',
        '& svg': {
            width: '17px',
            height: '17px',
        },
    },
}));
