import {isV2} from 'hsi/types/theme';
import {makeStyles} from 'tss-react/mui';

export default makeStyles<void, 'verticalSpace'>()((theme, _params, classes) => {
    const v2 = isV2(theme);

    return {
        header: {
            flex: '0 0 auto',
            display: 'flex',
            flexFlow: 'column nowrap',
            color: v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
            zIndex: 10,
            position: 'relative',
            top: '0',
            right: '0',
            padding: '16px 30px 20px 20px',
        },
        full: {
            ...(v2
                ? {}
                : {
                      borderBottom: `2px solid ${theme.colors.lightGrey80}`,
                      marginBottom: '16px',
                  }),
        },
        row: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '20px',
            marginTop: v2 ? 12 : undefined,

            [`&.${classes.verticalSpace}`]: {
                marginTop: 12,
            },

            '&:first-child': v2
                ? {
                      paddingBottom: '8px',
                      borderBottom: `1px dashed ${theme.colors.borders}`,
                  }
                : undefined,
        },
        left: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: '1 1 auto',
            gap: '8px',
        },
        right: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flex: '1 1 auto',
            gap: '5px',
            minWidth: 0,
        },
        title: {
            textTransform: 'capitalize',
            ...(v2 ? theme.fonts.headtitleStrong : {fontWeight: theme.font.bold, fontSize: '20px'}),
            flex: '0 1 auto',
            margin: 0,
            marginLeft: '20px',
        },
        closeBtn: v2
            ? {
                  border: 0,
                  background: 'transparent',
                  padding: 0,
                  margin: 0,
                  ...theme.fonts.bodyNormal,
                  fontFamily: theme.font.name,
                  cursor: 'pointer',
                  color: theme.colors.textSubtle,
              }
            : {
                  ...theme.mixin.linkColor,
                  border: 0,
                  background: 'transparent',
                  padding: 0,
                  margin: 0,
                  fontWeight: 700,
                  fontSize: '16px',
                  fontFamily: theme.font.name,
                  cursor: 'pointer',
              },
        showMoreLessBtn: {
            ...theme.mixin.blankButton,

            ...(v2
                ? {
                      ...theme.fonts.bodyNormal,
                      color: theme.colors.textSubtle,
                      position: 'relative',
                      marginRight: '33px',

                      '&:after': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          right: '-16px',
                          width: '1px',
                          height: '12px',
                          background: theme.colors.borders,
                      },
                  }
                : {
                      ...theme.mixin.linkColor,
                      fontWeight: 700,
                      fontSize: '16px',
                      cursor: 'pointer',
                  }),
        },
        showMoreLessBtnIcon: {
            ...(v2
                ? {
                      width: '11px',
                      height: '11px',
                  }
                : {
                      margin: '-2px 0',
                      width: '16px',
                      height: '16px',
                  }),
        },

        selectModeSwitchLbl: {
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            marginRight: 20,
            ...(v2 ? theme.fonts.bodyStrong : {fontWeight: theme.font.bold, fontSize: '16px'}),
        },

        numSelectedMentions: {
            ...(v2 ? theme.fonts.bodyNormal : {}),
        },

        clearSelectedMentions: {
            ...theme.mixin.linkButton,
            color: v2 ? theme.colors.textSubtle : theme.colors.textDefault,

            ...(v2 ? theme.fonts.bodyStrong : {fontWeight: theme.font.bold}),

            '&[disabled]': {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        },

        verticalSpace: {},
    };
});
