import Avatar from 'hsi/components/Avatar';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import PlatformIcon from 'hsi/components/PlatformIcons';
import SiteFavicon from 'hsi/components/SiteFavicon';
import Tooltip from 'hsi/components/Tooltip';

import useStyles from './styles';

import {inferPlatformFromDomain} from 'hsi/utils/platform';
import {ReactNode} from 'react-markdown';

type AuthorAvatarArgs = {
    avatarUrl?: string;
    domain?: string;
    miniIcon?: ReactNode;
    classes: ReturnType<typeof useStyles>;
};

const AuthorAvatar = ({avatarUrl, domain, miniIcon, classes: cl}: AuthorAvatarArgs) => {
    const avatarContent = (
        <Avatar
            className={cl.avatar}
            src={avatarUrl}
            platformIcon={miniIcon}
            onClick={(e) => e && e.stopPropagation()}
        />
    );

    return domain ? <Tooltip tooltip={domain}>{avatarContent}</Tooltip> : avatarContent;
};

type AuthorDetailsArgs = {
    displayName: ReactNode;
    countryName: ReactNode;
    classes: ReturnType<typeof useStyles>;
};

const AuthorDetails = ({displayName, countryName, classes: cl}: AuthorDetailsArgs) => (
    <div className={cl.authorDetails}>
        <OverflowTooltip tooltip={displayName as any}>
            <div className={cl.authorName}>{displayName}</div>
        </OverflowTooltip>

        <OverflowTooltip tooltip={countryName as any}>
            <div className={cl.location}>{countryName}</div>
        </OverflowTooltip>
    </div>
);

type AuthorCellArgs = {
    datum: {
        displayName: any;
        domain: string;
        twitterAvatar?: string;
        countryName: string;
    };
    type: any;
    index: any;
};

const AuthorCell = ({datum, type, index}: AuthorCellArgs) => {
    const {displayName, domain, twitterAvatar: avatarUrl, countryName} = datum;

    const platform = inferPlatformFromDomain(domain);
    const hasPlatformIcon = !!platform;
    const hasAvatar = !!(avatarUrl || hasPlatformIcon || domain);

    const cl = useStyles({hasAvatar});

    const miniIcon = hasAvatar ? (
        hasPlatformIcon ? (
            <PlatformIcon name={platform} is48={!avatarUrl} width="20" height="20" />
        ) : (
            <SiteFavicon domain={domain} className={cl.favicon} />
        )
    ) : undefined;

    return (
        <div className={cl.container}>
            <div className={cl.index}>{index + 1}</div>
            {hasAvatar && (
                <AuthorAvatar
                    avatarUrl={avatarUrl}
                    domain={domain}
                    miniIcon={miniIcon}
                    classes={cl}
                />
            )}
            <AuthorDetails displayName={displayName} countryName={countryName} classes={cl} />
        </div>
    );
};

export default AuthorCell;
