import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    table: {
        width: '100%',
        tableLayout: 'auto',
        fontSize: theme.elements.cardTable?.fontSize,
        color: theme.colors.darkGrey100,
        borderCollapse: 'collapse !important',
    },

    left: {
        textAlign: 'left',
    },

    th: {
        position: 'relative',
        height: '40px',
        color: '#454b53',

        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    td: {
        height: '40px',
        padding: 0,
        lineHeight: '39px',
        [`&.${classes.rank}`]: {
            paddingRight: '4px',
        },
    },

    name: {
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
    },

    nameContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: '40px',
        height: '40px',
    },

    totalAmount: {
        paddingRight: '32px',
        textAlign: 'right',

        [`&.${classes.td}`]: {
            paddingRight: '45px',
        },
    },

    uniqAmount: {
        whiteSpace: 'nowrap',
        paddingRight: '4px',

        [`&.${classes.th}`]: {
            textAlign: 'left',
        },
    },

    uniqBar: {
        padding: '0 8px',
        width: '15%',
    },

    wide: {
        width: '25%',
        minWidth: '80px',
    },

    sparkline: {
        padding: '0 0 0 8px',
        minWidth: '73px',
        maxWidth: '73px',
    },

    tr: {
        borderCollapse: 'collapse !important',
        backgroundSize: '2px 2px',
        borderTop: `1px dotted ${theme.colors.lightGrey100}`,
    },

    clickableRow: {
        cursor: 'pointer',
        [`& .${classes.nameContainer}`]: {
            ...theme.mixin.linkColor,
        },

        '&:hover': {
            [`& .${classes.nameContainer}`]: {
                textDecoration: 'underline',
            },
        },
    },
    onlyAmount: {
        [`& .${classes.totalAmount}`]: {
            paddingRight: 0,
        },
    },
}));
