import useRefCallback from 'hsi/hooks/useRefCallback';
import useUniqueId from 'hsi/hooks/useUniqueId';
import useWindowEvent from 'hsi/hooks/useWindowEvent';
import {findAncestor} from 'hsi/utils/dom';
import {mod} from 'hsi/utils/math';
import {mergeRefs} from 'hsi/utils/react';
import React, {
    Children,
    cloneElement,
    createContext,
    Dispatch,
    forwardRef,
    KeyboardEvent,
    MutableRefObject,
    ReactElement,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';

interface DataGridArgs {
    children: ReactElement;
    id?: string;
    initialCell?: string;
    rowsPerPage?: number;
    multiselectable?: boolean;

    getGridFromSource?: boolean;

    //Called when a cell recieves focus
    onActive?: (value: string | undefined) => void;

    //Called when a cell is 'clicked' either by mouse, or by space/enter key
    onSelect?: (value: string) => void;
    //Callback to allow custom wrapping rules + alternative custom wrapping behaviour
    //called with direction of requested wrapping, returns boolean for wrapping allowed
    //if undefined, wrapping always allowed
    onWrap?: WrapType;

    onValueDragStart?: (initialValue: string) => void;
    onValueDrag?: (initialValue: string, currentValue: string) => void;
    onValueDragEnd?: (initialValue?: string, endValue?: string) => void;
}

interface DataGridContextType
    extends Pick<
        DataGridArgs,
        | 'id'
        | 'onSelect'
        | 'getGridFromSource'
        | 'onActive'
        | 'onValueDragStart'
        | 'onValueDrag'
        | 'onValueDragEnd'
    > {
    draggable: boolean;
    initialDragValue?: string;
    setInitialDragValue?: Dispatch<React.SetStateAction<string | undefined>>;

    isDraggingRef: MutableRefObject<boolean>;
}

const DataGridContext = createContext<DataGridContextType | undefined>(undefined);
DataGridContext.displayName = 'DataGridContext';

export type Directions = 'up' | 'down' | 'left' | 'right';
export type WrapType = (
    direction: Directions,
    isLooping: boolean,
    fromX: number,
    fromY: number,
) => boolean;

type CellsGrid = (HTMLElement | undefined)[][];
type DataGridComponent = ReturnType<typeof forwardRef<HTMLElement, DataGridArgs>> & {
    Cell: ReturnType<typeof forwardRef<HTMLElement, DataGridCellArgs>>;
};

const DataGrid: DataGridComponent = forwardRef<HTMLElement, DataGridArgs>(function DataGrid(
    {
        id: _id,
        children,
        onSelect,
        onWrap,
        onActive,
        initialCell,
        rowsPerPage = 5,
        multiselectable = false,
        getGridFromSource = false,

        onValueDragStart,
        onValueDrag,
        onValueDragEnd,
    }: DataGridArgs,
    ref,
) {
    const [gridElement, _setGridElement] = useState<HTMLElement>();
    const id = useUniqueId(_id, 'grid');

    const draggable = !!(onValueDrag || onValueDragStart || onValueDragEnd);
    const [initialDragValue, setInitialDragValue] = useState<string | undefined>(undefined);
    const isDraggingRef = useRef(false);

    const baseChild = useMemo(() => Children.only(children) as ReactElement, [children]);

    //Callbacks
    const setGridElement = useCallback(
        (gridElement: HTMLElement | null) => {
            if (gridElement) {
                //initialise tabIndex for cells
                const cells = Array.from(
                    gridElement.querySelectorAll<HTMLElement>(
                        `[role=gridcell][data-gridcell-grid="${gridElement.id}"]`,
                    ),
                );

                //is a cell currently focussed?
                const activeCell = getCellFromElement(id, document.activeElement);

                //get index of focussed cell, or a cell that can be focussed
                let focusableIndex =
                    (activeCell && cells.findIndex((cell) => cell === activeCell)) ||
                    cells.findIndex((cell) => cell?.getAttribute('tabindex') === '0');

                if (focusableIndex === -1) {
                    //if nothing currently focussed, use initialCell, or find first selectable cell
                    const initialCellIndex = initialCell
                        ? cells.findIndex(
                              (cell) =>
                                  isSelectableCell(cell) &&
                                  cell.dataset.gridcellValue === initialCell,
                          )
                        : -1;
                    focusableIndex =
                        initialCellIndex !== -1
                            ? initialCellIndex
                            : cells.findIndex((cell) => isSelectableCell(cell));
                }

                //update tabindex of all cells
                cells.forEach((cell, index) => {
                    if (cell) {
                        cell.setAttribute('tabindex', index === focusableIndex ? '0' : '-1');
                    }
                });
            }

            _setGridElement(gridElement ?? undefined);
        },
        [_setGridElement, id, initialCell],
    );

    const onKeyDown = useCallback(
        (event: KeyboardEvent<HTMLTableElement>) => {
            if (
                [
                    'ArrowLeft',
                    'ArrowRight',
                    'ArrowUp',
                    'ArrowDown',
                    'Enter',
                    'Space',
                    'PageDown',
                    'PageUp',
                    'Home',
                    'End',
                ].includes(event.code)
            ) {
                event.preventDefault();
                event.stopPropagation();

                const activeCell = getCellFromElement(id, event.target as Element);

                function moveInDirection(
                    cells: CellsGrid,
                    activeX: number,
                    activeY: number,
                    direction: Directions,
                ) {
                    const newCell = findAvailableCellInGridInDirection(
                        cells,
                        activeX,
                        activeY,
                        direction,
                        onWrap,
                    );

                    newCell && moveCellFocus(cells, newCell);
                }

                function moveBy(
                    cells: CellsGrid,
                    activeX: number,
                    activeY: number,
                    direction: Directions,
                    distance: number,
                ) {
                    let target: HTMLElement | undefined;
                    const [dx, dy] = directionTransforms[direction];

                    for (let i = 1; i <= distance; i++) {
                        const cell = cells[activeX + dx * i][activeY + dy * i];

                        if (isSelectableCell(cell)) {
                            target = cell;
                        }
                    }

                    target && moveCellFocus(cells, target);
                }

                if (activeCell) {
                    const cells = getGridCells(gridElement, getGridFromSource);
                    const [activeX, activeY] = getCellCoords(cells, activeCell);

                    switch (event.code) {
                        case 'ArrowLeft': {
                            moveInDirection(cells, activeX, activeY, 'left');
                            break;
                        }
                        case 'ArrowRight': {
                            moveInDirection(cells, activeX, activeY, 'right');
                            break;
                        }

                        case 'ArrowUp': {
                            moveInDirection(cells, activeX, activeY, 'up');
                            break;
                        }
                        case 'ArrowDown': {
                            moveInDirection(cells, activeX, activeY, 'down');
                            break;
                        }
                        case 'Enter':
                        case 'Space':
                            onSelect?.(activeCell.dataset.gridcellValue as string);
                            break;
                        case 'PageDown': {
                            //Moves focus down an author-determined number of rows, typically scrolling so the bottom row in the currently visible set of rows becomes one of the first visible rows. If focus is in the last row of the grid, focus does not move.
                            moveBy(cells, activeX, activeY, 'down', rowsPerPage);
                            break;
                        }
                        case 'PageUp': {
                            //Moves focus up an author-determined number of rows, typically scrolling so the top row in the currently visible set of rows becomes one of the last visible rows. If focus is in the first row of the grid, focus does not move.
                            moveBy(cells, activeX, activeY, 'up', rowsPerPage);
                            break;
                        }
                        case 'Home': {
                            //Moves focus to the first cell in the first row. Or, if CTRL key down moves focus to the first cell in the row that contains focus.
                            const initY = event.ctrlKey ? 0 : activeY;
                            let isCellFound = false;

                            for (let y = initY; y < cells[0].length; y++) {
                                for (let x = 0; x < cells.length; x++) {
                                    if (isSelectableCell(cells[x][y])) {
                                        moveCellFocus(cells, cells[x][y]);
                                        isCellFound = true;
                                        break;
                                    }
                                }

                                if (isCellFound) {
                                    break;
                                }
                            }
                            break;
                        }
                        case 'End': {
                            //Moves focus to the last cell in the last row. Or, if CTRL key is down, moves focus to the last cell in the row that contains focus.
                            const initY = event.ctrlKey ? cells[0].length - 1 : activeY;
                            let isCellFound = false;

                            for (let y = initY; y < cells[0].length; y++) {
                                for (let x = cells.length - 1; x >= 0; --x) {
                                    if (isSelectableCell(cells[x][y])) {
                                        moveCellFocus(cells, cells[x][y]);
                                        isCellFound = true;
                                        break;
                                    }
                                }

                                if (isCellFound) {
                                    break;
                                }
                            }

                            break;
                        }
                    }
                }
            }

            //Call childs current onKeydown hanbdler, is applicable
            baseChild.props.onKeyDown?.(event);
        },
        [id, onSelect, onWrap, baseChild, rowsPerPage, gridElement, getGridFromSource],
    );

    const contextValue = useMemo<DataGridContextType>(
        () => ({
            id,
            onSelect,
            getGridFromSource,
            onActive,
            draggable,
            initialDragValue,
            setInitialDragValue,
            onValueDragStart,
            onValueDrag,
            onValueDragEnd,
            isDraggingRef,
        }),
        [
            id,
            onSelect,
            getGridFromSource,
            onActive,
            draggable,
            initialDragValue,
            onValueDragStart,
            onValueDrag,
            onValueDragEnd,
            isDraggingRef,
        ],
    );

    //Calculated values
    const child = useMemo(
        () =>
            cloneElement(baseChild, {
                ref: mergeRefs(setGridElement, ref, (baseChild as any).ref),
                role: 'grid',
                'aria-multiselectable': (!!multiselectable).toString(),
                id,
                onKeyDown,
            }),
        [setGridElement, id, multiselectable, onKeyDown, baseChild, ref],
    );

    return <DataGridContext.Provider value={contextValue}>{child}</DataGridContext.Provider>;
}) as DataGridComponent;

export default DataGrid;

function useDataGridContext() {
    return useContext(DataGridContext) as DataGridContextType;
}

type DataGridCellArgs = {
    children: ReactElement;
    disabled: boolean;
    selected?: boolean;
    value: string;
};

DataGrid.Cell = forwardRef<HTMLElement, DataGridCellArgs>(function DataGridCell(
    {children, disabled, selected, value}: DataGridCellArgs,
    ref,
) {
    const elemRef = useRef<HTMLElement>(null);

    const {
        id: gridId,
        onSelect,
        onActive,
        getGridFromSource,
        initialDragValue,
        setInitialDragValue,
        draggable,
        isDraggingRef,
        onValueDragStart,
        onValueDrag,
        onValueDragEnd,
    } = useDataGridContext();

    const child = Children.only(children);

    const onClick = useCallback(() => {
        if (!disabled) {
            moveCellFocus(
                getGridCells(
                    document.getElementById(gridId as string) ?? undefined,
                    !!getGridFromSource,
                ),
                elemRef.current,
            );
            !disabled && onSelect?.(value);
        }
    }, [gridId, value, disabled, onSelect, getGridFromSource]);

    const onFocus = useCallback(() => {
        onActive?.(value);
    }, [onActive, value]);

    //Only attached if draggable
    const onMouseDown = useRefCallback((event: MouseEvent) => {
        setInitialDragValue?.(value);
        event.preventDefault();
    });

    const onMouseEnter = useCallback(() => {
        onActive?.(value);

        if (isDraggingRef.current && !!onValueDrag) {
            onValueDrag(initialDragValue as string, value);
        }
    }, [onActive, value, isDraggingRef, onValueDrag, initialDragValue]);

    //Only set if cell is not disabled, and dragging is enabled
    const onMouseUp = useCallback(() => {
        if (isDraggingRef.current || initialDragValue === value) {
            onValueDragEnd?.(initialDragValue as string, value);

            //Set as no longer dragging
            setInitialDragValue?.(undefined);
            isDraggingRef.current = false;
        }
    }, [initialDragValue, isDraggingRef, onValueDragEnd, setInitialDragValue, value]);

    //If dragging and this is the initial cell, if the mouse pointer moves out from this cell while being held down, and a drag hasn't already started
    //trigger the onValueDragStart handler and mark dragging as started
    const onMouseLeave = useRefCallback((event: MouseEvent) => {
        onActive?.(undefined);

        if (
            draggable &&
            initialDragValue === value &&
            value === initialDragValue &&
            !isDraggingRef.current
        ) {
            event.preventDefault(); //stop text from getting selected

            isDraggingRef.current = true; //record that dragging has started

            //trigger callback
            onValueDragStart?.(value);
        }
    });

    //If this cell is being dragged from, listen to mouse up event on window
    //this is to catch if the mouse up event occurs outside the DataGrid element
    useWindowEvent(
        'mouseup',
        initialDragValue === value
            ? (event: MouseEvent) => {
                  if (isDraggingRef.current) {
                      //This will be false if cell handler fires first
                      onValueDragEnd?.();
                      setInitialDragValue?.(undefined);
                      isDraggingRef.current = false;
                  }
              }
            : undefined,
    );

    return cloneElement(child, {
        role: 'gridcell',
        'aria-disabled': disabled ? 'true' : undefined,
        'data-gridcell-grid': gridId,
        'data-gridcell-value': value,
        'aria-selected': (!!selected).toString(),
        onClick,
        onMouseDown: !disabled && draggable ? onMouseDown : undefined,
        onMouseEnter: !disabled ? onMouseEnter : undefined,
        onMouseLeave: !disabled ? onMouseLeave : undefined,
        onMouseUp: !disabled && draggable ? onMouseUp : undefined,
        onFocus: !disabled ? onFocus : undefined,

        ref: mergeRefs(elemRef, ref, (child as any).ref),
    });
});

//Utility methods
function getCellFromElement(gridId: string, element: Element | null): HTMLElement | undefined {
    if (!element) {
        return undefined;
    }

    return findAncestor<Element>(
        element,
        (elem) => elem instanceof HTMLElement && elem.dataset.gridcellGrid === gridId,
    ) as HTMLElement | undefined;
}

function moveCellFocus(cells: CellsGrid, activeCell?: HTMLElement | null) {
    //Keep tabIndex values updated
    cells.forEach((column) =>
        column.forEach((cell) => {
            cell?.setAttribute('tabindex', cell === activeCell ? '0' : '-1');
        }),
    );

    activeCell?.focus();
}

//returns all grid cell elements that are part of this grid as a nested array
//x, y indexed
function getGridCells(gridElement: HTMLElement | undefined, getGridFromSource: boolean) {
    if (!gridElement) {
        return [];
    }

    const id = gridElement.id;

    if (getGridFromSource) {
        const rowsByColumns = Array.from(
            gridElement?.querySelectorAll<HTMLElement>(`tr, [role=row]`),
        )
            .map((row) =>
                Array.from(
                    row.querySelectorAll<HTMLElement>(
                        `[role=gridcell][data-gridcell-grid="${id}"]`,
                    ),
                ),
            )
            .filter((row) => row.length > 0); //filter out empty rows (will also remove nested rows)

        //Rotate 2d array to be [column][row]
        const numColumns = Math.max(...rowsByColumns.map((row) => row.length));

        const output: CellsGrid = [];

        for (let x = 0; x < numColumns; x++) {
            output[x] = [];

            for (let y = 0; y < rowsByColumns.length; y++) {
                output[x][y] = rowsByColumns[y][x] ?? null;
            }
        }

        return output;
    } else {
        const allCells = Array.from(
            gridElement.querySelectorAll<HTMLElement>(
                `[role=gridcell][data-gridcell-grid="${id}"]`,
            ),
        );
        const cellBounds = allCells.map((cell) => cell.getBoundingClientRect());

        //calculate what all the rows and columns are
        const columnStarts = new Set<number>();
        const rowStarts = new Set<number>();

        cellBounds.forEach(({x, y}) => {
            columnStarts.add(x); //TODO round to nearest?
            rowStarts.add(y);
        });

        const columns = columnStarts.size;
        const rows = rowStarts.size;
        //create lookuop list of row/column position to row/column index
        const columnIndex = sortSetToMap(columnStarts);
        const rowIndex = sortSetToMap(rowStarts);

        //init output array
        const output: (HTMLElement | undefined)[][] = Array(columns)
            .fill(null)
            .map(() => Array(rows));

        //assign each cell it its row/column
        cellBounds.forEach(({x, y}, index) => {
            output[columnIndex.get(x) as number][rowIndex.get(y) as number] = allCells[index];
        });

        return output;
    }
}

function sortSetToMap(set: Set<number>) {
    return new Map<number, number>(
        Array.from(set)
            .sort((a, b) => a - b)
            .map((val, index) => [val, index]),
    );
}

function getCellCoords(cells: CellsGrid, cell: HTMLElement): [number, number] {
    for (let x = 0; x < cells.length; x++) {
        const column = cells[x];

        for (let y = 0; y < column.length; y++) {
            if (column[y] === cell) {
                return [x, y];
            }
        }
    }

    return [-1, -1];
}

const directionTransforms = {
    up: [0, -1],
    right: [1, 0],
    down: [0, 1],
    left: [-1, 0],
};

function findAvailableCellInGridInDirection(
    grid: CellsGrid,
    startX: number,
    startY: number,
    direction: Directions,
    onWrap?: WrapType,
): HTMLElement | undefined {
    if (grid.length === 0 || grid[0].length === 0) {
        return;
    }

    const transform = directionTransforms[direction];
    let x = startX;
    let y = startY;

    //grid will always be 'rectangular', although might be 'sparse' (cell = null)
    const columns = grid.length;
    const rows = grid[0].length;
    const numCells = rows * columns;

    for (let i = 0; i < numCells; i++) {
        const lastX = x;
        const lastY = y;

        //move to next position
        x += transform[0];
        y += transform[1];

        //check if new position is valid
        if (!isValidCoords(grid, x, y)) {
            if (
                !onWrap ||
                onWrap(direction, isLoop(direction, columns, rows, lastX, lastY), lastX, lastY)
            ) {
                //can wrap, do wrap
                switch (direction) {
                    case 'up':
                        //wrap to end of previous column, or if at the start, wrap to last cell
                        x = mod(x - 1, columns);
                        y = rows - 1;
                        break;
                    case 'right':
                        //if at the end of row, wrap to the start of next row, or wrap back to start if on the last row
                        x = 0;
                        y = mod(y + 1, rows);
                        break;
                    case 'down':
                        //wrap to start of next column, or back to start if on last column
                        x = mod(x + 1, columns);
                        y = 0;
                        break;
                    case 'left':
                        //wrap to end of previous row, or if first row, to end of last row
                        x = columns - 1;
                        y = mod(y - 1, rows);
                        break;
                }
            } else {
                //cannot wrap
                return;
            }
        }

        //If you end up back where you started...
        if (x === startX && y === startY) {
            return; //...do nothing
        }

        //If this cell can be selected, select it
        if (isSelectableCell(grid[x][y])) {
            return grid[x][y];
        }
    }

    return; //If you get here, every single cell was checked and didn't find one that could be focussed.
}

function isSelectableCell(cell: HTMLElement | undefined) {
    return cell && cell.getAttribute('aria-disabled') !== 'true';
}

function isValidCoords(grid: CellsGrid, x: number, y: number): boolean {
    return !(x < 0 || y < 0 || x >= grid.length || y >= grid[x].length);
}

function isLoop(
    direction: Directions,
    columns: number,
    rows: number,
    x: number,
    y: number,
): boolean {
    //do you need to wrap from the start/end to end/start

    switch (direction) {
        case 'up':
        case 'left':
            return x === 0 && y === 0;
        case 'down':
        case 'right':
            return x === columns - 1 && y === rows - 1;
    }
}
