import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    savedSearchBtnMsg: {
        textAlign: 'left',
        padding: 16,
        maxWidth: '250px',
    },
    saveSearchLink: {
        ...theme.mixin.blankButton,
        ...theme.mixin.linkColor,
        cursor: 'pointer',

        ...(theme.v2 ? theme.typgrphy.bodySmallStrong : {}),
    },
}));
