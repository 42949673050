import React, {ReactNode} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import {Theme} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => ({
    name: {
        ...theme.mixin.linkButton,
        ...theme.mixin.truncate,
        display: 'block',
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

const NameCell = ({name}: {name: ReactNode}) => {
    const cl = useStyles();
    return (
        <OverflowTooltip tooltip={name as any}>
            <div className={cl.name}>{name}</div>
        </OverflowTooltip>
    );
};

export default NameCell;
