import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    label: {},
    disabled: {},

    drawer: {
        width: theme.elements.configDrawerWidth,
        top: theme.elements.configDrawerOffsetTop || '130px !important',
        left: 'auto !important',

        '& .MuiBackdrop-root': {
            display: 'none',
        },
    },

    content: {
        color: theme.v2 ? theme.colors.textDefault : theme.colors.darkGrey100,
        zIndex: 10,
        position: 'relative',
        top: '0',
        right: '0',
        backgroundColor: theme.v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey60,
    },
    title: {
        paddingLeft: '20px',
        fontWeight: 700,
        fontSize: '20px',
        flex: '0 0 auto',
    },
    closeBtn: {
        position: 'absolute',
        top: 13,
        right: 13,
        width: 28,
        height: 28,
        padding: 1.5,
    },
    controls: {
        margin: '35px 15px 25px 20px',
        display: 'flex',
        flexFlow: 'column nowrap',
        gap: '25px',
    },

    selectMenuActions: {
        margin: '1em',
    },
}));
