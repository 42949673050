
import { useContext, Context, useEffect, useMemo } from "react";

//
import useUniqueId from "hsi/hooks/useUniqueId";

import ComboboxContext from "../context";
import { ComboboxContextType, ComboboxItemProps } from "../types";

export default function useComboboxItemProps<ItemType, OtherProps extends {}>(item: ItemType, index: number, otherProps?: OtherProps) {
    const props = useContext(ComboboxContext as Context<ComboboxContextType<ItemType>>);

    if(props === undefined) {
        throw new Error('Combobox items must be nested within a Combobbox component');
    }

    const id = useUniqueId((otherProps as any)?.id || undefined, 'comboboxItem');

    const {registerOption, clearOption, selectedItem} = props;

    //Side effects
    useEffect(() => {
        const curId = id;

        registerOption(item, index, id);

        return () => {
            clearOption(curId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item, index, id]);

    //The output value
    return useMemo<ComboboxItemProps<OtherProps>>(() => {
        return {
            ...(otherProps!),
            id,
            role: 'option',
            "aria-disabled": false,//TODO
            "aria-selected": selectedItem === item,
            //inputValue,
            //TODO key handlers
        };
    }, [id, item, otherProps, selectedItem]);
}

export function useComboboxItemState<ItemType>(item: ItemType) {
    const props = useContext(ComboboxContext as Context<ComboboxContextType<ItemType>>);

    if(props === undefined) {
        throw new Error('Combobox items must be nested within a Combobbox component');
    }

    const {selectedItem} = props;

    return useMemo(() => ({
        isSelected: selectedItem === item,
        isDisabled: false,//TODO
    }), [item, selectedItem])
}