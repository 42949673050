import { isV2 } from 'hsi/types/theme';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()((theme) => {
    const v2 = isV2(theme);

    return {
        exportMenuBtn: v2
            ? {
                  ...theme.mixin.linkColor,
                  padding: '0 13px 0 0',
                  borderRadius: '3px',

                  '& > .MuiTouchRipple-root': {
                      display: 'none',
                  },
              }
            : {
                  padding: '9px',
                  '&.disabled': {
                      opacity: 0.5,
                  },
              },
        exportMenuIcon: v2
            ? {
                  fill: `${theme.mixin.linkColor.color}!important`,
              }
            : {},
        exportMenuLabel: theme.v2
            ? {
                  ...theme.fonts.bodyStrong,
                  paddingLeft: '4px',
                  position: 'relative',
                  '&:after': theme.v2
                      ? {
                            ...theme.mixin.downArrow,
                        }
                      : undefined,
              }
            : {...theme.mixin.offscreen},
        exportsDisabled: {
            padding: 16,
            maxWidth: 240,
        },
    };
});
