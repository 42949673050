import {formatBigInt, formatTableSortNumber} from 'hsi/utils/formatNumber';
import {T} from 'hsi/i18n';
import {TopHashtagsCardData} from 'hsi/types/cards';
import {CardTableField} from 'hsi/components/CardTable/v2';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import HashtagCell from './HashtagCell';

type HashtagTableCardField = CardTableField<TopHashtagsCardData[number]>;

//The hook
const useTableFields = (data: TopHashtagsCardData | undefined): HashtagTableCardField[] => {
    const isSavedSearch = useIsSavedSearch();

    const fields: (HashtagTableCardField | undefined)[] = [
        {
            id: 'name',
            label: T('cards.tophashtags.tableFields.name'),
            format: (value, datum, index) => (<HashtagCell value={value} index={index}/>),
            width: '2fr',
            disableSort: true,
        },

        {
            id: 'value',
            label: T('cards.tophashtags.tableFields.volume'),
            sortFormat: formatTableSortNumber,
            format: (value: number) => formatBigInt(value),
        },

        isSavedSearch
            ? {
                  id: 'impressions',
                  label: T('cards.tophashtags.tableFields.impressions'),
                  sortFormat: formatTableSortNumber,
                  format: (value: number) => formatBigInt(value),
              }
            : undefined,

        isSavedSearch
            ? {
                  id: 'reachEstimate',
                  label: T('cards.tophashtags.tableFields.reach'),
                  sortFormat: formatTableSortNumber,
                  format: (value: number) => formatBigInt(value),
              }
            : undefined,
    ];

    return fields.filter((x): x is HashtagTableCardField => !!x);
};

export default useTableFields;
