import React from 'react';
import PropTypes from 'prop-types';

//Components
import Sparkline from 'hsi/components/Sparkline';
import {HorizontalBar} from 'hsi/components/HorizontalBar';
import Tooltip from 'hsi/components/Tooltip';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';

//Utils
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useConfig from 'hsi/hooks/useConfig';
import useStyles from './styles';

//The component
const TopTenTable = ({
    authorTotalTooltip,
    itemLabel,
    items,
    onRowClick,
    showMentions,
    showSparkline,
    showUniqueAuthors,
    sparklineTooltip,
    extraColumns,
    isCardInteractivityDisabled,
}) => {
    const {classes, cx} = useStyles();
    const {themeColors} = useConfig();

    return (
        <table
            className={cx(
                classes.table,
                !showUniqueAuthors && !showSparkline && classes.onlyAmount,
            )}
        >
            <thead>
                <tr>
                    <th colSpan="2" className={cx(classes.left, classes.th)}>
                        {itemLabel}
                    </th>
                    {showMentions && (
                        <th className={cx(classes.totalAmount, classes.th)}>{T('mentions')}</th>
                    )}
                    {(showUniqueAuthors || showSparkline) && (
                        <th
                            className={cx(classes.uniqAmount, classes.th)}
                            colSpan={(showUniqueAuthors ? 2 : 0) + !!showSparkline}
                        >
                            {T('uniqAuthors')}
                        </th>
                    )}
                    {!!extraColumns &&
                        extraColumns.map((col) => (
                            <th className={cx(col.className, classes.th)}>{col.label}</th>
                        ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item, i) => (
                    <tr
                        key={i}
                        className={cx(classes.tr, onRowClick && classes.clickableRow)}
                        onClick={() => onRowClick && onRowClick(item)}
                        onKeyDown={
                            onRowClick
                                ? (e) => {
                                      if ([13, 32].includes(e.which)) {
                                          onRowClick(item);
                                          e.preventDefault();
                                      }
                                  }
                                : null
                        }
                        tabIndex={onRowClick ? '0' : undefined}
                    >
                        <td className={cx(classes.rank, classes.td)}>{i + 1}</td>
                        <td className={cx(classes.name, classes.td)} data-testid="topTenValue">
                            <OverflowTooltip tooltip={item.name}>
                                <div className={classes.nameContainer}>{item.name}</div>
                            </OverflowTooltip>
                        </td>
                        {showMentions && (
                            <td className={cx(classes.totalAmount, classes.td)}>
                                {formatBigInt(item.total)}
                            </td>
                        )}
                        {showUniqueAuthors && (
                            <>
                                <td className={cx(classes.uniqAmount, classes.td)}>
                                    <Tooltip tooltip={authorTotalTooltip} portal>
                                        <span>{formatBigInt(item.authorTotal)}</span>
                                    </Tooltip>
                                </td>
                                <td
                                    aria-label="horizontalBar"
                                    className={cx(
                                        classes.uniqBar,
                                        !showSparkline && classes.wide,
                                        classes.td,
                                    )}
                                >
                                    <HorizontalBar
                                        value={item.authorPercent}
                                        color={themeColors.sparkline}
                                    />
                                </td>
                            </>
                        )}
                        {showSparkline && (
                            <td
                                aria-label="sparkline"
                                className={cx(classes.sparkline, classes.td)}
                            >
                                <Tooltip tooltip={sparklineTooltip}>
                                    <Sparkline
                                        height={22}
                                        width={showUniqueAuthors ? 65 : 110}
                                        series={item.authorSeries}
                                        withTrend
                                        className="topten-sparkline"
                                        color={themeColors.sparkline}
                                        tabIndex={isCardInteractivityDisabled ? undefined : '0'}
                                    />
                                </Tooltip>
                            </td>
                        )}
                        {!!extraColumns &&
                            extraColumns.map((col) => (
                                <td aria-label={col.key} className={cx(col.classname, classes.td)}>
                                    {item[col.key]}
                                </td>
                            ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

TopTenTable.propTypes = {
    authorTotalTooltip: PropTypes.string.isRequired,
    itemLabel: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            total: PropTypes.number,
            authorTotal: PropTypes.number,
            authorPercent: PropTypes.number,
            authorSeries: PropTypes.arrayOf(
                PropTypes.shape({
                    date: PropTypes.string,
                    value: PropTypes.number,
                }),
            ),
        }),
    ),
    showMentions: PropTypes.bool,
    showSparkline: PropTypes.bool,
    showUniqueAuthors: PropTypes.bool,
    sparklineTooltip: PropTypes.string.isRequired,
    extraColumns: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            key: PropTypes.string,
            className: PropTypes.string,
        }),
    ),
    isCardInteractivityDisabled: PropTypes.bool,
};

TopTenTable.defaultProps = {
    showMentions: true,
    showSparkline: false,
    showUniqueAuthors: false,
};

export default TopTenTable;
