import {useMemo} from 'react';

//Hooks
import {useSearchesById} from 'hsi/hooks/useSearchesById';

//Utils
import {formatBigInt, formatTableSortNumber} from 'hsi/utils/formatNumber';

//Other
import tns from 'hsi/i18n/tns';
const t = tns('multipleSearch');

//The hook
export const useTableParser = ({nameField, searchIds}) => {
    const {searchesById} = useSearchesById();

    const fields = useMemo(
        () => [
            {
                ...nameField,
                id: 'name',
                label: nameField.label,
                width: '2fr',
            },
            {
                id: 'value',
                label: t('cardsTableTotalField'),
                sortFormat: formatTableSortNumber,
                format: (value) => formatBigInt(value),
            },
            ...(searchIds || []).map((id) => ({
                id: String(id),
                label: searchesById[id]?.name,
                sortFormat: formatTableSortNumber,
                format: (value) => formatBigInt(value),
            })),
        ],
        [nameField, searchesById, searchIds],
    );

    return {fields};
};
