import {useMemo} from 'react';
import {useDispatch} from 'react-redux';

//Actions
import {loadSearchResults, abortSearchResults} from 'hsi/actions/resultsActions';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import useFlags from 'hsi/hooks/useFlags';

//The hook
export default function useGetLoadData(type) {
    const dispatch = useDispatch();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();

    return useMemo(() => {
        const func = () => dispatch(loadSearchResults(type, flags, pageTypes));

        func.abort = () => {
            // TODO: Stop this from triggering multiple times
            // type === 'topicWheel' && console.log('[T] Abort topic wheel load ', type);
            dispatch(abortSearchResults(type));
        };

        return func;
    }, [dispatch, flags, type, pageTypes]);
}
