import React from 'react';
import IconButton from '@mui/material/IconButton';
import IconRouter from 'hsi/components/IconRouter';
import useStyles from './styles';

type PaginationButtonsArgs = {
    page: number;
    totalPages: number;
    setPage: (newPage: number) => void;
};

const PaginationButtons = ({page, totalPages, setPage}: PaginationButtonsArgs) => {
    const cl = useStyles();

    return totalPages <= 1 ? null : (
        <div className={cl.paginationButtons}>
            <IconButton
                aria-label="previous page"
                disabled={page <= 1}
                onClick={() => setPage(page - 1)}
                size="small"
            >
                <IconRouter name="left-arrow" />
            </IconButton>

            <div className={cl.pageIndicator}>
                {page}/{totalPages}
            </div>

            <IconButton
                aria-label="next page"
                disabled={page >= totalPages}
                onClick={() => setPage(page + 1)}
                size="small"
            >
                <IconRouter name="right-arrow" />
            </IconButton>
        </div>
    );
};

export default PaginationButtons;
