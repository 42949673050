import React, {forwardRef} from 'react';

//Components
import TopMeasureBySearch from 'hsi/components/Cards/TopMeasureBySearch';
import NameCell from 'hsi/components/CardTable/v2/NameCell';

//Utils
import {drillInFilters} from 'hsi/utils/filters';

//Other
import tns from 'hsi/i18n/tns';
const t = tns('cards.tophashtagsBySearch');

//Consts
const type = 'tophashtagsBySearch';
const dataSelector = (state) => state.chart[type];
const drillInData = ({name: hashtag}) => ({
    type,
    label: hashtag,
    tracking: {
        name: 'cardDrilledIn',
        type,
        value: hashtag,
    },
    filter: drillInFilters.hashtag(hashtag),
});
const nameField = {
    label: t('nameFieldLabel'),
    format: (hashtag) => <NameCell name={hashtag} />,
};

//The component
const TopHashtagsBySearch = forwardRef(function TopHashtagsBySearch(props, ref) {
    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={dataSelector}
            drillInData={drillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopHashtagsBySearch;
