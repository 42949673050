import React, {forwardRef} from 'react';
import isEmpty from 'lodash/isEmpty';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import CardTable from 'hsi/components/CardTable/v2';

//Hooks
import useCardData from 'hsi/hooks/useCardData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import {useTableParser} from './useTableParser';

//The component
const TopMeasureBySearchCard = forwardRef(function TopMeasureBySearchCard(
    {title, type, dataSelector, drillInData, nameField, ...props},
    ref,
) {
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector,
    });

    const drillInMentions = useDrillInMentions();
    const {searchIds} = useMultipleSearchIds();
    const {fields} = useTableParser({nameField, searchIds});

    const defaultSort = 'value'; //i.e. total;

    return (
        <CardLoadState
            title={<CardTitle title={title} type={type} hasData={!isEmpty(data)} />}
            data-testid={type}
            error={error}
            hasData={!isEmpty(data)}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            type={type}
            {...props}
        >
            <CardTable
                fields={fields}
                items={data}
                type={type}
                defaultSort={defaultSort}
                onClickRow={(d) => drillInMentions(drillInData(d))}
            />
        </CardLoadState>
    );
});

export default TopMeasureBySearchCard;
