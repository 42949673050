import React from 'react';
import cn from 'classnames';
import makeStyles from '@mui/styles/makeStyles';

//Components
import SaveSearchButton from '../SaveSearchButton';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import useTableStyles from '../TopTenTable/styles';

//The component
export default function UniqAuthorsCTA({type, variant}) {
    const tableClasses = useTableStyles();
    const classes = useStyles();

    let msg = T('uniqAuthorsCTA.message', {
        splitBy: T(`cards.${type}.uniqAuthorCTASplitBy`),
    }).split('[link]');

    const saveLink = <SaveSearchButton inlineLink>{T('uniqAuthorsCTA.linkText')}</SaveSearchButton>;

    msg.splice(1, 0, saveLink);

    return (
        <div>
            <table
                className={cn(
                    tableClasses.table,
                    classes.table,
                    variant && classes[`${variant}Variant`],
                )}
            >
                <thead>
                    <tr>
                        <th className={tableClasses.th} align="left">
                            {T('uniqAuthors')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {[1, 2, 3].map((i) => (
                        <tr key={i} className={tableClasses.tr}>
                            <td className={tableClasses.td}>
                                <div className={classes.mockRow}>
                                    <div className={classes.mockRowPart1} />
                                    <div className={classes.mockRowPart2} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className={classes.msg}>
                {msg.map((part, i) => (
                    <span key={i}>{part}</span>
                ))}
            </div>
        </div>
    );
}

export const useSplitColumnsStyles = makeStyles((theme) => ({
    columns: {
        display: 'flex',
        overflow: 'hidden',
        gap: '24px',
        '&>*': {
            flex: '1 1 0',
            overflow: 'hidden',
        },
    },
}));
