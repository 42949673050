//TODO What is this for
//TODO better typing

const drillInFilters = {
    author: (authorName: string, domain: string | undefined, isSavedSearch: boolean) =>
        isSavedSearch
            ? domain
                ? {
                      site: {
                          fields: {
                              domain: {include: [domain], exclude: []},
                          },
                      },
                      author: {
                          fields: {
                              author: {include: [authorName], exclude: []},
                          },
                      },
                  }
                : {
                      author: {
                          fields: {
                              author: {include: [authorName], exclude: []},
                          },
                      },
                  }
            : {
                  ownedContent: {
                      fields: {
                          author: {include: [authorName], exclude: []},
                          domain: {include: domain ? [domain] : [], exclude: []},
                      },
                  },
              },
    domain: (domain: string, isSavedSearch: boolean) => {
        return {
            [isSavedSearch ? 'site' : 'ownedContent']: {
                fields: {
                    domain: {include: [domain], exclude: []},
                },
            },
        };
    },
    hashtag: (hashtag: any) => ({insightsHashtag: hashtag}),
    queryId: (queryId: any) => ({queryId}),
    sharedUrl: (url: any) => ({insightsUrl: url}),
    topic: (topicName: any) => ({keyword: topicName}),
} as const;

export default drillInFilters;
