//TODO this should be using the meter html element to be accessible and semantic

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from 'hsi/components/Tooltip';

import {formatPercent} from 'hsi/utils/formatNumber';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

import useStyles from './styles';

export default function HorizontalBar({value, displayValue, className}) {
    const classes = useStyles();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    return (
        <Tooltip tooltip={formatPercent(displayValue || value)} distance={4} portal>
            <div
                className={cn(classes.root, className)}
                tabIndex={isCardInteractivityDisabled ? undefined : '0'}
            >
                <div className={classes.bg} />
                <div
                    className={classes.value}
                    data-testid="horizontalBar"
                    style={{width: formatPercent(value)}}
                />
            </div>
        </Tooltip>
    );
}

HorizontalBar.propTypes = {
    value: PropTypes.number.isRequired,
    displayValue: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
};

HorizontalBar.defaultProps = {
    value: 0.5,
};
