import React, {useEffect} from 'react';

import {
    updatePage,
    SortableTableType,
    isPaginableTableType,
} from 'hsi/slices/cardTables';
import {sortCardTables} from 'hsi/actions/resultsActions'
import {selectSavedSearchId, selectCardTableSort, selectCardTablePage} from 'hsi/selectors';
import useEventTrack from 'hsi/hooks/useEventTrack';
import Table from './Table';
import PaginationButtons from './PaginationButtons';
import usePagination from './usePagination';
import useSort, {SortDirectionType} from './useSort';

import {DATA_LOADED} from 'hsi/constants/actionTypes';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import {ReactNode} from 'react-markdown';

const defaultMaxRows = 10;

/*
 * fields {
 *  id
 *  label
 *  format
 *  sortFormat
 *  width
 * }
 *
 * items [
 *  { [field.id]: value }
 * ]
 *
 */

type OnSortArgs = {
    sortDirChanged: boolean;
    sortKeyChanged: boolean;
};

//I couldn't figure out a way to link the type of 'value' in format and sortFormat to the type of T[id],
//so I have had to just use 'any'. I don't think it's actually possible to do this?
export type CardTableField<T> = {
    id: keyof T;
    label: ReactNode;
    format?: (value: any, datum: T, index?: number) => ReactNode; //value = item[field.id]
    sortFormat?: (value: any, item: T) => string | number; //value = item[field.id]
    disableSort?: boolean;
    width?: string; //I think this needs to be a css string? GridTemplateColumns<number | string> - but just one value!
};

type CardTableArgs<T> = {
    fields: CardTableField<T>[];
    items: T[];
    type: SortableTableType;
    defaultSort: keyof T;
    defaultSortDir?: SortDirectionType;
    maxRows?: number;
    onClickRow?: (item: T) => void;
    onSort?: (args: OnSortArgs) => void;
    paginate?: boolean;
    styleProps?: Parameters<typeof Table>[0]['styleProps'];
};

function CardTable<T>({
    fields,
    items,
    type,
    defaultSort,
    defaultSortDir,
    maxRows = defaultMaxRows,
    onClickRow,
    onSort: onSortCb,
    paginate = false,
    styleProps = {},
}: CardTableArgs<T>) {
    const dispatch = useAppDispatch();
    const savedSearchId = useAppSelector(selectSavedSearchId)!;
    const {trackWithSearchData} = useEventTrack();

    const {sortDir: initialSortDir, sortKey: initialSortKey} =
        useAppSelector(selectCardTableSort(type, savedSearchId)) || {};
    const initialPage = useAppSelector(
        selectCardTablePage(isPaginableTableType(type) ? type : undefined, savedSearchId),
    );

    const onSortChanged = ({
        sortDir: nextSortDir,
        sortKey: nextSortKey,
    }: {
        sortDir: SortDirectionType;
        sortKey: keyof T;
    }) => {
        dispatch(sortCardTables(type, savedSearchId, nextSortDir, nextSortKey));

        !!onSortCb &&
            onSortCb({
                sortDirChanged: sortDir !== nextSortDir,
                sortKeyChanged: sortKey !== nextSortKey,
            });

        trackWithSearchData('cardTableSorted', {
            sortDir: nextSortDir,
            sortField: nextSortKey,
            type,
        });
    };

    const onPageChanged = ({page}: {page: number}) =>
        isPaginableTableType(type) &&
        dispatch(
            updatePage({
                type,
                savedSearchId,
                value: page,
            }),
        );

    //Why are we doing this here?
    const {sortedItems, sortKey, sortDir, onSort} = useSort({
        items,
        fields,
        onSortChanged,
        defaultSortKey: defaultSort,
        defaultSortDir,
        initialSortKey: initialSortKey as keyof T,
        initialSortDir,
    });

    //UGLY FIX TO GET TWITTER FOLLOWERS SORTING IN CSV EXPORTS FOR TOP AUTHORS.
    //Because the data sorted is not persisted in the redux state and only the sorting key and dir
    // it's not a problem for a rendered card but it is for csv exports which consumes the data
    // present in the redux state and renders no card.
    // TODO: please remove it and find a more elegant solution. Please. Really
    useEffect(() => {
        sortKey === 'twitterFollowers' &&
            dispatch({
                type: DATA_LOADED,
                payload: {
                    data: sortedItems,
                    type,
                },
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortKey, sortDir]);

    const {pageItems, totalPages, page, setPage} = usePagination({
        items: sortedItems,
        itemsPerPage: maxRows,
        onPageChanged,
        initialPage,
        paginate,
    });

    return (
        <>
            <Table
                items={pageItems}
                fields={fields}
                onClickRow={onClickRow}
                sortKey={sortKey}
                sortDir={sortDir}
                onSort={onSort}
                styleProps={styleProps}
            />
            {paginate && (
                <PaginationButtons page={page} totalPages={totalPages} setPage={setPage} />
            )}
        </>
    );
}

export default CardTable;
