import { isFirefox } from 'react-device-detect';

import { APIFilterFormat, Aggregates, Breakdowns, DateRange } from 'hsi/types/filters.js';
import { QueryContextType } from 'hsi/types/query';
import { LinkedinChannelIdsType } from 'hsi/types/shared.js';
import workerAsyncConnector from 'hsi/utils/workerAsyncConnector';
import loadData from './loadData';

// Once we upgrade to webpack 5, we can use this syntax:
//const worker = new Worker(new URL('./worker.js', import.meta.url));

//But as long as we have webpack 4, we need to use this syntax:
import { RootReducer } from 'hsi/reducers/rootReducer.js';
import Worker from './worker.js';
const worker = new (Worker as any)();
//end webpack 4

// firefox currently has a bug where the web worker is using a new cookie so auth is failing.
// so we don't use a web worker if its firefox.
// jira ticket: https://brandwatch.atlassian.net/browse/SW-13254 
const workerAsync = isFirefox ? {loadData} : workerAsyncConnector(worker, null); 

export default workerAsync as {
    loadData: (
        queryContext: QueryContextType,
        type: string,
        apiFilterParams: APIFilterFormat,
        dateRange: DateRange,
        breakdowns: Breakdowns,
        aggregates: Aggregates,
        additionalQueries: number[],
        cardTables: RootReducer['cardTables'],
        linkedinChannelIds: LinkedinChannelIdsType,
        rules:
            | {
                  additionalCall: string;
                  additionalCallRequires: (...args: any[]) => any; //??
              }
            | null
            | undefined,
        config: any, //?
    ) => Promise<{data: any; noData: boolean | null}>;
};


