import {useCallback} from 'react';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import {drillInFilters} from 'hsi/utils/filters';

const useDrillInData = ({type}) => {
    const isSavedSearch = useIsSavedSearch();

    const drillInData = useCallback(
        ({name: authorName, domain}) => ({
            type,
            dates: null,
            drillInFrom: domain || undefined,
            filter: drillInFilters.author(authorName, domain, isSavedSearch),
            label: authorName,
            tracking: {
                name: 'cardDrilledIn',
                value: authorName,
            },
        }),
        [type, isSavedSearch],
    );

    return {drillInData};
};

export default useDrillInData;
