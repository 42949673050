import useIsCardSelected from 'hsi/hooks/useIsCardSelected';
import useGetLoadData from 'hsi/components/Cards/useGetLoadData';
import {CardType} from 'hsi/types/cards';
import {useAppSelector} from './useRedux';
import {RootState} from 'hsi/utils/configureStore';
import {DefaultChartStateType} from 'hsi/slices/chart';

/*
 * hook to get data and loading state for a card
 * to use with <CardLoadState/>
 */

type UseCardDataArgs<T> = {
    type: CardType;
    dataSelector: (state: RootState) => DefaultChartStateType<T>;
};

const useCardData = <T = any>({type, dataSelector}: UseCardDataArgs<T>) => {
    const {data, loading, loaded, error} = useAppSelector(dataSelector);
    const loadData = useGetLoadData(type);
    const isCardSelected = useIsCardSelected(type);
    return {data, loading, loaded, error, loadData, isCardSelected};
};

export default useCardData;
