export const BCR_URLS = {
    authorCountries: '/search/data/authors/all/countries',
    emotionHistory: '/search/data/volume/days/emotions',
    emotionVolume: '/search/data/volume/all/emotions',
    gender: '/search/data/volume/all/gender',
    geography: '/search/data/volume/all/countries',
    heatmap: '/search/data/volume/dayOfWeek/hourOfDay',
    history: '/search/data/volume/all/days',
    languageAuthors: '/search/data/authors/all/languages',
    mentionsHistory: '/search/data/volume/days/all',
    sentimentHistory: '/search/data/volume/days/sentiment',
    sentimentVolume: '/search/data/volume/all/sentiment',
    topauthors: '/search/data/volume/topauthors',
    tophashtags: '/search/data/volume/tophashtags',
    topinterests: '/search/data/volume/interest/all',
    toplanguages: '/search/data/volume/all/languages',
    totalMentions: '/search/data/mentions/count',
    topsites: '/search/data/volume/topsites',
    topSharedURLs: '/search/data/volume/topsharedurls',
    wordCloud: '/search/data/topics',
} as const;

//'{breakdown}' and '{aggragate}' get replaced when building a url
export const QUERY_URLS = {
    authorCountries: '/data/authors/queries/countries',
    benchmark: '/data/volume/{breakdown}/days',
    benchmarkBySearch: '/data/volume/all/queries',
    shareOfVoice: '/data/volume/all/queries',
    emotionHistory: '/data/volume/{breakdown}/emotions',
    emotionVolume: '/data/volume/all/emotions',
    gender: '/data/authors/all/gender',
    geography: '/data/volume/queries/countries',
    heatmap: '/data/volume/dayOfWeek/hourOfDay',
    interestTotals: '/data/volume/queries/interest',
    languageAuthors: '/data/authors/queries/languages',
    mentionsHistory: '/data/{aggregate}/{breakdown}/queries',
    netSentimentHistory: '/data/netSentiment/{breakdown}/queries',
    pageTypeBySearch: '/data/volume/queries/pageTypes',
    sentimentHistory: '/data/volume/{breakdown}/sentiment',
    sentimentVolume: '/data/volume/all/sentiment',
    sentimentVolumeBySearch: '/data/volume/queries/sentiment',
    topauthors: '/data/volume/topauthors/queries',
    topauthorsBySearch: '/data/volume/topauthors/queries',
    tophashtags: '/data/hashtags',
    tophashtagsBySearch: '/data/hashtags',
    topicWheel: '/data/topics/wheel',
    topinterests: '/data/demographics/interest',
    toplanguages: '/data/volume/queries/languages',
    topsites: '/data/volume/topsites/queries',
    topsitesBySearch: '/data/volume/topsites/queries',
    topSharedURLs: '/data/urls',
    toptopicsBySearch: '/data/topics',
    totalVolume: {
        authors: '/data/authors/all/queries',
        avgFollowers: '/fe-api/metrics/averageFollowers',
        impressions: '/data/impressions/all/queries',
        reachEstimate: '/data/reachEstimate/all/queries',
        retweetRate: '/fe-api/metrics/retweetRate',
        volume: '/data/volume/all/queries',
    },
    totalVolumeBySearch: {
        authors: '/data/authors/all/queries',
        avgFollowers: '/fe-api/metrics/averageFollowers',
        impressions: '/data/impressions/all/queries',
        reachEstimate: '/data/reachEstimate/all/queries',
        retweetRate: '/fe-api/metrics/retweetRate',
        volume: '/data/volume/all/queries',
    },
    wordCloud: '/data/topics',
} as const;
