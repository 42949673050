import cn from 'classnames';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from 'hsi/components/Button';
import HelpIcon from 'hsi/components/HelpIcon';
import IconRouter from 'hsi/components/IconRouter';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import {OverflowTooltip} from 'hsi/components/SimpleTooltip';
import PulseLoader from 'hsi/components/PulseLoader';
import SearchBox from 'hsi/components/SearchBox';
import Tooltip from 'hsi/components/Tooltip';

import useOnScreen from 'hsi/hooks/useOnScreen';
import useStyles from './styles';

import {T} from 'hsi/i18n';

export enum Order {
    Asc = 'asc',
    Desc = 'desc',
}

export type FieldWithoutTooltip = {
    id: keyof Data;
    format?: (label: any) => React.ReactNode;
    isSortable: boolean;
    sortId?: string;
    label: any;
    renderCell?: (props: RenderDefaultCellProps) => React.ReactNode;
    tooltip?: never;
    tooltipLbl?: never;
    width?: string; //TODO: Apply this
};

export type FieldWithTooltip = {
    id: keyof Data;
    format?: (label: any) => React.ReactNode;
    isSortable: boolean;
    sortId?: string;
    label: any;
    renderCell?: (props: RenderDefaultCellProps) => React.ReactNode;
    tooltip: string;
    tooltipLbl: string;
    width?: string; //TODO: Apply this
};

export type Field = FieldWithoutTooltip | FieldWithTooltip;

export function isFieldWithTooltip(field: Field): field is FieldWithTooltip {
    return !!field.tooltip;
}

export type Data = {
    id: string;
    [key: string]: any;
};

export type Action = {
    id: string;
    icon: string;
    handleClick: (data: Data) => void;
    label: string;
};

export type DataTableProps = {
    actions?: Action[];
    actionsClassName?: string;
    caption: string;
    className?: string;
    defaultOrder?: Order;
    data?: Data[];
    defaultFilter?: string;
    defaultOrderBy?: Field['id'];
    error?: boolean;
    fields: Field[];
    filterPlaceholder?: string;
    hasFilter?: boolean;
    label: string;
    labelledby?: string;
    loading?: boolean;
    noResults?: boolean;
    notFound?: boolean;
    onFilter?: (value: string) => void;
    onSort?: (newOrderBy: Field['id'], newOrder: Order) => void;
    onVisible?: () => void;
    renderAction?: (props: RenderDefaultActionProps) => React.ReactNode;
    renderError?: (props: RenderDefaultErrorProps) => React.ReactNode;
    renderNotFound?: (props: RenderDefaultNotFoundProps) => React.ReactNode;
    renderNoResults?: (props: {classes: ReturnType<typeof useStyles>}) => React.ReactNode;
    totalRows?: number;
};

const DataTable = ({
    actions,
    actionsClassName,
    caption,
    className,
    data: _data,
    defaultFilter = '',
    defaultOrder = Order.Desc,
    defaultOrderBy,
    error,
    fields,
    filterPlaceholder = T('dataTable.filterPlaceholder'),
    hasFilter = false,
    label,
    labelledby,
    loading = false,
    notFound,
    noResults,
    onFilter,
    onSort,
    onVisible,
    renderAction = renderDefaultAction,
    renderError = renderDefaultError,
    renderNotFound = renderDefaultNotFound,
    renderNoResults = renderDefaultNotFound, // TODO: Should have a specific default no results message
    totalRows,
}: DataTableProps) => {
    const classes = useStyles();

    const onScreenRef: any = useRef<HTMLDivElement>();
    const onScreen = useOnScreen(onScreenRef, '-10px');

    const [filter, setFilter] = useState<string>(defaultFilter);
    const [order, setOrder] = useState<Order>(defaultOrder);
    const [orderBy, setOrderBy] = useState<Field['id'] | undefined>(defaultOrderBy);

    const data = useMemo(() => _data?.slice(0, totalRows) || [], [_data, totalRows]);
    const hasMoreData = useMemo(
        () => totalRows && _data && _data.length < totalRows,
        [_data, totalRows],
    );

    const handleSort = useCallback(
        (id: Field['id'], sortId?: Field['sortId']) => {
            setOrderBy(id);
            const newOrder = order === Order.Asc ? Order.Desc : Order.Asc;
            setOrder(newOrder);
            onSort?.(sortId || id, newOrder);
        },
        [onSort, order],
    );

    const onCtaClick = useCallback(() => {
        setFilter('');
        onFilter?.('');
    }, [onFilter]);

    const getActions = useCallback(
        (classes: RenderDefaultActionProps['classes'], item: Data) =>
            actions?.map((action) => renderAction({action, classes, item})),
        [actions, renderAction],
    );

    useEffect(() => {
        if (onScreen) {
            onVisible?.();
        }
    }, [onScreen, onVisible]);

    return (
        <>
            {hasFilter && !error && !noResults && (
                <SearchBox // Should have a label
                    autoFocus={false}
                    className={classes.filter}
                    onChange={(event) => {
                        setFilter(event.target.value);
                        onFilter?.(event.target.value);
                    }}
                    placeholder={filterPlaceholder}
                    value={filter}
                />
            )}
            <TableContainer className={classes.container}>
                {error && renderError({classes})}
                {loading && (
                    <div
                        className={cn(classes.wrapper, classes.loading)}
                        data-testid="dataTableLoading"
                    >
                        <PulseLoader />
                    </div>
                )}
                {noResults && renderNoResults?.({classes})}
                {!error && !noResults && (
                    <Table
                        aria-label={label}
                        aria-labelledby={labelledby}
                        className={cn(classes.table, className)}
                        padding="none" // This is applied with CSS. TODO, probably override the padding
                        stickyHeader
                    >
                        <caption className="offscreen">{caption}</caption>
                        <TableHead>
                            <TableRow className={classes.header}>
                                {fields?.map((field) => (
                                    <TableHeaderCell
                                        key={field.id}
                                        active={
                                            !!orderBy &&
                                            (orderBy === field?.sortId || orderBy === field.id)
                                        }
                                        classes={classes}
                                        handleSort={() => handleSort(field.id, field?.sortId)}
                                        isSortable={field.isSortable}
                                        field={field}
                                        order={order}
                                    />
                                ))}
                                {(actions?.length || 0) > 0 && (
                                    <TableCell
                                        align="right"
                                        aria-label={T('dataTable.actionLabel')}
                                        className={actionsClassName}
                                    />
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.body}>
                            {notFound && (
                                <TableRow>
                                    <TableCell colSpan={fields.length}>
                                        {renderNotFound({classes, onCtaClick})}
                                    </TableCell>
                                </TableRow>
                            )}
                            {data.map((item) => (
                                <TableRow key={item.keyId || item.id} className={classes.row} hover>
                                    {fields?.map((field) => (
                                        <TableCell key={field.id} className={classes.rowCell}>
                                            {(field.renderCell || renderDefaultCell)?.({
                                                classes,
                                                item,
                                                label:
                                                    field.format?.(item[field.id]) ||
                                                    item[field.id],
                                            })}
                                        </TableCell>
                                    ))}
                                    {(actions?.length || 0) > 0 && (
                                        <TableCell align="right">
                                            <div className={cn(classes.actions, 'actions')}>
                                                {getActions(classes, item)}
                                            </div>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <div
                    className={cn(
                        classes.wrapper,
                        classes.loadingMore,
                        error || loading || notFound || noResults || !hasMoreData
                            ? classes.notLoading
                            : false,
                    )}
                    data-testid="dataTableScrollLoader"
                    ref={onScreenRef}
                >
                    <PulseLoader />
                </div>
            </TableContainer>
        </>
    );
};

type TableHeaderCellProps = {
    active: boolean;
    classes: ReturnType<typeof useStyles>;
    field: Field;
    handleSort: () => void;
    isSortable?: boolean;
    order: Order;
};

const TableHeaderCell = ({
    active,
    classes,
    field,
    handleSort,
    isSortable,
    order,
}: TableHeaderCellProps) => {
    const content = useMemo(
        () => (
            <OverflowTooltip tooltip={field.label}>
                <div
                    className={cn(
                        isSortable ? classes.headerCellTextActive : classes.headerNoSort,
                        classes.headerCellText,
                    )}
                >
                    {field.label}
                </div>
            </OverflowTooltip>
        ),
        [classes, field.label, isSortable],
    );

    const tooltip = useMemo(
        () =>
            isFieldWithTooltip(field) ? (
                <InfoPopupContent copy={field.tooltip} title={field.label} markdown />
            ) : null,
        [field],
    );

    return (
        <TableCell
            key={field.id}
            align="left"
            className={classes.headerCell}
            sortDirection={active ? order : false}
        >
            <div className={classes.headerCellContainer}>
                {isSortable ? (
                    <ButtonBase
                        className={cn(
                            classes.headerSortButton,
                            active && classes.headerSortButtonActive,
                        )}
                        onClick={handleSort}
                    >
                        {content}
                        <div className={classes.headerSortIconWrapper}>
                            <IconRouter
                                className={cn(
                                    classes.headerSortIcon,
                                    active && order === Order.Asc
                                        ? classes.headerSortIconActive
                                        : classes.headerSortIconInactive,
                                )}
                                name="arrow-drop-up"
                            />
                            <IconRouter
                                className={cn(
                                    classes.headerSortIcon,
                                    active && order === Order.Desc
                                        ? classes.headerSortIconActive
                                        : classes.headerSortIconInactive,
                                )}
                                name="arrow-drop-down"
                            />
                        </div>
                    </ButtonBase>
                ) : (
                    content
                )}
                {isFieldWithTooltip(field) && (
                    <HelpIcon
                        buttonLbl={field.tooltipLbl}
                        className={classes.headerCellTooltip}
                        placement="top"
                        tooltip={tooltip!}
                    />
                )}
            </div>
        </TableCell>
    );
};

export interface RenderDefaultActionProps {
    action: Action;
    classes: ReturnType<typeof useStyles>;
    item: Data;
}

const renderDefaultAction = ({action, classes, item}: RenderDefaultActionProps) => (
    <Tooltip key={action.id} tooltip={action.label}>
        <IconButton aria-label={action.label} onClick={() => action.handleClick(item)} size="large">
            <IconRouter className={classes.actionIcon} name={action.icon} />
        </IconButton>
    </Tooltip>
);

export interface RenderDefaultCellProps {
    classes: ReturnType<typeof useStyles>;
    item: Data;
    label?: string;
}

const renderDefaultCell = ({classes, label}: RenderDefaultCellProps) => (
    <OverflowTooltip tooltip={label}>
        <div className={classes.cell}>{label || '-'}</div>
    </OverflowTooltip>
);

export interface RenderDefaultErrorProps {
    classes: ReturnType<typeof useStyles>;
}

const renderDefaultError = ({classes}: RenderDefaultErrorProps) => (
    <div className={classes.wrapper}>{T('dataTable.errorTitle')}</div>
);

export interface RenderDefaultNotFoundProps {
    classes: ReturnType<typeof useStyles>;
    onCtaClick?: () => void;
}

const renderDefaultNotFound = ({classes, onCtaClick}: RenderDefaultNotFoundProps) => (
    <div className={cn(classes.wrapper, classes.notFound)}>
        <IconRouter className={classes.notFoundIcon} name="search" />
        <h3 className={classes.notFoundTitle}>{T('dataTable.noResultsTitle')}</h3>
        <p className={classes.notFoundDesc}>{T('dataTable.noResultsDesc')}</p>
        {onCtaClick && (
            <Button className={classes.notFoundButton} onClick={onCtaClick} priority="primary">
                {T('dataTable.noResultsCta')}
            </Button>
        )}
    </div>
);

export default DataTable;
