import ThemeBuilder from 'hsi/classes/ThemeBuilder';

//The theme
const theme = new ThemeBuilder('base', false, {
    mixin: ({font}) => ({
        //Mixins
        offscreen: {
            clip: 'rect(0 0 0 0)',
            clipPath: 'inset(50%)',
            height: '1px',
            overflow: 'hidden',
            position: 'absolute',
            whiteSpace: 'nowrap',
            width: '1px',
        },
        truncate: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        wordBreak: {
            overflowWrap: 'break-word',
        },
        blankButton: {
            border: 0,
            padding: 0,
            margin: 0,
            background: 'transparent',
            cursor: 'pointer',
            font: 'inherit',
            fontFamily: font?.name,
            lineHeight: 'inherit',
        },
        downArrow: {
            content: '""',
            display: 'block',
            width: '7px',
            height: '7px',
            border: `2px solid currentColor`,
            borderWidth: '0 2px 2px 0',
            transform: 'translate(5px, -50%) rotate(45deg)',

            position: 'absolute',
            top: 'calc(50% - 1px)',
            left: '100%',
        },
        icon: {
            smallSize: {
                height: 14,
                width: 14,
            },
            normalSize: {
                height: 18,
                width: 18,
            },
            bigSize: {
                height: 24,
                width: 24,
            },
            massiveSize: {
                height: 36,
                width: 36,
            },
        },
    }),
    globals: ({mixin}) => ({
        //Globals
        html: {
            height: '100%',
        },
        body: {
            minHeight: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
            minWidth: '1280px',
        },
        '#header': {
            flex: '0 0 auto',
        },
        '#root': {
            flex: '1 1 auto',
            width: '100%',
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        '.offscreen': {
            ...mixin.offscreen,
        },
        '.hiddenCard': {
            position: 'absolute',
            zIndex: '-100000',
            top: '1px',
            width: '688px',
        },
        '.truncate': {
            ...mixin.truncate,
        },

        a: {...mixin.linkColor},
    }),
    breakpoints: () => ({
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1440,
        },
    }),
});

export default theme;
