import {forwardRef} from 'react';
import isEmpty from 'lodash/isEmpty';

import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import CardTable from 'hsi/components/CardTable/v2';

import InfoPopup from './InfoPopup';
import useTableFields from './useTableFields';
import useDrillInData from './useDrillInData';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';

import {selectCardTableSortPersist} from 'hsi/selectors';
import {persistActiveQuery} from 'hsi/actions/queryActions';

const type = 'topauthors';

//The component
const TopAuthors = forwardRef(({title, ...props}, ref) => {
    const drillInMentions = useDrillInMentions();
    const {drillInData} = useDrillInData({type});
    const isSavedSearch = useIsSavedSearch();
    const dispatch = useAppDispatch();

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });
    const fields = useTableFields({data, type});

    const defaultSort = 'value'; //i.e. total;

    const onSort = ({sortDirChanged, sortKeyChanged}) => {
        if (sortKeyChanged) loadData();
        dispatch(persistActiveQuery());
    };

    const hasData = !isEmpty(data);
    const {sortDir = 'desc', sortKey = defaultSort} = useAppSelector(selectCardTableSortPersist(type));

    return (
        <CardLoadState
            title={
                <CardTitle
                    title={title}
                    type={type}
                    tooltipComponent={<InfoPopup type={type} isSavedSearch={isSavedSearch} />}
                    hasData={hasData}
                />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <CardTable
                fields={fields}
                items={data}
                type={type}
                paginate={true}
                defaultSort={sortKey}
                defaultSortDir={sortDir}
                onSort={onSort}
                styleProps={{
                    rowHeight: '58px',
                }}
                onClickRow={(d) => drillInMentions(drillInData(d))}
            />
        </CardLoadState>
    );
});

export default TopAuthors;
