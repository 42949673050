import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {T} from 'hsi/i18n';

const useStyles = makeStyles((theme) => ({
    newBadge: {
        ...theme.elements.newBadge,
    },
}));

const NewBadge = () => {
    const cl = useStyles();
    return <span className={cl.newBadge}>{T('newBadge')}</span>;
};

export default NewBadge;
