//TODO test menu items
//TODO implement natsort?

import React, {useState, useMemo, useCallback} from 'react';
import cn from 'classnames';
import {DateTime} from 'luxon';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import IconRouter from 'hsi/components/IconRouter';
import SimpleMenu from 'hsi/components/SimpleMenu';
import OverflowTooltip from 'hsi/components/Tooltip/OverflowTooltip';

//Other
import {T} from 'hsi/i18n';
import defaultUseStyles from './styles';
import useAriaAnnounce from 'hsi/hooks/useAriaAnnounce';

//The component
export default function SortableTable({
    defaultSort,
    defaultSortDir,
    fields,
    getItemMenuProps,
    items,
    itemTypeName,
    menuEntries,
    maxRows,
    noItemsMsg,
    onClickMenu,
    onClickRow,
    onSorted,
    persistedSorting,
    label = T('sortableTable.defaultTableLbL'),
    caption,

    //TODO solve this better
    useStyles = defaultUseStyles,
    useStylesProps = {},
}) {
    //Technically this is a foot-gun - someone could switch the value of useStyles
    //mid-lifecycle and break this component. If that ever happens, just save the
    //first supplied value and always use that for the rest of the components life
    const {classes} = useStyles(useStylesProps);

    const ariaAnnounce = useAriaAnnounce();

    //Calculated values
    const hasRowMenu = !!menuEntries || !!getItemMenuProps;
    const hasItems = Array.isArray(items) && !!items?.length;

    const fieldsObj = useMemo(
        //maps fields arr to {[id]: field}
        () =>
            fields.reduce((obj, item) => {
                obj[item.id] = item;
                return obj;
            }, {}),
        [fields],
    );

    const defaultSortField = useMemo(() => {
        if (fieldsObj[defaultSort]) {
            return defaultSort;
        }

        if (fieldsObj.lastModificationDate) {
            return 'lastModificationDate';
        }

        return fields[0].id;
    }, [defaultSort, fieldsObj, fields]);

    const getDefaultSortDir = useCallback(() => {
        return defaultSortDir || getDefaultSortDirByFieldValue(items?.[0]?.[defaultSortField]);
    }, [defaultSortDir, items, defaultSortField]);

    //Internal state
    const [_sortDir, setSortDir] = useState(() => persistedSorting?.sortDir || getDefaultSortDir());
    const [_sortField, setSortField] = useState(persistedSorting?.sortField || defaultSortField);

    let sortDir = _sortDir;
    let sortField = _sortField;

    if (!fieldsObj[sortField]) {
        //if fields change, and sorted column no longer exists, reset sort column
        setSortField((sortField = defaultSortField));
        setSortDir((sortDir = getDefaultSortDir()));
    }

    //Items
    const sortedItems = useMemo(
        () =>
            [...(hasItems ? items : [])].sort((a, b) =>
                doSort(a, b, sortField, sortDir, fieldsObj),
            ),
        [hasItems, items, sortField, sortDir, fieldsObj],
    );

    const displayItems = useMemo(
        () => (maxRows ? sortedItems.slice(0, maxRows) : sortedItems),
        [sortedItems, maxRows],
    );

    // Callbacks
    const onClickSort = useCallback(
        (nextSortField) => {
            const nextSortDir =
                sortField !== nextSortField
                    ? getDefaultSortDir(nextSortField)
                    : toggleSortDir(sortDir);

            setSortDir(nextSortDir);
            setSortField(nextSortField);

            ariaAnnounce(
                T(
                    nextSortDir === 'asc'
                        ? 'sortableTable.sortOrderChangedToAsc'
                        : 'sortableTable.sortOrderChangedToDesc',
                    {column: fieldsObj[nextSortField].label},
                ),
                'assertive',
            );

            !!onSorted && onSorted({sortDir: nextSortDir, sortField: nextSortField});
        },
        [ariaAnnounce, fieldsObj, getDefaultSortDir, onSorted, sortDir, sortField],
    );

    const _getItemMenuProps = useCallback(
        (item) => {
            const itemMenuProps = {};

            if (menuEntries) {
                itemMenuProps.entries = menuEntries.map((m) => ({
                    ...m,
                    label: m.formatLabel ? m.formatLabel(item) : m.label,
                    onClick: () => m.onClick(item),
                }));
            }

            return getItemMenuProps ? {...itemMenuProps, ...getItemMenuProps(item)} : itemMenuProps;
        },
        [menuEntries, getItemMenuProps],
    );

    const styles = useMemo(
        () => ({
            '--sortableTable-numColumns': fields.length + (hasRowMenu ? 1 : 0),
            gridTemplateColumns:
                fields
                    .map(({width, colSize}, index) => {
                        return colSize ?? `${parseFloat(width) || 1}fr`;
                    })
                    .join(' ') + (hasRowMenu ? ' var(--sortableTable-menuColWidth)' : ''),
        }),
        [fields, hasRowMenu],
    );

    // Rendering
    const header = useMemo(
        () => [
            ...fields.map((field) => (
                <TH
                    key={field.id}
                    field={field}
                    classes={classes}
                    sortField={sortField}
                    sortDir={sortDir}
                    onClickSort={onClickSort}
                />
            )),

            //Menu items column
            ...(hasRowMenu
                ? [
                      <TH
                          field={{id: '___menu___', label: T('actions')}}
                          classes={classes}
                          className={cn(classes.headerCol, classes.actions)}
                          key="menu-pholder"
                      />,
                  ]
                : []),
        ],
        [classes, fields, hasRowMenu, sortDir, sortField, onClickSort],
    );

    const body = useMemo(
        () =>
            hasItems ? (
                displayItems.map((item, index) => (
                    <tr
                        key={`item-${index}`}
                        className={cn(classes.row, !!onClickRow && classes.clickableRow)}
                        data-testid="hsi-tableRow"
                        onClick={() => onClickRow?.(item)}
                    >
                        {fields.map(({id, format, renderFunc, className}) => {
                            const txtVal = format ? format(item[id], item, index) : item[id];
                            const cellContent = renderFunc ? (
                                renderFunc(item)
                            ) : (
                                <OverflowTooltip tooltip={txtVal}>
                                    <span className={classes.colTxt}>{txtVal || '-'}</span>
                                </OverflowTooltip>
                            );

                            return (
                                <td
                                    className={cn(classes.col, className, {
                                        [classes.sorting]: sortField === id,
                                    })}
                                    key={id}
                                >
                                    {cellContent}
                                </td>
                            );
                        })}

                        {hasRowMenu && (
                            <td className={cn(classes.col, classes.menuCol)}>
                                {_getItemMenuProps(item).type === 'iconButtons' ? (
                                    _getItemMenuProps(item).entries.map((entry) => (
                                        <IconButton
                                            key={`${entry.name}-${index}`}
                                            aria-label={entry.label}
                                            className={classes.actionIconButton}
                                            onClick={() => entry.onClick(item)}
                                            size="large"
                                        >
                                            <IconRouter
                                                className={classes.actionIcon}
                                                name={entry.iconName}
                                            />
                                        </IconButton>
                                    ))
                                ) : (
                                    <SimpleMenu
                                        {..._getItemMenuProps(item)}
                                        menuLabel={
                                            item?.[fields?.[0]?.id]
                                                ? `${item[fields[0].id]} menu`
                                                : undefined
                                        }
                                        onClick={onClickMenu}
                                        portal
                                    />
                                )}
                            </td>
                        )}
                    </tr>
                ))
            ) : (
                <tr className={classes.row}>
                    <td className={cn(classes.col, classes.noItemsCell)}>
                        {noItemsMsg || T('sorry.no.items.found', {items: itemTypeName || 'items'})}
                    </td>
                </tr>
            ),
        [
            classes,
            displayItems,
            fields,
            _getItemMenuProps,
            hasRowMenu,
            hasItems,
            itemTypeName,
            onClickMenu,
            onClickRow,
            noItemsMsg,
            sortField,
        ],
    );

    return (
        <table
            className={classes.table}
            style={styles}
            aria-label={T('sortableTable.tableLbl', {label})}
        >
            {caption && <caption className="offscreen">{caption}</caption>}
            <thead className={classes.tableHeader}>
                <tr className={classes.tableHeaderRow}>{header}</tr>
            </thead>
            <tbody className={classes.tableBody} data-testid="hsi-tableBody">
                {body}
            </tbody>
        </table>
    );
}

SortableTable.propTypes = {
    defaultSort: PropTypes.string,
    defaultSortDir: PropTypes.string,
    fields: PropTypes.array,
    getItemMenuProps: PropTypes.func,
    items: PropTypes.array,
    maxRows: PropTypes.number,
    menuEntries: PropTypes.array,
    noItemsMsg: PropTypes.any,
    onClickMenu: PropTypes.func,
    onClickRow: PropTypes.func,
    persistedSorting: PropTypes.object,
    type: PropTypes.string,
    caption: PropTypes.string,
};

//Internal helper components
function TH({field, classes, sortField, sortDir, onClickSort}) {
    const {id, label, headerOverflowTooltip, ref} = field;

    const sortable = !!onClickSort;
    const sorted = sortField === id;

    const classNameOptions = {
        [classes.sorting]: sorted,
        [classes.sortable]: sortable,
    };
    const Component = sortable ? 'button' : 'div';

    return (
        <th
            className={cn(
                classes.headerCol,
                onClickSort && classes.headerClickable,
                classNameOptions,
            )}
            data-sort={id}
            ref={ref}
            aria-sort={sorted ? (sortDir === 'asc' ? 'ascending' : 'descending') : undefined}
        >
            <Component
                className={cn(classes.headerContent, classNameOptions)}
                onClick={onClickSort ? () => onClickSort(id) : undefined}
            >
                <OverflowTooltip tooltip={label} active={!!headerOverflowTooltip}>
                    <span
                        className={cn(
                            classes.headerContentWrapper,
                            headerOverflowTooltip && classes.ellipsis,
                            classNameOptions,
                        )}
                        data-sort={id}
                    >
                        {label}
                    </span>
                </OverflowTooltip>
                {sorted && (
                    <span className="offscreen">
                        {T(
                            sortDir === 'asc'
                                ? 'sortableTable.sortedColumnAsc'
                                : 'sortableTable.sortedColumnDesc',
                        )}
                    </span>
                )}
                {sortable && (
                    <div className={classes.arrows} aria-hidden>
                        <IconRouter
                            name="arrow-drop-up"
                            className={cn(classes.arrow, {
                                [classes.sorting]: sortDir === 'asc' && sorted,
                            })}
                        />
                        <IconRouter
                            name="arrow-drop-down"
                            className={cn(classes.arrow, classes.down, {
                                [classes.sorting]: sortDir === 'desc' && sorted,
                            })}
                        />
                    </div>
                )}
            </Component>
        </th>
    );
}

//Helper functions
var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

function doSort(a, b, val, sortDir, fieldsObj) {
    if (a[val] && typeof a[val] === 'string') {
        return collator.compare(a[val], b[val]) * (sortDir === 'asc' ? 1 : -1);
    } else if (fieldsObj[val].sortFormat) {
        //TODO surely custom sort should take presedence? Leaving as-is for now (PP)
        a = fieldsObj[val].sortFormat(a[val], a);
        b = fieldsObj[val].sortFormat(b[val], b);
    } else if (typeof a[val] !== 'string' && fieldsObj[val].format) {
        a = String(fieldsObj[val].format(a[val], a)).toLowerCase();
        b = String(fieldsObj[val].format(b[val], b)).toLowerCase();
    } else {
        a = b = '';
    }

    if (sortDir === 'asc') {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    } else {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
    }
}

const defaultSortDirByType = {
    number: 'desc',
    string: 'asc',
    date: 'desc',
};

function getDefaultSortDirByFieldValue(fieldValue) {
    if (!fieldValue) return 'desc';

    const type = DateTime.fromISO(fieldValue).isValid ? 'date' : typeof fieldValue;

    return defaultSortDirByType[type] || 'desc';
}

function toggleSortDir(sortDir) {
    return sortDir === 'asc' ? 'desc' : 'asc';
}
