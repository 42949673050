import {useMemo} from 'react';
import {drillInFilters} from 'hsi/utils/filters';

export const useDrillInData = ({searchesById, type}) => {
    const searchIdsByName = useMemo(
        () =>
            Object.keys(searchesById).reduce((res, id) => {
                const d = searchesById[id] || {};
                res[d.name] = d.id;
                return res;
            }, {}),
        [searchesById],
    );

    const drillInData = ({name}) => ({
        dates: null,
        filter: drillInFilters.queryId(searchIdsByName[name]),
        label: name,
        tracking: {
            name: 'cardDrilledIn',
            value: name,
        },
        type: 'totalVolumeBySearch',
    });

    return {drillInData};
};
