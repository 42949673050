import {useState, useMemo, useCallback} from 'react';

type UsePaginationArgs<T> = {
    items: T[];
    itemsPerPage: number;
    onPageChanged?: (arg: {page: number}) => void;
    initialPage?: number;
    paginate?: boolean;
};

const usePagination = <T>({
    items,
    itemsPerPage,
    onPageChanged,
    initialPage,
    paginate = false,
}: UsePaginationArgs<T>) => {
    const [_page, _setPage] = useState(initialPage || 1);
    const totalPages = Math.max(Math.ceil((items?.length || 0) / itemsPerPage - 1), 0) + 1;
    const page = Math.max(Math.min(_page, totalPages), 1);
    const start = itemsPerPage * (page - 1);
    const end = start + itemsPerPage;

    const setPage = useCallback(
        (page: number) => {
            _setPage(page);
            !!onPageChanged && onPageChanged({page});
        },
        [_setPage, onPageChanged],
    );

    const pageItems = useMemo(
        () =>
            !paginate
                ? !itemsPerPage
                    ? items
                    : items.slice(0, itemsPerPage)
                : items.slice(start, end),
        [items, itemsPerPage, paginate, start, end],
    );

    return useMemo(
        () => ({pageItems, page, totalPages, setPage}),
        [pageItems, page, totalPages, setPage],
    );
};

export default usePagination;
