import React, {forwardRef} from 'react';
import isEmpty from 'lodash/isEmpty';

import {useSearchesById} from 'hsi/hooks/useSearchesById';
import useCardData from 'hsi/hooks/useCardData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import CardTable from 'hsi/components/CardTable/v2';

import {useDataParser} from './useDataParser';
import {useTableParser} from './useTableParser';
import {useDrillInData} from './useDrillInData';

//Constants
const type = 'totalVolumeBySearch';

//The component
const TotalVolumeBySearch = forwardRef(({title, ...props}, ref) => {
    const {searchIds} = useMultipleSearchIds();
    const {searchesById} = useSearchesById();
    const drillInMentions = useDrillInMentions();
    const {drillInData} = useDrillInData({searchesById, type});

    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });

    const {volumeBySearch, metrics} = useDataParser({
        data,
        searchIds,
        type,
    });

    const {items, fields} = useTableParser({volumeBySearch, metrics, searchIds, searchesById});

    const defaultSort = 'volume';

    return (
        <CardLoadState
            title={<CardTitle title={title} type={type} hasData={!isEmpty(volumeBySearch)} />}
            error={error}
            hasData={!isEmpty(volumeBySearch)}
            loaded={loaded}
            loading={loading}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <CardTable
                defaultSort={defaultSort}
                fields={fields}
                items={items}
                type={type}
                onClickRow={(d) => drillInMentions(drillInData(d))}
            />
        </CardLoadState>
    );
});

TotalVolumeBySearch.displayName = 'TotalVolumeBySearch';

export default TotalVolumeBySearch;
