import React, {forwardRef} from 'react';

//Components
import TopMeasureBySearch from 'hsi/components/Cards/TopMeasureBySearch';
import NameCell from 'hsi/components/CardTable/v2/NameCell';

//Utils
import {drillInFilters} from 'hsi/utils/filters';

//Other
import tns from 'hsi/i18n/tns';

//Consts
const type = 'toptopicsBySearch';
const t = tns(`cards.${type}`);
const dataSelector = (state) => state.chart[type];
const drillInData = ({name: topicName}) => ({
    type,
    label: topicName,
    tracking: {
        name: 'cardDrilledIn',
        type,
        value: topicName,
    },
    filter: drillInFilters.topic(topicName),
});
const nameField = {
    label: t('nameFieldLabel'),
    format: (topicName) => <NameCell name={topicName} />,
};

//The component
const TopTopicsBySearch = forwardRef(function TopTopicsBySearch(props, ref) {
    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={dataSelector}
            drillInData={drillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopTopicsBySearch;
