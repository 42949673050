import find from 'lodash/find';

import {CCFlagsType} from 'hsi/types/cc-flags';
import {QueryContextType} from 'hsi/types/query';

import getConfig from 'hsi/config'; //Not a fan of this
import {CardType} from 'hsi/types/cards';

export default function getCardRules(
    type: CardType,
    queryContext: QueryContextType,
    flags: CCFlagsType,
) {
    const conditions = {queryContext, flags};

    const {
        searchResults: {cardRules},
    } = getConfig();

    const rules = find(cardRules, ({name}) => name === type);

    if (!rules) {
        return undefined;
    }

    const {getTitle, ...otherRules} = rules;

    return {
        ...otherRules,
        additionalCallRequires: !!rules?.additionalCallRequires
            ? rules.additionalCallRequires(conditions)
            : null,
        peaks: !!rules?.peaks ? rules.peaks(conditions) : null,
        requires: !!rules?.requires ? rules.requires(conditions) : null,
    };
}
