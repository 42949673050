import React, {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

//Components
import TopTenTableCard from 'hsi/components/Cards/TopTenTableCard/';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useGetLoadData from './useGetLoadData';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Utils
import isBCR from 'hsi/utils/isBCR';

//Other
import {T} from 'hsi/i18n';

//Constants
const type = 'toplanguages';

//The components
const TopLanguages = React.forwardRef(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopLanguagesCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();

    //Redux
    const dispatch = useDispatch();
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);

    const loadData = useGetLoadData(type);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    return (
        <TopTenTableCard
            config={topTenTableConfig}
            data={data}
            data-testid={type}
            error={error}
            loading={loading}
            loaded={loaded}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={!!data} />
            }
            type={type}
            loadData={loadData}
            onRowClick={({id, name}) => {
                const drillinFilter = {
                    language: {
                        activeModeIsInclude: true,
                        values: [{id, fullName: name}],
                    },
                };
                const drillinName = name;

                trackWithSearchData('cardDrilledIn', {
                    breakdown: 'language',
                    value: id,
                    type,
                });

                dispatch(mentionsDrillIn(drillinFilter, type, null, drillinName));
            }}
            ref={ref}
            {...props}
        />
    );
});

const topTenTableConfig = {
    authorTotalTooltip: T('language.authorTotalTooltip'),
    label: T('language.label'),
    sparklineTooltip: T('language.sparklineTooltip'),
    formatData: (data) => data,
    hasData: (data) => !!data && !isEmpty(data),
    showMentions: () => true,
    showSparkline: () => false,
    showUniqueAuthors: (data, isSavedSearch) =>
        isSavedSearch && data?.every(({authorTotal}) => !!authorTotal),
    showUniqueAuthorCTA: (_data, isSavedSearch) => !isSavedSearch && !isBCR(),
};

TopLanguages.displayName = 'TopLanguages';

TopLanguages.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
    }),
};

export default TopLanguages;
