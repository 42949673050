import {useLocation} from 'react-router-dom';

import useConfig from 'hsi/hooks/useConfig';

import {getProduct} from 'hsi/utils/trackingNames';

// Used to get the event tracking name for what page you are on in the app
// This should match the app page structure in the App container
const useTrackingLocation = () => {
    const config: any = useConfig();
    const {pathname} = useLocation();

    // As it's so simple, all of streams is considered one product
    if (config?.trackingProduct === 'streams') {
        return getProduct('streams');
    }

    if (!pathname) {
        return getProduct('unknown');
    }

    if (pathname === '/') {
        return getProduct('home page');
    } else if (pathname.startsWith('/search/results') || pathname.startsWith('/search/edit')) {
        return getProduct('dashboard');
    } else if (pathname === '/alerts') {
        return getProduct('alerts page');
    } else if (pathname === '/manage-tokens/auth-tokens') {
        return getProduct('auth tokens page');
    } else if (
        pathname === '/manage-tokens/targeted-data' ||
        pathname.startsWith('/data-management')
    ) {
        return getProduct('targeted data page');
    } else if (pathname === '/reports') {
        return getProduct('reports page');
    } else if (pathname === '/searches') {
        return getProduct('saved searches page');
    } else if (pathname.includes('/mentions-table')) {
        return getProduct('mentions table');
    } else {
        return getProduct('unknown');
    }
};

export default useTrackingLocation;
