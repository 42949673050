
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import useStyles from './styles';

type HashtagCellArgs = {
    value: string;
    index: any;
};

const HashtagCell = ({value, index}: HashtagCellArgs) => {
    const cl = useStyles();
    return (
        <div className={cl.container}>
            <div className={cl.index}>{index + 1}</div>
            <div className={cl.hashtagDetails}>
                <OverflowTooltip tooltip={value as any}>
                    <div className={cl.hashtagName}>{value}</div>
                </OverflowTooltip>
             </div>
        </div>
    );
};

export default HashtagCell;
