
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import Combobox, { useComboboxInputProps, useComboboxItemProps, useComboboxLabelProps, useComboboxListProps } from "hsi/components/Combobox"
import { ChangeEventHandler, PropsWithChildren, useState } from "react";

const useStyles = makeStyles(() => ({
    list: {
        display: 'block',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    option: {
        background: '#DDDDFF',

        '&[aria-selected="true"], &:hover': {
            cursor: 'pointer',
            fontWeight: 'bold',
        },
    },
    hide: {
        display: 'none',
    },
}))

const exampleItems = [
    'Hello',
    'World',
    'Fish',
    'Zoo',
    'Javascript for Dummies',
    'War and Peace',
    'Catcher in the Rye',
    'Of Mice and Men',
    'Homer\'s Odyssey',
    'Dune',
];

// const defaultProps = {
//     getFilteredItems:  (inputValue: string) => inputValue === '' 
//         ? exampleItems 
//         : exampleItems.filter((item) => item.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())),
//     itemToString: (item: string | null) => item || '',
//     itemToKey: (item: string) => item,
//     itemComponent: forwardRef<HTMLLIElement, ComboboxItemProps<string>>(({item, index, isSelected, isHighlighted, ...rest}, ref) => {
//         return <li {...rest} ref={ref}>{item}</li>
//     })
// };



export default function TestBed() {
    //const [value, setValue] = useState('');
    const [currentItems, setCurrentItems] = useState(exampleItems);//TODO filter?

    return <div>
        <Combobox
            openOnFocus
            //closeOnBlur
            clickTogglesOpen
            onInputValueChange={(inputValue) => {
                if(inputValue === '') {
                    setCurrentItems(exampleItems);
                }

                setCurrentItems(exampleItems.filter((item) => {
                    return item.toLocaleLowerCase().includes(inputValue)
                }));
            }}
        >
            <ComboboxLabel>Example label</ComboboxLabel>
            <ComboboxInput />
            <ComboboxList>
                {currentItems.map(
                    (item, index) => <ComboboxItem item={item} index={index} key={item} />
                )}
            </ComboboxList>
        </Combobox>

    </div>
}

function ComboboxInput({onChange}: {onChange?: ChangeEventHandler<HTMLInputElement>}) {
    return <input {...useComboboxInputProps({onChange})} />;
}

function ComboboxLabel({children}: PropsWithChildren) {
    return <label {...useComboboxLabelProps()}>{children}</label>
}

function ComboboxList({children}: PropsWithChildren) {
    const classes = useStyles();
    const {open, className, ...other} = useComboboxListProps({className: classes.list});

    return (<ul className={classNames(className, !open && classes.hide)} {...other}>
            {children}
        </ul>)
}

function ComboboxItem({item, index}: {item: string, index: number}) {
    const classes = useStyles();

    return <li {...useComboboxItemProps(item, index, {className: classes.option})}>{item}</li>
}