import React, {useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

//Components
import TopTenTableCard from 'hsi/components/Cards/TopTenTableCard/';
import CardTitle from 'hsi/components/Card/CardTitle';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useGetLoadData from './useGetLoadData';
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Other
import {T} from 'hsi/i18n';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';

//Constants
const type = 'topinterests';

//The components
const TopInterests = React.forwardRef(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopInterestsCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();

    const isSavedSearch = useIsSavedSearch();

    //Redux
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters.filters);
    const {data, loaded, loading, error} = useSelector((state) => state.chart[type]);

    const loadData = useGetLoadData(type);

    const notAvailablePerDataLimitation = useMemo(() => {
        if (
            !!filters.pageType &&
            Object.values(filters.pageType).some((value) => value === true) &&
            (filters.pageType.twitter === false || filters.pageType.twitter === undefined)
        ) {
            return true;
        }
        return false;
    }, [filters.pageType]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    return (
        <TopTenTableCard
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={!!data} />
            }
            config={topTenTableConfig()}
            data-testid={type}
            data={data}
            error={error}
            loadData={loadData}
            loaded={loaded}
            loading={loading}
            notAvailablePerDataLimitation={notAvailablePerDataLimitation}
            ref={ref}
            type={type}
            onRowClick={
                isSavedSearch
                    ? ({id}) => {
                          const drillinFilter = {interest: id};
                          const drillinName = id;

                          trackWithSearchData('cardDrilledIn', {
                              breakdown: 'interest',
                              value: id,
                              type,
                          });

                          dispatch(mentionsDrillIn(drillinFilter, type, null, drillinName));
                      }
                    : undefined
            }
        />
    );
});

const topTenTableConfig = () => ({
    label: T('interest.label'),
    authorTotalTooltip: T('interest.authorTotalTooltip'),
    sparklineTooltip: T('interest.sparklineTooltip'),
    formatData: (data) => data,
    hasData: (data) => !!data && !isEmpty(data),
    showMentions: () => true,
    showSparkline: (data, isSavedSearch) =>
        isSavedSearch && data?.every(({authorSeries}) => !isEmpty(authorSeries)),
    showUniqueAuthors: (data, isSavedSearch) =>
        isSavedSearch && data?.every(({authorTotal}) => !!authorTotal),
    showUniqueAuthorCTA: (_data, isSavedSearch) => !isSavedSearch,
});

TopInterests.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
    }),
};

TopInterests.displayName = 'TopInterests';

export default TopInterests;
