import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => {
    if (theme.v2)
        return {
            table: {
                width: 'auto',
            },
            mockRow: {
                display: 'flex',
                margin: '16px 0',

                '& > *': {
                    backgroundColor: theme.colors.uiGrayDark,
                    borderRadius: '4px',
                    height: '8px',
                },
            },
            mockRowPart1: {
                flex: '0 0 12px',
                marginRight: '13px',
            },
            mockRowPart2: {
                flex: '1 1 auto',
            },
            msg: {
                backgroundColor: theme.colors.uiGrayDark,
                fontSize: theme.typgrphy.bodySmall.fontSize,
                fontWeight: theme.typgrphy.bodySmall.fontWeight,
                padding: '12px',
                borderRadius: '4px',
                '& a': {
                    fontSize: theme.typgrphy.bodySmallStrong.fontSize,
                    fontWeight: theme.typgrphy.bodySmallStrong.fontWeight,
                    textDecoration: 'none',
                    color: theme.colors.hyperlink,
                },
            },
            cardTableVariant: {
                '& th': {
                    fontSize: `13px !important`,
                    height: '39px !important',
                },
            },
        };
    else
        return {
            table: {
                width: 'auto',
            },
            mockRow: {
                display: 'flex',
                margin: '16px 0',
                '& > *': {
                    backgroundColor: theme.colors.lightGrey60,
                    borderRadius: '4px',
                    height: '8px',
                },
            },
            mockRowPart1: {
                flex: '0 0 12px',
                marginRight: '13px',
            },
            mockRowPart2: {
                flex: '1 1 auto',
            },
            msg: {
                backgroundColor: theme.colors.lightGrey60,
                fontSize: '12px',
                padding: '12px',
                fontWeight: theme.font.semiBold,
                borderRadius: '4px',
                '& a': {
                    textDecoration: 'none',
                },
            },
            cardTableVariant: {
                '& th': {
                    fontSize: `13px !important`,
                },
            },
        };
});
