import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

// Hooks
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

const useStyles = makeStyles((theme) => ({
    drillIn: {
        ...theme.mixin.linkButton,
        ...theme.mixin.truncate,
        '@media print': {
            overflow: 'visible',
            textAlign: 'left',
            lineHeight: '20px',
            whiteSpace: 'normal',
            textOverflow: 'unset',
            wordBreak: 'break-all',
        },
        '.printMedia &': {
            overflow: 'visible',
            textAlign: 'left',
            lineHeight: '20px',
            whiteSpace: 'normal',
            textOverflow: 'unset',
            wordBreak: 'break-all',
        },
    },
}));

const DrillInMentionsButton = ({drillInData, className, children}) => {
    const cl = useStyles();
    const drillInMentions = useDrillInMentions();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    return isCardInteractivityDisabled ? (
        <span className={cn(cl.drillIn, className)}>{children}</span>
    ) : (
        <button className={cn(cl.drillIn, className)} onClick={() => drillInMentions(drillInData)}>
            {children}
        </button>
    );
};

export default DrillInMentionsButton;
