import {
    FiltersState,
    IncludeAndExcludeFilterFieldState,
    IncludeAndExcludeFilterState,
    IncludeOrExcludeFilterState,
    RangeFilterState,
    CheckboxesFilterState,
    FilterState,
    TextfieldFilterState,
    AllFilteringState,
    DateRange,
    FilterTrackingData,
} from 'hsi/types/filters';
import isEmpty from 'lodash/isEmpty';
import {includeOrExcludeToIncludeAndExclude} from './filters/normalisePersistedFilters';

export {default as filterStateToAPIFormat} from './filters/filterStateToAPIFormat';
export {default as apiFormatToFilterState} from './filters/apiFormatToFilterState';
export {default as normalisePersistedFilters} from './filters/normalisePersistedFilters';
export {default as drillInFilters} from './filters/drillInFilters';

///////////////////
// Merge filters //
///////////////////

function mergeCheckboxes(_base: FilterState, _additional: FilterState): FilterState {
    const base: CheckboxesFilterState = _base as CheckboxesFilterState;
    const additional: CheckboxesFilterState = _additional as CheckboxesFilterState;
    const output: CheckboxesFilterState = {};

    const ifTrueAddToOutput = ([key, value]: [string, boolean]): void => {
        if (value) {
            output[key] = true;
        }
    };

    Object.entries(base).forEach(ifTrueAddToOutput);
    Object.entries(additional).forEach(ifTrueAddToOutput);

    return output;
}

//Doesn't (can't?) merge different include/exclude modes - additional will replace if modes are different
// should 'merge' really mean 'replace' here?
function mergeIncludeOrExclude(_base: FilterState, _additional: FilterState): FilterState {
    const base: IncludeOrExcludeFilterState = _base as IncludeOrExcludeFilterState;
    const additional: IncludeOrExcludeFilterState = _additional as IncludeOrExcludeFilterState;

    if (base.activeModeIsInclude !== additional.activeModeIsInclude) {
        return additional; //if modes are different, replace with additional
    }

    //Otherwise, merge
    const values = [...base.values];

    additional.values.forEach((value) => {
        if (!values.find((val) => val.id === value.id)) {
            values.push(value);
        }
    });

    return {
        ...base,
        values,
    };
}

function mergeIncludeAndExclude<T = any>(
    allFieldNames: string[],
    base: IncludeAndExcludeFilterState<T>,
    additional: IncludeAndExcludeFilterState<T>,
): IncludeAndExcludeFilterState<T> {
    return {
        ...base,
        fields: allFieldNames.reduce<{[key: string]: IncludeAndExcludeFilterFieldState<T>}>(
            (output, fieldName) => {
                const baseField = base.fields[fieldName];
                const additionalField = additional.fields[fieldName];

                if (baseField || additionalField) {
                    output[fieldName] = {
                        include: [...(additionalField?.include || baseField.include)],
                        exclude: [...(additionalField?.exclude || baseField.exclude)],
                    };
                }

                return output;
            },
            {},
        ),
    };
}

function getMergeIncludeAndOrExclude(field: string) {
    return (base: FilterState, additional: FilterState) => {
        if ((base as IncludeOrExcludeFilterState).values) {
            return mergeIncludeOrExclude(base, additional);
        }

        return mergeIncludeAndExclude(
            [field],
            base as IncludeAndExcludeFilterState,
            includeOrExcludeToIncludeAndExclude(additional as IncludeOrExcludeFilterState, field),
        );
    };
}

function additionalOrBase(base: FilterState, additional: FilterState): FilterState {
    return additional || base;
}

//Does this need to be moved into the config? Probably!
const mergeFiltersFuncs: {
    [key: string]: (base: FilterState, additional: FilterState) => FilterState;
} = {
    keyword: (base: FilterState, additional: FilterState): FilterState => {
        const s1 = (base as TextfieldFilterState).trim();
        const s2 = (additional as TextfieldFilterState).trim();

        if (s1 && s2) {
            return `(${s1}) AND (${s2})`;
        }

        return s1 || s2 || '';
    },
    pageType: mergeCheckboxes,
    sentiment: mergeCheckboxes,
    classifications: mergeCheckboxes,
    gender: mergeCheckboxes,
    //quick search
    ownedContent: (base: FilterState, additional: FilterState): FilterState => {
        return mergeIncludeAndExclude(
            ['author', 'domain'],
            base as IncludeAndExcludeFilterState,
            additional as IncludeAndExcludeFilterState,
        );
    },
    //saved search
    site: (base: FilterState, additional: FilterState): FilterState => {
        return mergeIncludeAndExclude<string>(
            ['domain'],
            base as IncludeAndExcludeFilterState<string>,
            additional as IncludeAndExcludeFilterState<string>,
        );
    },
    author: (base: FilterState, additional: FilterState): FilterState => {
        return mergeIncludeAndExclude<string>(
            ['author'],
            base as IncludeAndExcludeFilterState<string>,
            additional as IncludeAndExcludeFilterState<string>,
        );
    },
    tag: (_base: FilterState, _additional: FilterState): FilterState => {
        const base = _base as IncludeAndExcludeFilterState;
        const additional = _additional as IncludeAndExcludeFilterState;

        if (additional?.fields?.tag) {
            return additional;
        }

        return base;
    },
    language: getMergeIncludeAndOrExclude('language'),
    location: getMergeIncludeAndOrExclude('location'),
    accountType: mergeCheckboxes,
    twitterVerified: mergeCheckboxes,
    followersRange: (_base: FilterState, _additional: FilterState): FilterState => {
        const base: RangeFilterState = _base as RangeFilterState;
        const additional: RangeFilterState = _additional as RangeFilterState;

        return {
            min: additional.min ?? base.min,
            max: additional.max ?? base.max,
        };
    },
    threadEntryType: mergeCheckboxes,

    'topicWheel-name': additionalOrBase,
    'topicWheel-mentions': additionalOrBase,
    insightsUrl: additionalOrBase,
    insightsHashtag: additionalOrBase,
    dayOfWeek: additionalOrBase,
    hourOfDay: additionalOrBase,
    queryId: additionalOrBase,
    interest: additionalOrBase,
};

export function mergeFilters(
    baseFilters: FiltersState,
    additionalFilters: FiltersState,
): FiltersState {
    const output = {...baseFilters};

    for (let [filter, value] of Object.entries(additionalFilters)) {
        if (filter in baseFilters) {
            if (mergeFiltersFuncs[filter]) {
                output[filter] = mergeFiltersFuncs[filter](baseFilters[filter], value);
            } else {
                //throw new Error('Unknown filter to merge: '+filter);
                console.log('Unknown filter to merge: ' + filter);
            }
        } else {
            output[filter] = value;
        }
    }

    return output;
}

export function addDrillInToFiltersState(
    filters: AllFilteringState,
    drillIn?: FiltersState,
    drillInDates?: DateRange,
): AllFilteringState {
    const hasDrillIn = !isEmpty(drillIn);
    const hasDrillInDates = !isEmpty(drillInDates);

    return hasDrillIn || hasDrillInDates
        ? {
              ...filters,
              filters: hasDrillIn
                  ? mergeFilters(filters.filters, drillIn as FiltersState)
                  : filters.filters,
              dateRange: hasDrillInDates
                  ? {...(drillInDates as DateRange), timezone: filters?.dateRange?.timezone}
                  : filters.dateRange,
          }
        : filters;
}

//Tracking stuff

type GetFilterTrackingData =
    | ((filterName: string, value: any, checked: boolean) => FilterTrackingData | undefined)
    | ((
          filterName: string,
          fieldName: string,
          mode: boolean,
          value: any,
      ) => FilterTrackingData | undefined)
    | ((filterName: string, mode: boolean, value: any) => FilterTrackingData | undefined);

const handleFilterTypes: {[key: string]: GetFilterTrackingData} = {
    checkboxes: (filterName: string, value: any, checked: boolean) => {
        if (checked) {
            return {
                type: filterName,
                value,
            };
        }
    },
    includeAndExclude: (_filterName: string, fieldName: string, mode: boolean, value: any) => ({
        operator: mode ? 'include' : 'exclude',
        type: fieldName,
        value,
    }),
    includeOrExclude: (filterName: string, mode: boolean, value: any) => ({
        operator: mode ? 'include' : 'exclude',
        type: filterName,
        value,
    }),
    multiValueTextfield: (filterName: string, value: any) => ({
        type: filterName,
        value,
    }),
    range: (filterName: string, mode: string, value: any) => ({
        operator: mode,
        type: filterName,
        value,
    }),
    textfield: (filterName: string, value: any) => ({
        type: filterName,
        value,
    }),
    select: (filterName: string, value: any) => ({
        type: filterName,
        value,
    }),
    // category: (filterName: string, mode: string, value: any) => {
    //     //TODO how do we track category changes? apparently we didn't previously
    // },
};

export function trackFilters(
    filterName: string,
    type: string,
    ...args: [any, any, any]
): FilterTrackingData | undefined {
    if (handleFilterTypes?.[type]) {
        return handleFilterTypes[type](filterName, ...args);
    }
}
