import React, {useMemo, useCallback} from 'react';
import cn from 'classnames';
import {useSelector, useDispatch} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import ChoroplethMap from 'hsi/components/ChoroplethMap';
import CardTable from 'hsi/components/CardTable/v2';
import NameCell from 'hsi/components/CardTable/v2/NameCell';
import {NumberWithHorizontalBarPercent} from 'hsi/components/HorizontalBar';
import UniqAuthorsCTA, {useSplitColumnsStyles} from 'hsi/components/UniqAuthorsCTA';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useEventTrack from 'hsi/hooks/useEventTrack';
import useGetLoadData from '../useGetLoadData';
import useMultipleSearchIds from 'hsi/hooks/useMultipleSearchIds';
import useQueryContext from 'hsi/hooks/useQueryContext';
import {useSearchesById} from 'hsi/hooks/useSearchesById';

//Actions
import {mentionsDrillIn} from 'hsi/slices/mentions';

//Utils
import isBCR from 'hsi/utils/isBCR';
import {formatBigInt, formatTableSortNumber} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

const type = 'geography';
const COUNTRIES_MAX_AMOUNT = 50;

//The components
export default React.forwardRef(function Geography({title, ...props}, ref) {
    const classes = useStyles();
    const {
        links: {dashboardInfoGeographyCTA: popupCTA},
    } = useConfig();
    const {trackWithSearchData} = useEventTrack();
    const {columns} = useSplitColumnsStyles();
    const queryContext = useQueryContext();
    const {isMultipleSearch, isSavedSearch} = queryContext;
    const {searchIds} = useMultipleSearchIds();
    const {searchesById} = useSearchesById();
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    //Redux
    const dispatch = useDispatch();
    const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);
    const {data, error, loaded, loading} = useSelector((state) => state.chart[type]);
    const userIsViewOnly = useSelector((state) => state.user.isViewOnly);
    const hasData = useMemo(() => !!data?.some(({total}) => total > 0), [data]);

    //Callbacks
    const onItemClick = useCallback(
        ({id, name}) => {
            trackWithSearchData('cardDrilledIn', {
                type,
                value: name,
            });
            dispatch(
                mentionsDrillIn(
                    {
                        location: {
                            activeModeIsInclude: true,
                            values: [{id, name, fullName: name}],
                        },
                    },
                    type,
                    null,
                    name,
                ),
            );
        },
        [dispatch, trackWithSearchData],
    );

    //Calculates values
    const loadData = useGetLoadData(type);

    const showAuthorsCTA = useMemo(
        () => !isBCR() && !userIsViewOnly && !isMultipleSearch && !isSavedSearch,
        [isMultipleSearch, isSavedSearch, userIsViewOnly],
    );

    const content = useMemo(() => {
        const baseFields = [
            {
                id: 'name',
                label: T(`cards.${type}.countries`),
                width: '2fr',
                format: (value) => <NameCell name={value} />,
            },
            {
                id: 'total',
                label: T(isMultipleSearch ? 'total' : 'mentions'),
                format: (value) => formatBigInt(value),
                sortFormat: formatTableSortNumber,
            },
        ];

        const authorField = {
            id: 'authorTotal',
            label: T('uniqAuthors'),
            format: (value, {authorTotal, authorPercent}) => (
                <NumberWithHorizontalBarPercent
                    value={authorTotal}
                    percent={authorPercent}
                    valueTooltip={T(`cards.${type}.authorTotalTooltip`)}
                />
            ),
            sortFormat: formatTableSortNumber,
            headerOverflowTooltip: true,
        };

        const searchIdFields = searchIds.map((searchId) => ({
            id: searchId,
            label: searchesById[searchId]?.name,
            format: (value) => formatBigInt(value),
            sortFormat: formatTableSortNumber,
            headerOverflowTooltip: true,
        }));

        const fields = () => {
            if (isMultipleSearch) {
                return baseFields.concat(searchIdFields);
            }

            if (!isMultipleSearch && isSavedSearch) {
                return baseFields.concat(authorField);
            }

            return baseFields;
        };

        const limitedData = data?.slice(0, COUNTRIES_MAX_AMOUNT) || [];

        return (
            <div className={classes.root}>
                <div
                    className={classes.chart}
                    inert={isCardInteractivityDisabled ? 'inert' : undefined}
                >
                    <ChoroplethMap data={limitedData} onItemClick={onItemClick} />
                </div>
                <div className={cn(classes.content, columns)}>
                    <div>
                        <CardTable
                            defaultSort="total"
                            fields={fields()}
                            items={limitedData}
                            maxRows={10}
                            onClickRow={onItemClick}
                            type={type}
                            paginate={true}
                        />
                    </div>
                    {showAuthorsCTA && <UniqAuthorsCTA type={type} variant="cardTable" />}
                </div>
            </div>
        );
    }, [
        classes,
        columns,
        data,
        isMultipleSearch,
        isSavedSearch,
        onItemClick,
        searchIds,
        searchesById,
        showAuthorsCTA,
        isCardInteractivityDisabled,
    ]);

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    //Render
    return (
        <CardLoadState
            {...props}
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={hasData} />
            }
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            selected={isSelectedCard}
            loadData={loadData}
            type={type}
            ref={ref}
            data-testid={type}
        >
            {content}
        </CardLoadState>
    );
});
