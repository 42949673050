import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    chart: {
        marginTop: '15px',
        height: '236px',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column nowrap',

        '& > *': {
            flex: '1 1 auto',
            overflow: 'hidden',
        },
    },
    content: {
        padding: '32px 0 0',
        margin: '0',
        overflow: 'hidden',
    },
    msTableCol: {},
}));
