//TODO only open if there are items?
//TODO open on down key?


import {useMemo, useState, useCallback, useRef} from 'react';
import useUniqueId from 'hsi/hooks/useUniqueId';
import { CombobboxInputValueChangeReason, ComboboxContextType, ComboboxProps, Option } from './types';
import ComboboxContext from './context';

export {default as useComboboxInputProps} from './hooks/useComboboxInputProps';
export {default as useComboboxItemProps} from './hooks/useComboboxItemProps';
export {default as useComboboxItemState} from './hooks/useComboboxItemState';
export {default as useComboboxLabelProps} from './hooks/useComboboxLabelProps';
export {default as useComboboxListProps} from './hooks/useComboboxListProps';


//The component
export default function ComboBox<T>({
    id: _id, 
    labelId: _labelId, 
    listBoxId: _listBoxId,
    inputValue: _inputValue,
    onInputValueChange: _onInputValueChange,

    openOnFocus,
    closeOnBlur,
    clickTogglesOpen,

    children,
}: ComboboxProps) {
    const id = useUniqueId(_id, 'combobox');
    const labelId = _labelId || `${id}.label`;
    const listBoxId = _listBoxId || `${id}.list`;

    //Internal state
    const [internalInputValue, setInternalInputValue] = useState('');
    const [open, setOpen] = useState(false);
    const optionsRef = useRef<Option<T>[]>([]);
    const [selectedItem, setSelectedItem] = useState<T|null>(null);

    //Calculated values
    const inputValue = _inputValue ?? internalInputValue;

    //Callbacks
    const onInputValueChange = useCallback((newValue: string, reason: CombobboxInputValueChangeReason) => {
        setInternalInputValue(newValue);
        _onInputValueChange?.(newValue, reason);
    }, [_onInputValueChange]);

    const registerOption = useCallback((item: T, index: number, id: string) => {
        optionsRef.current[index] = {item, index, id};
    }, []);

    const clearOption = useCallback((id: string) => {
        const index = optionsRef.current.findIndex((option) => option?.id === id);

        if(index !== -1) {
            delete optionsRef.current[index];
        }
    }, []);

    const comboboxContextValue = useMemo<ComboboxContextType<T>>(() => {
        return {
            id,
            labelId,
            listBoxId,
            open,
            setOpen,
            inputValue, 
            onInputValueChange,
            selectedItem,
            setSelectedItem,

            openOnFocus: !!openOnFocus,
            closeOnBlur: !!closeOnBlur,
            clickTogglesOpen: !!clickTogglesOpen,

            registerOption,
            clearOption,
        };
    }, [id, labelId, listBoxId, open, inputValue, onInputValueChange, selectedItem, openOnFocus, closeOnBlur, clickTogglesOpen, registerOption, clearOption]);
//console.log(comboboxContextValue);
    return <ComboboxContext.Provider value={comboboxContextValue as ComboboxContextType}>
        {children}
    </ComboboxContext.Provider>

    // return (
    //     <div>
    //         <div className="">
    //             <label className="" {...getLabelProps()}>
    //                 Choose your favorite book:
    //             </label>
    //             <div className="">
    //                 <input
    //                     placeholder="Best book ever"
    //                     className=""
    //                     {...getInputProps()}
    //                 />
    //                 <button
    //                     aria-label="toggle menu"
    //                     className=""
    //                     type="button"
    //                     {...getToggleButtonProps()}
    //                 >
    //                     {isOpen ? <>&#8593;</> : <>&#8595;</>}
    //                 </button>
    //             </div>
    //         </div>
    //         <ul
    //             className={` ${
    //                 !(isOpen && items.length) && 'hidden'
    //             }`}
    //             {...getMenuProps()}
    //         >
    //             {isOpen &&
    //                 items.map((item, index) => {
    //                     const isSelected = selectedItem === item;
    //                     const isHighlighted = highlightedIndex === index;

    //                     const itemProps = getItemProps({item, index, isSelected, isHighlighted})

    //                     return <ItemComponent
    //                         key={itemToKey(item)}
    //                         item={item}
    //                         index={index}
    //                         {...itemProps}
    //                     />
    //             })}
    //         </ul>
    //   </div>
    // );
}

// function ComboBoxExample() {
//     const books = [
//       {id: 'book-1', author: 'Harper Lee', title: 'To Kill a Mockingbird'},
//       {id: 'book-2', author: 'Lev Tolstoy', title: 'War and Peace'},
//       {id: 'book-3', author: 'Fyodor Dostoyevsy', title: 'The Idiot'},
//       {id: 'book-4', author: 'Oscar Wilde', title: 'A Picture of Dorian Gray'},
//       {id: 'book-5', author: 'George Orwell', title: '1984'},
//       {id: 'book-6', author: 'Jane Austen', title: 'Pride and Prejudice'},
//       {id: 'book-7', author: 'Marcus Aurelius', title: 'Meditations'},
//       {
//         id: 'book-8',
//         author: 'Fyodor Dostoevsky',
//         title: 'The Brothers Karamazov',
//       },
//       {id: 'book-9', author: 'Lev Tolstoy', title: 'Anna Karenina'},
//       {id: 'book-10', author: 'Fyodor Dostoevsky', title: 'Crime and Punishment'},
//     ]
//     function getBooksFilter(inputValue) {
//       const lowerCasedInputValue = inputValue.toLowerCase()
  
//       return function booksFilter(book) {
//         return (
//           !inputValue ||
//           book.title.toLowerCase().includes(lowerCasedInputValue) ||
//           book.author.toLowerCase().includes(lowerCasedInputValue)
//         )
//       }
//     }
  
    
//     return <ComboBox />
//   }