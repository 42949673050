import React from 'react';
import cn from 'classnames';

import useConfig from 'hsi/hooks/useConfig';
import Tooltip from 'hsi/components/Tooltip';
import HorizontalBar from './HorizontalBar';
import {formatBigInt} from 'hsi/utils/formatNumber';
import useStyles from './styles';

export default React.forwardRef(function NumberWithHorizontalBarPercent(
    {value, valueTooltip, percent, className, extraClasses},
    ref,
) {
    const cl = useStyles();
    const {themeColors} = useConfig();
    const _number = formatBigInt(value);
    const number = valueTooltip ? (
        <Tooltip tooltip={valueTooltip}>
            <span>{_number}</span>
        </Tooltip>
    ) : (
        _number
    );
    return (
        <div ref={ref} className={cn(cl.numberWithBarRoot, className)}>
            <div className={cn(cl.numberContainer, extraClasses?.numberContainer)}>{number}</div>
            <div className={cn(cl.barContainer, extraClasses?.barContainer)}>
                <HorizontalBar value={percent} color={themeColors.sparkline} />
            </div>
        </div>
    );
});
