import {useMemo} from 'react';

import AuthorCell from './AuthorCell';
import Tooltip from 'hsi/components/Tooltip';

import {formatBigInt, formatTableSortNumber, formatPercent} from 'hsi/utils/formatNumber';
import tns from 'hsi/i18n/tns';
const t = tns('cards.topauthors');

const sum = (data, key) => data?.reduce((sum, d) => sum + (d?.[key] || 0), 0) || 0;
const useSum = (data, key) => useMemo(() => sum(data, key), [data, key]);

const useTableFields = ({data, type}) => {
    const followersTotal = useSum(data, 'twitterFollowers');
    const reachTotal = useSum(data, 'reachEstimate');

    return [
        {
            id: 'name',
            label: t('tableFields.name'),
            format: (value, datum, index) => <AuthorCell datum={datum} type={type} index={index} />,
            width: '2fr',
        },

        {
            id: 'value',
            label: t('tableFields.mentions'),
            sortFormat: formatTableSortNumber,
            format: (value, {percentageOfTotal}) => (
                <Tooltip
                    tooltip={t('percentOfMentions', {percent: formatPercent(percentageOfTotal)})}
                >
                    <div style={{padding: 8}}>{formatBigInt(value)}</div>
                </Tooltip>
            ),
        },

        followersTotal > 0 && {
            id: 'twitterFollowers',
            label: t('tableFields.followers'),
            sortFormat: formatTableSortNumber,
            format: (value) => formatBigInt(value),
        },

        reachTotal > 0 && {
            id: 'reachEstimate',
            label: t('tableFields.reach'),
            sortFormat: formatTableSortNumber,
            format: (value) => formatBigInt(value),
        },
    ].filter((d) => !!d);
};

export default useTableFields;
