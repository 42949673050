//TODO Why is this table made out of DIVs? This is simply wrong, and probably an accessibility issue

import React, {ReactNode} from 'react';
import cn from 'classnames';

// Components
import IconRouter from 'hsi/components/IconRouter';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';
import {CardTableField} from '..';

// Hooks
import {getArrowDir, SortDirectionType, SortedItem} from '../useSort';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

import useStyles, {TableStyleArgs} from './styles';

type HeaderArgs<T> = {
    fields: CardTableField<T>[];
    sortKey: keyof T;
    sortDir: SortDirectionType;
    onSort: (fieldId: keyof T) => void;
    cl: ReturnType<typeof useStyles>;
};

function Header<T>({fields, sortKey, sortDir, onSort, cl}: HeaderArgs<T>) {
    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();
    return (
        <div className={cl.header}>
            {fields.map(({id, label, disableSort}) => (
                <div
                    key={id as string}
                    className={cn(cl.headerCell, disableSort && cl.disabledSort)}
                    onClick={disableSort ? undefined : () => onSort(id)}
                >
                    <div
                        key={id as string}
                        data-testid={'card-table-header-cell--' + (id as string)}
                        className={cn(cl.headerCellContent, {
                            [cl.sorting]: !disableSort && sortKey === id,
                        })}
                    >
                        <OverflowTooltip active={!isCardInteractivityDisabled} tooltip={label as any}>
                            <div className={cl.headerLabel}>{label}</div>
                        </OverflowTooltip>
                        <div
                            className={cn(cl.arrowIcon, {
                                [cl.sorting]: !disableSort && sortKey === id,
                            })}
                        >
                            <IconRouter name={`arrow-drop-${getArrowDir(sortDir)}`} />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

type BodyArgs<T> = {
    items: (T & SortedItem)[];
    fields: CardTableField<T>[];
    sortKey: keyof T;
    onClickRow?: (item: T) => void;
    cl: ReturnType<typeof useStyles>;
};

function Body<T>({items, fields, sortKey, onClickRow, cl}: BodyArgs<T>) {
    return (
        <>
            {items.map((item) => (
                <div
                    key={item.__sortindex__}
                    className={cl.row}
                    data-testid={'card-table-row'}
                    onClick={() => onClickRow?.(item)}
                >
                    {fields.map(({id, format}) => {
                        return (
                            <div
                                key={id as string}
                                className={cn(cl.cell, {
                                    sorting: sortKey === id,
                                })}
                                data-testid={'card-table-cell'}
                            >
                                <div className={cl.cellContent}>
                                    {format?.(item[id], item, item.__sortindex__) ||
                                        (item[id] as ReactNode)}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </>
    );
}

type TableArgs<T> = {
    items: (T & SortedItem)[];
    fields: CardTableField<T>[];
    onClickRow?: (item: T) => void;
    sortKey: keyof T;
    sortDir: SortDirectionType;
    onSort: (fieldId: keyof T) => void;
    styleProps?: Omit<TableStyleArgs, 'fields' | 'items'>;
};

function Table<T>({items, fields, onClickRow, sortKey, sortDir, onSort, styleProps}: TableArgs<T>) {
    const cl = useStyles({fields, items, ...styleProps});

    return (
        <div className={cl.table} data-testid={'card-table'}>
            <Header fields={fields} sortKey={sortKey} sortDir={sortDir} onSort={onSort} cl={cl} />
            <Body items={items} fields={fields} sortKey={sortKey} onClickRow={onClickRow} cl={cl} />
        </div>
    );
}

export default Table;
