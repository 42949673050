import {useCallback, useState, useMemo} from 'react';
import PropTypes from 'prop-types';

import Slider, { SliderProps } from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//Components
import Button from 'hsi/components/Button';
import IconRouter from 'hsi/components/IconRouter';
import Popover from 'hsi/components/Popover';
import {MenuWrapper, CheckableMenuItem} from 'hsi/components/SelectableMenuItem';

//Actions
import {reloadWordCloud} from 'hsi/actions/resultsActions';

// Hooks
import useEventTrack from 'hsi/hooks/useEventTrack';
import useFlags from 'hsi/hooks/useFlags';
import {useActiveQueryPageTypes} from 'hsi/hooks/usePageTypes';
import { useAppDispatch } from 'hsi/hooks/useRedux';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Constants
import {WORD_CLOUD_TYPE_MAP, WORD_CLOUD_TYPES, LOAD_DATA_TYPE_LIMITS} from 'hsi/constants/config';


//Utils

//TODO replace with a11y alternative
const MenuContent = ({classes, dispatchAction, menuItems, onCancel, types}: any) => {
    //Initial menu selections are based on the provided types
    const defaultData = useMemo(
        () =>
            menuItems.map((item: any) =>
                types.includes(item.id) ? {...item, state: true} : {...item, state: false},
            ),
        [menuItems, types],
    );

    const [data, setData] = useState(defaultData);

    const onApply = useCallback(() => {
        //Data is saved as an array of the selected ids === types
        const options = data.filter((item: any) => item.state === true).map((item:any) => item.id);
        dispatchAction(options, 'types');
        onCancel();
    }, [data, dispatchAction, onCancel]);

    const onChange = (value: any, updatedItem: any) => {
        setData((data: any) =>
            data.map((item: any) => (item.id === updatedItem.id ? {...item, state: value} : item)),
        );
    };

    return (
        <div className={classes.menuContent}>
            <MenuWrapper>
                {data.map((item: any) => (
                    <CheckableMenuItem
                        key={item.id}
                        checked={item.state}
                        onChange={(value) => onChange(value, item)}
                    >
                        {item.label}
                    </CheckableMenuItem>
                ))}
            </MenuWrapper>
            <div className={classes.selectMenuActions}>
                <Button priority="primary" onClick={onApply}>
                    {T('apply')}
                </Button>
                <Button priority="secondary" onClick={onCancel}>
                    {T('cancel')}
                </Button>
            </div>
        </div>
    );
};

MenuContent.propTypes = {
    classes: PropTypes.object.isRequired,
    dispatchAction: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
    onCancel: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
};


//TODO type this properly and refactor to use a11y components (Select specifically)
type ControlProps = {
    type: any, 
    selectedParams: any, 
    onParamChange: any, 
    options: Record<string, string[]>;
};

function Control ({type, selectedParams, onParamChange, options}: ControlProps) {

    return <div className="control">
        <span className="label" id={type}>{/*TODO make the *label* actually be a label */}
            {T('configSideDrawer.wordCloud.' + type)}
        </span>
        <Select
            labelId={type}
            variant="outlined"
            value={selectedParams[type]}
            onChange={(e) => onParamChange(e.target.value, type)}
        >
            {options[type].map((selectItem: string) => (
                <MenuItem key={type + selectItem} value={selectItem}>
                    {T('configSideDrawer.wordCloud.selectItems.' + type + '.' + selectItem)}
                </MenuItem>
            ))}
        </Select>
        <IconRouter name="chevron-down" className="select-icon" />
    </div>
}

//TODO replace this with a11y alternative
const PopMenuCtrl = ({classes, dispatchAction, labelsReference, menuItems, selectedParams}: any) => {
    const {types} = selectedParams;

    const typesLabel = useMemo(
        () =>
            types.length === 0
                ? T('configSideDrawer.emptySelection')
                : types.length > 2
                ? `${labelsReference[types[0]]}, ${labelsReference[types[1]]}, +${
                      types.length - 2
                  } more`
                : types.map((t: any) => labelsReference[t]).join(', '),
        [labelsReference, types],
    );

    return (
        <div className="control">
            <span className="label">{T('configSideDrawer.wordCloud.show')}</span>
            <Popover
                portal
                content={({close}) => (
                    <MenuContent
                        {...{classes, dispatchAction, menuItems, onCancel: close, types}}
                    />
                )}
                flip={false}
            >
                <button className="config-menu">
                    <span className="label">{typesLabel}</span>
                    <IconRouter name="chevron-down" />
                </button>
            </Popover>
        </div>
    );
};

export type WordCloudControlsProps = {
    selectedParams: any;//TODO
};


//The component
const WordCloudControls = ({selectedParams}: WordCloudControlsProps) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const {trackWithSearchData} = useEventTrack();
    const flags = useFlags();
    const pageTypes = useActiveQueryPageTypes();

    const [cloudSize, setCloudSize] = useState(selectedParams.size);

    const options = {
        color: ['topicType', 'gender', 'sentiment', 'volume', 'trending', 'random', 'none'],
        orderBy: ['trending', 'volume'],
    };

    const onChangeSize: SliderProps['onChange'] = (e, value) => {
        setCloudSize(value);
    };

    const onChange = useCallback(
        (value: any, metric: any) => {
            const updatedValues = Object.assign({}, selectedParams, {[metric]: value});
            trackWithSearchData('cardCustomized', {
                metric,
                type: 'wordCloud',
                value,
            });
            dispatch(reloadWordCloud(updatedValues, metric, flags, pageTypes));
        },
        [dispatch, flags, pageTypes, trackWithSearchData, selectedParams],
    );

    const onParamChange = useCallback(
        (value: any, type: any) => {
            return value === selectedParams[type] ? false : onChange(value, type);
        },
        [onChange, selectedParams],
    );

    return (
        <>
            <PopMenuCtrl
                classes={classes}
                dispatchAction={onChange}
                labelsReference={WORD_CLOUD_TYPE_MAP}
                menuItems={WORD_CLOUD_TYPES}
                selectedParams={selectedParams}
            />
            <Control type="orderBy" selectedParams={selectedParams} onParamChange={onParamChange} options={options} />
            <Control type="color" selectedParams={selectedParams} onParamChange={onParamChange} options={options} />
            <div>
                <label id="wordCloudConfigSizeLbl">{T('configSideDrawer.wordCloud.cloudSize')}</label>
                <Slider
                    aria-labelledby={'wordCloudConfigSizeLbl'}
                    max={LOAD_DATA_TYPE_LIMITS.wordCloud}
                    min={1}
                    onChange={onChangeSize}
                    onChangeCommitted={(e, value) => onParamChange(value, 'size')}
                    value={cloudSize}
                    valueLabelDisplay="auto"
                />
            </div>
        </>
    );
};

WordCloudControls.propTypes = {
    selectedParams: PropTypes.object.isRequired,
};

export default WordCloudControls;
