import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {LineChart, Line} from 'recharts';
import {useTheme} from '@mui/material/styles';

//Other
import useStyles from './styles';

//The component
const Sparkline = forwardRef(function Sparkline(
    {series, color, dataKey, width, height, animate, withTrend, className, ...rest},
    ref,
) {
    const {classes, cx} = useStyles();
    const {colors} = useTheme();

    if (!width || !height) return <div />;

    color = color ?? colors.keyline;

    let trend;

    if (withTrend && series.length > 1) {
        const lastButOne = series[series.length - 2][dataKey];
        const lastOne = series[series.length - 1][dataKey];
        if (lastOne < lastButOne) {
            trend = 'down';
        } else if (lastOne > lastButOne) {
            trend = 'up';
        } else {
            trend = 'same';
        }
    } else {
        withTrend = false;
    }
    return (
        <div
            className={cx(classes.sparklineWrapper, className)}
            data-testid="sparkline"
            {...rest}
            ref={ref}
        >
            <LineChart
                {...{width, height}}
                className={classes.sparkline}
                data={series}
                margin={{top: 1, right: withTrend ? 16 : 0, bottom: 1, left: 0}}
            >
                <Line
                    className={classes.line}
                    dataKey={dataKey}
                    stroke={color}
                    dot={false}
                    isAnimationActive={animate}
                    strokeWidth={2}
                />
            </LineChart>
            {withTrend && (
                <div style={{borderColor: color}} className={cx(classes.trend, classes[trend])} />
            )}
        </div>
    );
});

export default Sparkline;

Sparkline.propTypes = {
    animate: PropTypes.bool,
    color: PropTypes.string,
    dataKey: PropTypes.string,
    heigth: PropTypes.number,
    series: PropTypes.array,
    width: PropTypes.number,
    withTrend: PropTypes.bool,
};

Sparkline.defaultProps = {
    animate: false,
    color: null,
    dataKey: 'value',
    withTrend: false,
};
