import {AnyAction, createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
    CLEAR_RESULTS,
    OPEN_EDIT_SAVE_SEARCH,
    RESET_FILTERS,
} from 'hsi/constants/actionTypes';

import {CardType} from 'hsi/types/cards';
import {
    MentionType,
    QuickSearchMentionApiType,
    SavedSearchMentionApiType,
    SavedSearchMentionType,
} from 'hsi/types/mentions';

import {DateRange, FiltersState} from 'hsi/types/filters';
import {AsyncThunkPayloadAction} from 'hsi/types/redux';
import {
    deleteMentions,
    loadMentions,
    loadMentionsCursoredPagination,
    MentionsLoadArg,
    MentionsLoadFulfilledPayload,
    MentionsLoadRejectedPayload,
    patchMentions,
    setMentionSelected,
} from './thunks';
import {PeakType} from 'hsi/types/peaks';
import normaliseSavedSearchMention from 'hsi/utils/mentions/normaliseSavedSearchMention';
import normaliseQuickSearchMention from 'hsi/utils/mentions/normaliseQuickSearchMention';
import {WritableDraft} from 'immer/dist/types/types-external';
import {MAX_SELECTED_MENTIONS} from 'hsi/constants/config';

export type MentionOrderByQuicksearch = 'date' | 'random' | 'relevance';
export type MentionOrderBySavedsearch =
    | 'date'
    | 'reachEstimate'
    | 'md5'
    | 'twitterRetweets'
    | 'impressions';

export const MENTION_LAYOUT_TYPES = ['uniform', 'masonry'] as const;
export type MentionsLayout = typeof MENTION_LAYOUT_TYPES[number];
export type MentionOrderByType = MentionOrderByQuicksearch | MentionOrderBySavedsearch;

export type EditMentionType = {
    loading: boolean;
    error: boolean;

    /**
     * Is location being edited
     */
    location: boolean;
    /**
     * Is emotion being edited
     */
    emotion: boolean;
    /**
     * Is sentiment beignt edited
     */
    sentiment: boolean;
    /**
     * Are tags beings edited
     */
    tags: boolean;
    /**
     * Is this mention being deleted
     */
    deleting: boolean;
};

export type MentionsStateType = {
    results: MentionType[];
    keyword: []; //TODO Is this used?
    isOpen: boolean;
    isSelectMode: boolean;
    selectedMentions: Record<string, boolean>;
    numSelectedMentions: number;
    isFull: boolean;
    fullLayout: MentionsLayout;

    /**
     * Are the mentions being loaded?
     */
    loading: boolean;
    error: boolean;
    errorCode?: string;
    page: number;
    hasMore: boolean;
    drillInFilter?: FiltersState;
    drillInDates?: DateRange;
    drillInCard?: CardType;
    drillInLabel?: string;
    drillInFrom?: string; //Seems to be a domain, used in top authors drill in (used for the 'from' part of the drill-in label)
    peak?: PeakType;
    orderBy: MentionOrderByType;
    orderIsAsc: boolean;
    cursor?: string;

    editMentionStatus: Record<string, EditMentionType>;

    bulkEditTagsIsOpen: boolean;
    bulkChangeSentimentIsOpen: boolean;
    bulkDeleteIsOpen: boolean;
    isBulkActionPending: boolean;
};

const initialState: MentionsStateType = {
    results: [],
    keyword: [],
    isOpen: true,
    isSelectMode: false,
    numSelectedMentions: 0,
    selectedMentions: {},
    isFull: false,
    fullLayout: 'masonry',
    loading: false,
    error: false,
    errorCode: undefined,
    page: 0,
    hasMore: false,
    drillInFilter: undefined,
    drillInDates: undefined,
    drillInCard: undefined,
    drillInLabel: undefined,
    drillInFrom: undefined,
    peak: undefined,
    orderBy: 'date',
    orderIsAsc: false,

    editMentionStatus: {},

    bulkEditTagsIsOpen: false,
    bulkChangeSentimentIsOpen: false,
    bulkDeleteIsOpen: false,
    isBulkActionPending: false,
};

//Thunks:

const slice = createSlice({
    name: 'mentions',
    initialState,
    reducers: {
        setBulkEditTagsIsOpen: (state, {payload}: PayloadAction<boolean>) => {
            state.bulkEditTagsIsOpen = payload;
        },
        setBulkChangeSentimentIsOpen: (state, {payload}: PayloadAction<boolean>) => {
            state.bulkChangeSentimentIsOpen = payload;
        },
        setBulkDeleteIsOpen: (state, {payload}: PayloadAction<boolean>) => {
            state.bulkDeleteIsOpen = payload;
        },
        setMentionsOpen: (state, {payload}: PayloadAction<boolean>) => {
            state.isOpen = payload;
            state.isFull = false;
            state.peak = undefined;
        },

        setMentionsIsSelectMode: (state, {payload}: PayloadAction<boolean>) => {
            state.isSelectMode = payload;

            if (!payload) {
                state.selectedMentions = {}; //clear selected mentions when exiting select mode
                state.numSelectedMentions = 0;
            }
        },

        clearSelectedMentions: (state) => {
            state.selectedMentions = {};
            state.numSelectedMentions = 0;
        },

        setMentionsFull: (state, {payload}: PayloadAction<boolean>) => {
            state.isFull = payload;
        },

        setMentionsLayout: (state, {payload}: PayloadAction<MentionsLayout>) => {
            state.fullLayout = payload;
        },

        setMentionsOrder: (
            state,
            {
                payload: {orderBy, orderIsAsc},
            }: PayloadAction<Pick<MentionsStateType, 'orderBy' | 'orderIsAsc'>>,
        ) => {
            state.orderBy = orderBy;
            state.orderIsAsc = orderIsAsc;
        },

        mentionsDrillIn: (
            state,
            {
                payload: {
                    drillInFilter,
                    drillInCard,
                    drillInDates,
                    drillInLabel,
                    drillInFrom,
                    peak,
                },
            }: PayloadAction<
                Pick<
                    MentionsStateType,
                    | 'drillInFilter'
                    | 'drillInCard'
                    | 'drillInDates'
                    | 'drillInLabel'
                    | 'drillInFrom'
                    | 'peak'
                >
            >,
        ) => {
            //Only trigger a reload if the drill-in has actually changed
            if (
                state.drillInFilter !== drillInFilter ||
                state.drillInCard !== drillInCard ||
                state.drillInDates !== drillInDates ||
                state.drillInLabel !== drillInLabel ||
                state.drillInFrom !== (drillInFrom ?? undefined) ||
                state.peak !== peak
            ) {
                state.drillInFilter = drillInFilter;
                state.drillInCard = drillInCard;
                state.drillInDates = drillInDates;
                state.drillInLabel = drillInLabel;
                state.drillInFrom = drillInFrom ?? undefined;
                state.peak = peak;
                state.loading = true;
                state.isOpen = true;
            }
        },
        mentionsDrillOut: (state) => {
            state.drillInFilter = undefined;
            state.drillInCard = undefined;
            state.drillInDates = undefined;
            state.drillInLabel = undefined;
            state.drillInFrom = undefined;
            state.peak = undefined;
            state.loading = true;
        },

        setMentionTracked: (state, {payload: {id}}) => {
            state.results = state.results.map((mention) => {
                if (mention.id === id) mention.hasBeenTracked = true;
                return mention;
            });
        },

        /**
         * Called when leaving the mentions results page
         * @param state
         */
        clearMentions: (state) => {
            state.results = [];
            state.page = 0;
            state.selectedMentions = {};
            state.numSelectedMentions = 0;
        },
    },

    extraReducers(builder) {
        builder
            .addCase(setMentionSelected.fulfilled, (state, {payload: {id, selected}}) => {
                if (selected) {
                    //This should be already validated by the thunk, but leaving this here as a belt-and-braces fallback.
                    updateSelectedMentionsCount(state);

                    if (state.numSelectedMentions < MAX_SELECTED_MENTIONS) {
                        state.selectedMentions[id] = selected;
                    }
                } else {
                    delete state.selectedMentions[id];
                }

                updateSelectedMentionsCount(state);
            })

            .addCase(CLEAR_RESULTS, (state) => {
                state.drillInFilter = undefined;
                state.drillInDates = undefined;
                state.drillInCard = undefined;
                state.drillInLabel = undefined;
                state.drillInFrom = undefined;
                state.peak = undefined;
            })
            .addCase(OPEN_EDIT_SAVE_SEARCH, (state) => {
                state.orderBy = 'date';
                state.orderIsAsc = false;
            })
            .addCase(RESET_FILTERS, slice.caseReducers.mentionsDrillOut)

            //Patch mentions thunk lifecycle handlers
            .addCase(
                patchMentions.pending,
                (
                    state,
                    {
                        meta: {
                            arg: {patch, isBulkAction},
                        },
                    },
                ) => {
                    isBulkAction && (state.isBulkActionPending = true);

                    patch.forEach(({mention, patchData}) => {
                        state.editMentionStatus[mention.resourceId] = {
                            loading: true,
                            error: false,
                            deleting: false,

                            location: !!patchData.location,
                            emotion:
                                !!patchData.addClassifications || !!patchData.removeClassifications,
                            sentiment: !!patchData.sentiment,
                            tags: !!patchData.addTag || !!patchData.removeTag,
                        };
                    });
                },
            )

            .addCase(patchMentions.fulfilled, (state, {meta: {arg}, payload}) => {
                const mentionIds = arg.patch.map(({mention: {resourceId}}) => resourceId);
                //Clear the edit mention statuses + selected status for these mentions
                clearEditMentionStatus(state, mentionIds);
                removeFromSelectedMentions(state, mentionIds);

                if (arg.isBulkAction) {
                    state.isBulkActionPending = false;
                }

                if (payload) {
                    //patch mention can potentially return nothing if the servers are too busy
                    payload.forEach((patchedMention) => {
                        const normalisedPatchedMention =
                            normaliseSavedSearchMention(patchedMention);

                        const index = state.results.findIndex(
                            (mention) => mention.id === normalisedPatchedMention.id,
                        );

                        const existingMention = state.results[index] as SavedSearchMentionType;

                        //retain fullText (if applicable)
                        state.results[index] = existingMention.fullText
                            ? {fullText: existingMention.fullText, ...normalisedPatchedMention}
                            : normalisedPatchedMention;
                    });
                }
            })

            .addCase(patchMentions.rejected, (state, {meta: {arg}}) => {
                if (arg.isBulkAction) {
                    state.isBulkActionPending = false;
                }

                failedEditMentionStatus(
                    state,
                    arg.patch.map(({mention: {resourceId}}) => resourceId),
                );
            })

            //Delete mentions thunk lifecycle handlers
            .addCase(
                deleteMentions.pending,
                (
                    state,
                    {
                        meta: {
                            arg: {mentions, isBulkAction},
                        },
                    },
                ) => {
                    if (isBulkAction) {
                        state.isBulkActionPending = true;
                    }

                    //update edit mention status
                    updateEditMentionStatus(
                        state,
                        mentions.map(({resourceId}) => resourceId),
                        {
                            loading: true,
                            error: false,
                            deleting: true,

                            location: false,
                            tags: false,
                            sentiment: false,
                            emotion: false,
                        },
                    );
                },
            )
            .addCase(
                deleteMentions.fulfilled,
                (
                    state,
                    {
                        payload,
                        meta: {
                            arg: {isBulkAction},
                        },
                    },
                ) => {
                    const deletedIds = new Set(payload);

                    if (isBulkAction) {
                        state.isBulkActionPending = false;
                    }

                    state.results = state.results.filter(({id}) => !deletedIds.has(id));

                    removeFromSelectedMentions(state, payload);
                    clearEditMentionStatus(state, payload);
                },
            )
            .addCase(
                deleteMentions.rejected,
                (
                    state,
                    {
                        meta: {
                            arg: {mentions, isBulkAction},
                        },
                    },
                ) => {
                    if (isBulkAction) {
                        state.isBulkActionPending = false;
                    }

                    failedEditMentionStatus(
                        state,
                        mentions.map(({resourceId}) => resourceId),
                    );
                },
            )

            //Matchers must go after all .addCase
            //Using matchers because I want the same handlers for two different thunks (loadMentions and LoadMentionsCursored)
            .addMatcher(isMentionLoadPendingAction, (state, action) => {
                if (!action.meta.arg.append) {
                    state.results = [];
                    state.selectedMentions = {};
                    state.numSelectedMentions = 0;
                }

                state.loading = true;
                state.error = false;
                state.errorCode = undefined;
            })

            .addMatcher(isMentionLoadFulfilledAction, (state, {payload, meta}) => {
                const normalisedMentions = meta.arg.queryContext.isSavedSearch
                    ? (payload.results as SavedSearchMentionApiType[]).map(
                          normaliseSavedSearchMention,
                      )
                    : (payload.results as QuickSearchMentionApiType[]).map(
                          normaliseQuickSearchMention,
                      );

                state.results = meta.arg.append
                    ? [...state.results, ...normalisedMentions]
                    : normalisedMentions;
                state.hasMore = payload.hasMore;
                state.cursor = payload.cursor;
                state.loading = false;
                state.error = false;
                state.errorCode = undefined;

                if (!meta.arg.append) {
                    //clear selected here, or just update?
                    state.selectedMentions = {};
                    state.numSelectedMentions = 0;
                }
            })

            .addMatcher(isMentionLoadRejectedAction, (state, {payload}) => {
                if (!payload) {
                    return; //When would this happen?
                }
                if (payload.showError) {
                    state.error = payload.showError;
                    state.errorCode = payload.errorCode;
                }

                state.loading = false;
            });
    },
});

//Internal helpers
/**
 * Sets the value of editMentionStatus for a set of mentions
 * @param state
 * @param mentionIds
 * @param status
 */
function updateEditMentionStatus(
    state: WritableDraft<MentionsStateType>,
    mentionIds: string[],
    status: EditMentionType,
) {
    mentionIds.forEach((mentionId) => {
        state.editMentionStatus[mentionId] = status;
    });
}

/**
 * Will delete the supplied mention ids from the editMentionStatus set
 *
 * @param state
 * @param mentionIds
 */
function clearEditMentionStatus(state: WritableDraft<MentionsStateType>, mentionIds: string[]) {
    mentionIds.forEach((mentionId) => {
        delete state.editMentionStatus[mentionId];
    });
}

/**
 * If an update/delete action fails, update the editedMentionStatus object for the affected mentions
 *
 * @param state The current reducer state
 * @param mentionIds list of mention IDs
 */
function failedEditMentionStatus(state: WritableDraft<MentionsStateType>, mentionIds: string[]) {
    mentionIds.forEach((mentionId) => {
        const editedMentionStatus = state.editMentionStatus[mentionId];

        if (editedMentionStatus) {
            editedMentionStatus.loading = false;
            editedMentionStatus.error = true;
        }
    });
}

/**
 * Will delete the supplied mention ids from the selectedMentions set
 *
 * @param state
 * @param mentionIds
 */
function removeFromSelectedMentions(state: WritableDraft<MentionsStateType>, mentionIds: string[]) {
    mentionIds.forEach((mentionId) => {
        delete state.selectedMentions[mentionId];
    });

    //Update the count of selected mentions
    updateSelectedMentionsCount(state);
}

function updateSelectedMentionsCount(state: WritableDraft<MentionsStateType>) {
    //Get keys of all mentions as a set
    const mentionsIds = state.results.reduce((output, {id}) => {
        output.add(id);

        return output;
    }, new Set<string>());

    //delete any keys that do not correspond to a mention ID
    Object.keys(state.selectedMentions).forEach((id) => {
        if (!mentionsIds.has(id)) {
            delete state.selectedMentions[id];
        }
    });

    //keep count of selected mentions updated
    state.numSelectedMentions = Object.keys(state.selectedMentions).length;
}

//Typeguards
function isMentionLoadPendingAction(
    action: AnyAction,
): action is AsyncThunkPayloadAction<void, MentionsLoadArg, 'pending'> {
    return (
        action.type === loadMentions.pending.type ||
        action.type === loadMentionsCursoredPagination.pending.type
    );
}

function isMentionLoadFulfilledAction(
    action: AnyAction,
): action is AsyncThunkPayloadAction<MentionsLoadFulfilledPayload, MentionsLoadArg, 'fulfilled'> {
    return (
        action.type === loadMentions.fulfilled.type ||
        action.type === loadMentionsCursoredPagination.fulfilled.type
    );
}

function isMentionLoadRejectedAction(
    action: AnyAction,
): action is AsyncThunkPayloadAction<MentionsLoadRejectedPayload, MentionsLoadArg, 'rejected'> {
    return (
        action.type === loadMentions.rejected.type ||
        action.type === loadMentionsCursoredPagination.rejected.type
    );
}

export const {
    setMentionsOpen,
    setMentionsIsSelectMode,
    setMentionsFull,
    setMentionsLayout,
    mentionsDrillOut,
    clearMentions,
    setBulkEditTagsIsOpen,
    setBulkChangeSentimentIsOpen,
    setBulkDeleteIsOpen,
    clearSelectedMentions,
} = slice.actions;
export default slice.reducer;
export {
    loadMentions,
    loadMentionsCursoredPagination,
    updateMentionsSentiment,
    addMentionsTag,
    removeMentionsTag,
    updateMentionsLocation,
    updateMentionsEmotion,
    deleteMentions,
    setMentionSelected,
} from './thunks';

//The legacy actions did not take a single argument, so we need these
//functions to convert the arguments to the new single payload format
export function mentionsDrillIn(
    drillInFilter: MentionsStateType['drillInFilter'],
    drillInCard: MentionsStateType['drillInCard'],
    drillInDates: MentionsStateType['drillInDates'],
    drillInLabel: MentionsStateType['drillInLabel'],
    peak: MentionsStateType['peak'],
    drillInFrom: MentionsStateType['drillInFrom'],
) {
    return slice.actions.mentionsDrillIn({
        drillInFilter,
        drillInCard,
        drillInDates,
        drillInLabel,
        drillInFrom,
        peak,
    });
}

export function setMentionsOrder(orderBy: MentionOrderByType, orderIsAsc: boolean) {
    return slice.actions.setMentionsOrder({orderBy, orderIsAsc});
}

export function setMentionTracked(id: string) {
    return slice.actions.setMentionTracked({id});
}
