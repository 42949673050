import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

//Components
import CardTitle from 'hsi/components/Card/CardTitle';
import DrillInMentionsButton from 'hsi/components/DrillInMentionsButton';
import InfoPopupContent from 'hsi/components/InfoPopupContent';
import TopTenCard from 'hsi/components/Cards/TopTenCard/';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useGetLoadData from './useGetLoadData';

//Utils
import {drillInFilters} from 'hsi/utils/filters';
import {formatBigInt} from 'hsi/utils/formatNumber';

//Other
import {T} from 'hsi/i18n';

//Constants
const type = 'topSharedURLs';

//The components
const TopSharedURLs = React.forwardRef(({title, ...props}, ref) => {
    const {
        links: {dashboardInfoTopSharedURLsCTA: popupCTA},
    } = useConfig();

    //Redux
    const {data, loading, loaded, error} = useSelector((state) => state.chart[type]);
    const breakdown = useSelector((state) => state.cardPersistState[type]?.breakdown);

    //Calculated values
    const loadData = useGetLoadData(type);

    const drillInData = useCallback(
        (url) => ({
            dates: null,
            filter: drillInFilters.sharedUrl(url),
            label: url,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: url,
            },
            type,
        }),
        [],
    );

    const popup = useMemo(
        () => (
            <InfoPopupContent
                title={T(`cards.${type}.info.title`)}
                copy={T(`cards.${type}.info.copy`)}
                ctaUrl={popupCTA}
                ctaLabel={T('cards.infoCTALabel')}
            />
        ),
        [popupCTA],
    );

    const fields = [
        {
            label: T(`cards.${type}.label`),
            options: {size: 'full'},
            format: ({name}) => (
                <DrillInMentionsButton drillInData={drillInData(name)}>
                    {name}
                </DrillInMentionsButton>
            ),
            tooltip: ({name}) => name,
        },
        {
            label: T('mentions'),
            options: {bold: true, textAlign: 'right'},
            format: ({volume}) => formatBigInt(volume),
        },
    ];

    //Render
    return (
        <TopTenCard
            title={
                <CardTitle title={title} tooltipComponent={popup} type={type} hasData={!!data} />
            }
            breakdown={breakdown}
            data={data}
            data-testid={type}
            error={error}
            fields={fields}
            loaded={loaded}
            loading={loading}
            type={type}
            loadData={loadData}
            ref={ref}
            {...props}
        />
    );
});

TopSharedURLs.propTypes = {
    props: PropTypes.shape({
        fadeOnVisible: PropTypes.func.isRequired,
        height: PropTypes.number.isRequired,
        isVisible: PropTypes.bool.isRequired,
        onContentChanged: PropTypes.func.isRequired,
    }),
};

TopSharedURLs.displayName = type;

export default TopSharedURLs;
