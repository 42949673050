import React, {useState, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import IconButton from '@mui/material/IconButton';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import IconRouter from 'hsi/components/IconRouter';
import OverflowTooltip from 'hsi/components/SimpleTooltip/OverflowTooltipHook';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useQueryContext from 'hsi/hooks/useQueryContext';

//Slices
import {updatePage} from 'hsi/slices/cardTables';
import {selectCardTablePage} from 'hsi/selectors';

//Other
import useStyles from './styles';

//The component
const TopTenCard = React.forwardRef(
    ({data: _data, error, fields, loadData, loaded, loading, title, type, ...props}, ref) => {
        const {classes, cx} = useStyles();
        const {
            topTenCard: {pageSize, quickSearchLimit},
        } = useConfig();
        const {isSavedSearch} = useQueryContext();
        const dispatch = useDispatch();
        //Redux
        const savedSearchId = useSelector((state) => state.query.context?.savedSearchId);
        const persistedPage = useSelector(selectCardTablePage(type, savedSearchId));
        const isSelectedCard = useSelector((state) => state.mentions.drillInCard === type);

        //State
        const [page, setPage] = useState(persistedPage || 0);

        const setCurrentPage = useCallback(
            (page) => {
                dispatch(updatePage({type, savedSearchId, value: page}));
                setPage(page);
            },
            [type, savedSearchId, dispatch],
        );

        //Calculated values
        const data = useMemo(() => {
            if (_data && Array.isArray(_data)) {
                return _data
                    .map((item, idx) => ({...item, index: idx + 1}))
                    .slice(0, !isSavedSearch ? quickSearchLimit : _data.length);
            }
        }, [_data, isSavedSearch, quickSearchLimit]);

        const content = useMemo(() => {
            const pageMinItem = page * pageSize + 1;
            const pageMaxPossibleItem = (page + 1) * pageSize;
            const totalItems = data?.length || 0;
            const pageMaxItem = pageMaxPossibleItem < totalItems ? pageMaxPossibleItem : totalItems;
            const numOfPages = Math.ceil(totalItems / pageSize);

            const cellData = {classes};
            //build gridTemplateColumns string
            const gridTemplateColumns =
                'auto ' +
                fields
                    .map(({options: {size}}, i, list) => {
                        if (size === 'full') {
                            return 'minmax(100px, 1fr)';
                        }

                        const isLast = i + 1 === list.length;
                        const widthVar = 'var(--table-cell-normal-width-max)';

                        return `fit-content(${
                            isLast ? `calc(${widthVar} + var(--table-row-end-padding))` : widthVar
                        })`;
                    })
                    .join(' ');

            return (
                <div className={classes.root}>
                    <table className={classes.table} style={{gridTemplateColumns}}>
                        <thead className={classes.thead}>
                            <tr className={classes.tr}>
                                {fields.map(({label, options}, i) => {
                                    const style = i === 0 ? {gridColumn: '1 / 3'} : null;
                                    const textAlign =
                                        options.textAlign &&
                                        classes[`textAlign_${options.textAlign}`];

                                    return (
                                        <OverflowTooltip key={i} tooltip={label}>
                                            <th className={cx(classes.th, textAlign)} style={style}>
                                                {label}
                                            </th>
                                        </OverflowTooltip>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className={classes.tbody}>
                            {totalItems > 0 &&
                                data.slice(pageMinItem - 1, pageMaxItem).map((item, i) => (
                                    <tr key={pageMinItem + i} className={classes.tr}>
                                        <td key={i} className={cx(classes.td, classes.bold)}>
                                            {pageMinItem + i}
                                        </td>
                                        {fields.map(({options, format, tooltip}, i) => {
                                            const value = format(item, cellData);

                                            return (
                                                <Cell
                                                    key={i}
                                                    className={cx(
                                                        classes.td,
                                                        options.bold && classes.bold,
                                                        options.textAlign &&
                                                            classes[
                                                                `textAlign_${options.textAlign}`
                                                            ],
                                                    )}
                                                    options={options}
                                                    tooltip={
                                                        tooltip instanceof Function
                                                            ? tooltip(item, cellData)
                                                            : value
                                                    }
                                                    value={value}
                                                />
                                            );
                                        })}
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <PaginationButtons
                        {...{classes, numOfPages, page, pageMaxItem, setCurrentPage, totalItems}}
                    />
                </div>
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [classes, data, fields, page, pageSize, setCurrentPage]);

        //Render
        return (
            <CardLoadState
                {...props}
                title={title}
                error={error}
                loading={loading}
                loaded={loaded}
                hasData={!!data?.length > 0}
                selected={isSelectedCard}
                skipCardContent
                loadData={loadData}
                type={type}
                ref={ref}
            >
                {content}
            </CardLoadState>
        );
    },
);

const Cell = ({className, options, tooltip, value}) => {
    if (tooltip && options.button) {
        return (
            <td className={className}>
                <OverflowTooltip tooltip={tooltip}>{value}</OverflowTooltip>
            </td>
        );
    }

    if (tooltip && !options.button) {
        return (
            <OverflowTooltip distance={0} tooltip={tooltip}>
                <td className={className}>{value}</td>
            </OverflowTooltip>
        );
    }

    return <td className={className}>{value}</td>;
};

const PaginationButtons = ({
    classes,
    numOfPages,
    page,
    pageMaxItem,
    setCurrentPage,
    totalItems,
}) => {
    if (numOfPages <= 1) {
        return null;
    }

    return (
        <div className={classes.navigation} data-testid="topTenCardPagination">
            <IconButton
                aria-label="previous page"
                disabled={page <= 0}
                onClick={() => setCurrentPage(page - 1)}
                size="small"
            >
                <IconRouter name="left-arrow" />
            </IconButton>
            <div className={classes.navLabel}>
                {page + 1}/{numOfPages}
            </div>
            <IconButton
                aria-label="next page"
                disabled={pageMaxItem === totalItems}
                onClick={() => setCurrentPage(page + 1)}
                size="small"
            >
                <IconRouter name="right-arrow" />
            </IconButton>
        </div>
    );
};

TopTenCard.propTypes = {
    data: PropTypes.array,
    error: PropTypes.bool,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            format: PropTypes.func.isRequired,
            options: PropTypes.object.isRequired,
            tooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        }).isRequired,
    ).isRequired,
    loadData: PropTypes.func.isRequired,
    loaded: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
    title: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
};

TopTenCard.displayName = 'TopTenCard';

export default TopTenCard;
