import {makeStyles} from 'tss-react/mui';

export default makeStyles()((theme, _params, classes) => ({
    sparklineWrapper: {
        position: 'relative',
    },

    trend: {
        position: 'absolute',
        right: '3px',
        top: '50%',
        transform: 'translate(0, -50%)',
        zIndex: 4,
        borderStyle: 'solid',

        [`&.${classes.up}`]: {
            width: '5px',
            height: '5px',
            borderBottomWidth: '5px',
            borderTopWidth: 0,
            borderLeftWidth: '3px',
            borderRightWidth: '3px',
            borderLeftColor: 'transparent !important',
            borderRightColor: 'transparent !important',
            borderTopColor: 'transparent !important',
        },
        [`&.${classes.down}`]: {
            width: '5px',
            height: '5px',
            borderTopWidth: '5px',
            borderBottomWidth: 0,
            borderLeftWidth: '3px',
            borderRightWidth: '3px',
            borderLeftColor: 'transparent !important',
            borderRightColor: 'transparent !important',
            borderBottomColor: 'transparent !important',
        },
        [`&.${classes.same}`]: {
            width: '6px',
            height: '0px',
            borderBottomWidth: 0,
            borderTopWidth: '1px',
            borderLeftWidth: '0px',
            borderRightWidth: '0px',
        },
    },

    up: {},
    down: {},
    same: {},
}));
