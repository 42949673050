import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    favicon: {
        width: '16px',
        height: '16px',
    },
    siteDetails: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
}));
