import { useContext, useMemo } from "react";
import ComboboxContext from "../context";
import { ComboboxListProps } from "../types";


export default function useComboboxListProps<OtherProps extends {}>(otherProps?: OtherProps) {
    const props = useContext(ComboboxContext);

    if(props === undefined) {
        throw new Error('Combobx input must be nested within a Combobbox component');
    }

    const {labelId, listBoxId, open} = props;

    return useMemo<ComboboxListProps<OtherProps>>(() => {
        return {
            ...(otherProps!),
            open,
            id: listBoxId,
            role: 'listbox',
            "aria-labelledby": labelId
        };
    }, [labelId, listBoxId, open, otherProps]);
}