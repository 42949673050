import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';
import merge from 'lodash/merge';

export default makeStyles((theme: Theme) => ({
    paginationButtons: merge(
        {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        theme.elements.cardTable?.paginationButtons || {},
    ),
    pageIndicator: {
        margin: '0 12px',
    },
}));
