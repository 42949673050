import {ThemeV1} from 'hsi/types/theme';
import failImage from 'hsi/img/401cat.png';
import noResultsImg from 'hsi/themes/hsi/img/no-results.png';
import ThemeBuilder from 'hsi/classes/ThemeBuilder';

const elements = (theme: ThemeBuilder) => {
    const colors = theme.colors as ThemeV1['colors'];

    return {
        searchBarContainerHeight: '72px',
        navBarContainerHeight: '72px',
        filtersDrawerWidth: '300px',
        mentionsDrawerWidth: '470px',
        configDrawerWidth: '470px',
        configDrawerOffsetTop: '148px !important',
        failImage,
        noResultsImg,

        newBadge: {
            padding: '2px 4px',
            fontSize: '12px',
            color: '#CB4807',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            borderRadius: '2px',
            marginLeft: '8px',
            fontWeight: 700,
            height: '20px',
            lineHeight: '20px',
        },

        cardTable: {
            fontSize: '16px',
            rowHeight: '48px',
            headerHeight: '48px',
            headerSortingColor: colors.primaryBlue100,
            headerCell: {
                borderBottom: `1px solid ${colors.lightGrey40}`,
            },
            row: {
                '&:hover $cell': {
                    backgroundColor: colors.lightGrey20,
                },
            },
            cell: {
                borderBottom: `1px dotted ${colors.lightGrey40}`,
                cursor: 'pointer',
            },
            arrowIcon: {
                width: '24px',
                height: '24px',
            },
            paginationButtons: {
                padding: '12px',
                '& .MuiIconButton-root': {
                    backgroundColor: colors.primaryBlue20,
                    '& svg': {
                        fill: colors.primaryBlue100,
                    },
                    '@media print': {
                        display: 'none',
                    },
                    '.printMedia &': {
                        display: 'none',
                    },
                },
                '& .MuiIconButton-root.Mui-disabled': {
                    backgroundColor: colors.lightGrey40,
                },
            },
        },

        topAuthorsCard: {
            indexColor: colors.textDefault,
            locationColor: colors.textDefault,
        },

        topHashtagsCard: {
            indexColor: colors.textDefault,
            locationColor: colors.textDefault,
        },
    };
};

export default elements;
