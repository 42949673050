// TODO: improve types as I make more reducers TS
import {RootState} from 'hsi/utils/configureStore';
import {SortDirectionType} from 'hsi/components/CardTable/v2/useSort';

export const selectAdditionalQueries = (state: RootState) => state.query?.additionalQueries;

export const selectCanImpersonate = (state: RootState): boolean => state.user.canImpersonate;

export const selectCardTableSort =
    (type: keyof RootState['cardTables']['sort'], savedSearchId: number | undefined) =>
    (state: Pick<RootState, 'cardTables'>) =>
        {
            return state.cardTables.sort?.[type]?.[savedSearchId!] ?? undefined;
        }

export const selectCardTablePage =
    (type: keyof RootState['cardTables']['page'] | undefined, savedSearchId: number | undefined) =>
    (state: RootState): number | undefined =>
        (type && state.cardTables.page?.[type]?.[savedSearchId!]) ?? undefined;

export const selectCardTableSortPersist = (type: 'tophashtags' | 'topauthors') =>
(state: Pick<RootState, 'cardPersistState'>) =>
    {
        return state.cardPersistState?.[type as 'topauthors' | 'tophashtags'] as {sortDir: SortDirectionType, sortKey: string };
    }

export const selectDrillInCard = (state: RootState) => state.mentions.drillInCard;

export const selectImpersonationLoading = (state: RootState) => state.user.impersonationLoading;

export const selectIsImpersonated = (state: RootState) => state.user.isImpersonated;

export const selectIsViewOnly = (state: RootState) => state.user.isViewOnly as boolean;

export const selectLinkedinChannelIds = (state: RootState) => state?.query?.linkedinChannelIds;

export const selectNumFilters = (state: RootState) => state.filters.numAppliedFilters;

export const selectProjectId = (state: RootState) => state.query?.context?.projectId;

export const selectProjects = (state: RootState) => state.user?.projects;

export const selectSavedSearch = (state: RootState): any | undefined =>
    state.query?.context?.savedSearch;

export const selectSavedSearchId = (state: RootState) => state.query?.context?.savedSearchId;

export const selectSearches = (state: RootState) => (state.search as any)?.searches;

export const selectSessionStart = (state: RootState) => state.user.sessionStart;

export const selectSavingQuery = (state: RootState) => state?.query?.saving;

export const selectIsEditSearch = (state: RootState) => state?.query?.context?.isEditSearch;

export const selectIsMultipleSearch = (state: RootState) => state?.query?.context?.isMultipleSearch;

export const selectSearchType = (state: RootState) => state.query?.context?.searchType;

export const selectIsGuidedSearch = (state: RootState) => state.query?.isGuided;
