import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

const useStyles = makeStyles((theme: Theme) => ({
    timezoneSelect: {
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: theme.v2 ? 32 : undefined,
        width: theme.v2 ? 312 : undefined,
        minHeight: theme.v2 ? 32 : undefined,
    },
    timezoneSelectBtnLbl: {
        flex: '1 1 auto',
        textAlign: 'left',
        paddingTop: 0,
        paddingBottom: 0,
        ...(isV2(theme)
            ? {...theme.fonts.bodyNormal, textTransform: 'initial'}
            : {fontSize: '16px', fontWeight: 600}),
    },
    timezoneSelectClockIcon: {
        height: theme.v2 ? undefined : 20,
        width: theme.v2 ? undefined : 20,

        '& > svg': {
            fontSize: theme.v2 ? '1.5rem' : undefined,
        },
    },
    timezoneSelectDropdownIcon: {
        '& > svg': {
            fontSize: theme.v2 ? undefined : '1.5rem',
        },
    },
    timezoneDropdownDefaultOption: {
        alignItems: 'center',
        borderBottom: isV2(theme) ? `1px solid ${theme.colors.bgSubtle}` : `1px solid #CCC`,
        display: 'flex',

        ...(isV2(theme) ? theme.fonts.bodyNormal : {fontSize: '1rem'}),
        marginTop: theme.v2 ? 4 : undefined,
        minHeight: theme.v2 ? 40 : 48,
        padding: theme.v2 ? '6px 24px' : '6px 16px',

        '&:hover': {
            backgroundColor: isV2(theme) ? theme.colors.bgHover : theme.colors.primaryYellow20,
            cursor: 'pointer',
        },
        '&[aria-selected="true"]': {
            backgroundColor: isV2(theme)
                ? theme.colors.bgSubtle
                : `${theme.colors.primaryYellow40} !important`,
        },
    },
    timezoneDropdownDefaultOptionSelected: {
        backgroundColor: isV2(theme)
            ? theme.colors.bgSubtle
            : `${theme.colors.primaryYellow40} !important`,
    },
    timezoneOptionIcon: {
        fill: isV2(theme) ? theme.colors.primaryBlue100 : theme.colors.primaryBlue100,
        marginRight: 6,
    },
}));

export default useStyles;
