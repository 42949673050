import { useContext, useMemo } from "react";
import ComboboxContext from "../context";
import { ComboboxLabelProps } from "../types";


export default function useComboboxLabelProps<OtherProps extends {}>(otherProps?: OtherProps) {
    const props = useContext(ComboboxContext);

    if(props === undefined) {
        throw new Error('Combobx input must be nested within a Combobbox component');
    }

    const {id, labelId} = props;

    return useMemo<ComboboxLabelProps<OtherProps>>(() => {
        return {
            ...(otherProps!),
            id: labelId,
            htmlFor: id,
        };
    }, [id, labelId, otherProps]);
}
