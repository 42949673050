import React, {createContext, ReactNode, RefObject, useContext, useMemo} from 'react';
import cn from 'classnames';
import MuiCard from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

//Components
import {LoadErrorMsg} from 'hsi/components/ErrorMessage';
import PulseLoader from 'hsi/components/PulseLoader';
import SimpleMenu from 'hsi/components/SimpleMenu';

//Hooks
import useFocusOrPointerIn from 'hsi/hooks/useFocusOrPointerIn';
import useOnVisible, {VisibleObserver} from 'hsi/hooks/useOnVisible';
import useIsCardHidden from 'hsi/hooks/useIsCardHidden';
import useUniqueId from 'hsi/hooks/useUniqueId';

//Other
import useStyles from './styles';
import {T} from 'hsi/i18n';
import {CardType} from 'hsi/types/cards';

//Consts
type CardContextType = {
    isFocusOrPointerIn: boolean;
    isPointerIn: boolean;
    isFocusIn: boolean;
    id: string,
    headingId: string,
};

const CardContext = createContext<CardContextType>({
    isFocusOrPointerIn: false,
    isPointerIn: false,
    isFocusIn: false,
    id: '',
    headingId: '',
});
CardContext.displayName = 'CardContext';

export function useCardContext() {
    return useContext(CardContext);
}

type CardHeaderArgs = Parameters<typeof CardHeader>[0];

export type CardArgs = Omit<Parameters<typeof MuiCard>[0], 'className' | 'style' | 'title'> &
    Pick<CardHeaderArgs, 'avatar' | 'title' | 'subheader'> & {
        subheader?: ReactNode;
        menuEntries?: {label: string; onClick: () => void; icon?: ReactNode}[]; //TODO reference SimpleMenu types once they exist
        headerRight?: ReactNode;
        children: ReactNode;
        skipCardContent?: boolean;
        cardHeaderProps?: Omit<CardHeaderArgs, 'avatar' | 'title' | 'subheader'>;
        cardContentProps?: Parameters<typeof CardContent>[0];
        loading?: boolean;
        className?: string;
        selected?: boolean;
        height: any; //CSS height
        hasConfig?: boolean;
        toggleConfig?: () => void;
        type: CardType;
        renderWhenVisible?: boolean | React.Context<VisibleObserver>;
    };

//The component
export default function Card({
    avatar,
    title,
    subheader,
    menuEntries,
    headerRight,
    children,
    skipCardContent = false,
    cardHeaderProps,
    cardContentProps,
    loading = false,
    className,
    selected,
    height,
    hasConfig = false,
    toggleConfig,
    type,
    renderWhenVisible,
    id: _id,
    ...rest //What should this be? Valid props for MuiCard?
}: CardArgs) {
    const id = useUniqueId(_id, 'card');
    const headingId = cardHeaderProps?.id ?? `${id}.header`;
    const classes = useStyles();

    const isCardHidden = useIsCardHidden(type);

    const isRenderWhenVisibleEnabled = !!renderWhenVisible;
    //This hook only does anything if we assign the ref, and we only do that if isRenderWhenVisibleEnabled = true
    const [hasBeenVisible, renderWhenVisibleRef] = useOnVisible(
        undefined,
        undefined,
        isRenderWhenVisibleEnabled && renderWhenVisible !== true ? renderWhenVisible : undefined,
    );
    const canBeRendered = !isRenderWhenVisibleEnabled || hasBeenVisible;
    const renderChildren = canBeRendered ? children : null;

    const headerAction = useMemo(
        () => (menuEntries ? <SimpleMenu entries={menuEntries} /> : headerRight || null),
        [menuEntries, headerRight],
    );

    const [isFocusOrPointerIn, mouseOrFocusInProps, isPointerIn, isFocusIn] =
        useFocusOrPointerIn<HTMLDivElement>(rest);

    const cardContextValue = useMemo(
        () => ({isFocusOrPointerIn, isPointerIn, isFocusIn, id, headingId}),
        [isFocusOrPointerIn, isPointerIn, isFocusIn, id, headingId],
    );

    return (
        <MuiCard
            id={id}
            aria-labelledby={headingId}
            component="article"
            {...rest}
            className={cn(
                selected && classes.selected,
                className,
                classes.root,
            )}
            style={isCardHidden ? undefined : {height: height}}
            {...mouseOrFocusInProps}
            ref={isRenderWhenVisibleEnabled ? renderWhenVisibleRef as RefObject<HTMLDivElement> : undefined}
            tabIndex={-1}
        >
            <CardContext.Provider value={cardContextValue}>
                {(!!title || !!menuEntries || !!headerRight || !!cardHeaderProps) && (
                    <CardHeader
                        i
                        
                        title={title}
                        subheader={!isCardHidden ? subheader : undefined}
                        avatar={avatar}
                        action={headerAction}
                        hidden={isCardHidden}
                        {...cardHeaderProps}
                    />
                )}

                {isCardHidden 
                    ? null 
                    : (
                        !loading ? (
                            skipCardContent ? (
                                renderChildren
                            ) : (
                                <CardContent {...cardContentProps}>{renderChildren}</CardContent>
                            )
                        ) : (
                            <div className={classes.loadingWrapper}>
                                <PulseLoader size="medium" />
                            </div>
                        )
                    )
                }
            </CardContext.Provider>
        </MuiCard>
    );
}

//Other components
export const CardNoDataMsg = ({withConfig}: {withConfig: boolean}) => (
    <LoadErrorMsg
        line1={T('cards.noData.msg1')}
        line2={withConfig ? T('cards.noData.msg2b') : T('cards.noData.msg2')}
        iconType="info"
        ariaLive="off"
    />
);

export const CardErrorMsg = () => (
    <LoadErrorMsg line1={T('cards.error.msg1')} line2={T('cards.error.msg2')} ariaLive="off" />
);

export const CardUnsupportedMsg = () => (
    <LoadErrorMsg
        line1={T('cards.unsupported.msg1')}
        line2={T('cards.unsupported.msg2')}
        ariaLive="off"
    />
);

export const CardComingSoonMsg = () => (
    <LoadErrorMsg
        line1={T('cards.comingSoon.msg1')}
        line2={T('cards.comingSoon.msg2')}
        iconType="info"
        ariaLive="off"
    />
);

export const CardDatanotAvailablePerDataLimitation = ({type}: {type: string}) => (
    <LoadErrorMsg
        line1={T('cards.notAvailablePerDataLimitation.' + type + '.msg1')}
        line2={T('cards.notAvailablePerDataLimitation.' + type + '.msg2')}
        iconType="info"
        ariaLive="off"
    />
);

