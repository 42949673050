import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'avatar' | 'onlyPlatformIcon' | 'medium' | 'small'>()(
    (theme, _params, classes) => ({
        avatarWrapper: {
            position: 'relative',
            display: 'flex',
            flexShrink: 0,

            [`&.${classes.medium}`]: {
                [`${classes.avatar}`]: {
                    height: '48px',
                    width: '48px',
                },
                '.platform-icon': {
                    width: '20px',
                    height: '20px',
                },
                [`&.${classes.onlyPlatformIcon} .platform-icon`]: {
                    height: '48px',
                    width: '48px',
                },
            },

            [`&.${classes.small}`]: {
                [`${classes.avatar}`]: {
                    height: '30px',
                    width: '30px',
                },
                '.platform-icon': {
                    width: '15px',
                    height: '15px',
                },
                [`&.${classes.onlyPlatformIcon} .platform-icon`]: {
                    height: '30px',
                    width: '30px',
                },
            },

            [`&:not(.${classes.onlyPlatformIcon}) .platform-icon`]: {
                position: 'absolute',
                bottom: 0,
                right: 0,
                '.printMedia &': {
                    position: 'relative',
                    marginRight: '-20px',
                    display: 'flex',
                    alignSelf: 'end',
                    height: '24px',
                    width: '24px',
                },
            },
        },

        avatar: {},
        small: {},
        medium: {},
        onlyPlatformIcon: {},
    }),
);
