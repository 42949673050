import makeStyles from '@mui/styles/makeStyles';

export default makeStyles((theme) => ({
    root: {
        padding: '8px 0',
        position: 'relative',
    },

    bg: {
        position: 'absolute',
        zIndex: 0,
        top: '8px',
        left: 0,
        right: 0,
        bottom: 0,
        height: '8px',
        backgroundColor: theme.colors.primaryBlue20,
        borderRadius: '4px',
    },

    value: {
        position: 'relative',
        zIndex: 1,
        height: '8px',
        borderRadius: '4px',
        backgroundColor: theme.colors.primaryBlue100,
    },

    numberWithBarRoot: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
    },

    numberContainer: {
        padding: '0 14px 0 0',
        width: 60,
    },

    barContainer: {
        flex: `10 1 auto`,
    },
}));
