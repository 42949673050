import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useCallback, useContext, useMemo, useRef } from "react";
import { ComboboxInputOtherProps, ComboboxInputProps } from "../types";
import ComboboxContext from "../context";

export default function useComboboxInputProps<OtherProps extends ComboboxInputOtherProps>(props?: OtherProps) {
    const comboboxProps = useContext(ComboboxContext);

    if(comboboxProps === undefined) {
        throw new Error('Combobx input must be nested within a Combobbox component');
    }

    const isFocusedRef = useRef(false);
    const wasFocusedBeforeClickStartedRef = useRef(false);//This is needed ot prevent clicking input from opening listobx due to focus, then immediately closing again due to click toggle

    const {onChange: _onChange, onFocus: _onFocus, onBlur: _onBlur, onClick: _onClick, onMouseDown: _onMouseDown, onKeyDown: _onKeyDown, ...otherProps} = props || {};

    const {id, labelId, listBoxId, open, setOpen, inputValue, onInputValueChange, openOnFocus, closeOnBlur, clickTogglesOpen} = comboboxProps;

    //Event handlers
    const onChange: ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
        onInputValueChange(e.target.value, 'input');
        _onChange?.(e);
    }, [_onChange, onInputValueChange]);

    const onFocus: FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        //console.log('onFocus');
        openOnFocus && !open && setOpen(true);
        isFocusedRef.current = true;
        _onFocus?.(e);
    }, [_onFocus, open, openOnFocus, setOpen]);

    const onBlur: FocusEventHandler<HTMLInputElement> = useCallback((e) => {
        closeOnBlur && open && setOpen(false);
        isFocusedRef.current = false;
        _onBlur?.(e);
    }, [_onBlur, closeOnBlur, open, setOpen]);

    const onMouseDown: MouseEventHandler<HTMLInputElement> = useCallback((e) => {
        //console.log('onMouseDown');
        wasFocusedBeforeClickStartedRef.current = isFocusedRef.current;
        _onMouseDown?.(e);
    }, [_onMouseDown]);

    const onClick: MouseEventHandler<HTMLInputElement> = useCallback((e) => {
        //console.log('onClick: ', wasFocusedBeforeClickStartedRef.current);
        //If clickTogglesOpen and openOnFocus are enabled, ignore clicks that start before the input was focused
        clickTogglesOpen && (!openOnFocus || wasFocusedBeforeClickStartedRef.current) && setOpen(!open);
        _onClick?.(e);
    }, [_onClick, clickTogglesOpen, open, openOnFocus, setOpen]);

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback((e) => {
        _onKeyDown?.(e);
//console.log(e.key);
        switch(e.key) {
            case 'ArrowDown':
                console.log('down');
                break;
            case 'ArrowUp':
                console.log('up');
                break;
            case 'Enter':
                console.log('select');
                break;
            case 'Escape':
                open && setOpen(false);
                break;
        }
    }, [_onKeyDown, open, setOpen]);

    //The props
    return useMemo<ComboboxInputProps<OtherProps>>(() => {
        return {
            ...(otherProps as any),//TS seems to screw up with this, so need to cast to any

            id,
            "aria-activedescendant": undefined,//TODO ID of selected item (if applicable)
            "aria-autocomplete": 'list',
            "aria-controls": listBoxId,
            "aria-expanded": open,//<<TODO?
            "aria-labelledby": labelId,
            autoComplete: 'off',
            role: 'combobox',
            value: inputValue,

            //Event handlers
            onChange,
            onFocus,
            onBlur,
            onMouseDown,
            onClick,
            onKeyDown,
        };
    }, [otherProps, id, listBoxId, open, labelId, inputValue, onChange, onFocus, onBlur, onMouseDown, onClick, onKeyDown]);
}