import makeStyles from '@mui/styles/makeStyles';
import {Theme, isV2} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => {
    const v2 = isV2(theme);

    return {
        root: {
            display: 'flex',
        },
        hide: {
            display: 'none',
        },
        drawer: {
            flexShrink: 0,
            height: '100%',
            zIndex: 1000,
            backgroundColor: v2 ? theme.colors.uiGrayDark : theme.colors.lightGrey60,
            [theme.breakpoints.up('xl')]: {
                overflow: 'hidden',
                height: 'auto',
                '& .MuiDrawer-paper': {
                    position: 'absolute',
                },
            },
        },
        leftAnchored: {
            position: 'absolute',
            top: '0px',
            left: '0px',
            height: '100%',
            boxShadow: v2 ? 'none' : `8px 0px 0px ${theme.colors.lightGrey80}`,
            marginRight: v2 ? undefined : '8px',

            '&.forceSticky': {
                position: 'sticky',
                paddingTop: '0px !important',
                boxShadow: v2 ? 'none' : `8px 0px 0px ${theme.colors.lightGrey80}`,
                top: theme.elements.searchBarContainerHeight || '60px',
            },

            [theme.breakpoints.up('xl')]: {
                position: 'sticky',
                paddingTop: '0px !important',
                boxShadow: v2 ? 'none' : `8px 0px 0px ${theme.colors.lightGrey80}`,
                top: theme.elements.searchBarContainerHeight || '60px',
            },
            '& .MuiDrawer-paperAnchorLeft': {
                position: 'sticky',
                [theme.breakpoints.up('xl')]: {
                    position: 'relative',
                },
            },
            '&.collapsed': {
                boxShadow: v2 ? 'none' : `8px 0px 0px ${theme.colors.lightGrey80}`,
            },
        },
        rightAnchored: {
            top: theme.elements.searchBarContainerHeight || '60px',
            right: '0px',
            position: 'sticky',
            height: '100vh',
            boxShadow: v2 ? 'none' : `-8px 0px 0px ${theme.colors.lightGrey80}`,

            '& .MuiDrawer-paperAnchorRight': {
                height: `calc(100vh - ${theme.elements.searchBarContainerHeight || '60px'})`,
                position: 'relative',
                overflowY: 'hidden !important',
            },
        },
        drawerOpen: {
            width: theme.elements.filtersDrawerWidth || 'auto',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            '&.mentions': {
                width: theme.elements.mentionsDrawerWidth,
            },
            '&.filters': {
                width: theme.elements.filtersDrawerWidth,
            },
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.elements.collapsedDrawerWidth || '44px',
            position: 'relative',
            height: '100%',
            top: 0,
        },
    };
});
