//Uses built-in Intl methods for more flexibility + possible future i18n
//See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
//For more information
const numberFormatOptions = {
    number: {},
    compact: {
        notation: 'compact',
        compactDisplay: 'short',
    },
} as const;

export type NumberFormatType = keyof typeof numberFormatOptions;

export default function format(
    value: number,
    format: NumberFormatType = 'number',
    locale: string | string[] = 'en-US',
) {
    return Intl.NumberFormat(locale, numberFormatOptions[format]).format(value);
}
