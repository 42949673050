import { makeStyles } from 'tss-react/mui';

export default makeStyles<void, 'normal' | 'large' | 'medium'>()((theme, _params, classes) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',

            minHeight: theme.v2 ? undefined : '23px',
        },

        btn: {
            [`&.${classes.normal}`]: {
                padding: 0,
            },
            [`&.${classes.large}`]: {
                padding: theme.v2 ? undefined : '14px',
            },
            [`&.${classes.medium}`]: {
                padding: theme.v2 ? undefined : '9px',
            },
        },

        msg: {
            cursor: 'pointer',
        },

        icon: {
            [`&.${classes.medium}`]: {
                width: theme.v2 ? 17 : 14,
                height: theme.v2 ? 17 : 14,
            },

            [`&.${classes.large}`]: {
                width: theme.v2 ? 20 : undefined,
                height: theme.v2 ? 20 : undefined,
            },
        },

        //declaration needed to be available in above selectors
        //small: {},
        normal: {},
        medium: {},
        large: {},
    };
});
