import React, {forwardRef, useMemo} from 'react';
import isEmpty from 'lodash/isEmpty';

import CardLoadState from 'hsi/components/Card/CardLoadState';
import CardTitle from 'hsi/components/Card/CardTitle';
import CardTable from 'hsi/components/CardTable/v2';
import InfoPopupContent from 'hsi/components/InfoPopupContent';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useCardData from 'hsi/hooks/useCardData';
import useDrillInMentions from 'hsi/hooks/useDrillInMentions';
import useTableFields from './useTableFields';
import useGetDrillInData from './useGetDrillInData';

//Hooks
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
import {persistActiveQuery} from 'hsi/actions/queryActions';
import {useAppDispatch, useAppSelector} from 'hsi/hooks/useRedux';
import {selectCardTableSortPersist} from 'hsi/selectors';

//Types
import {CardTypeProps} from 'hsi/types/cards';
import {SortDirectionType} from 'hsi/components/CardTable/v2/useSort';

//Other
import {T} from 'hsi/i18n';

const type = 'tophashtags';
const defaultSort = 'value'; //i.e. total;
type sortKeyType = "value" | "id" | "reachEstimate" | "impressions" | "name" | "retweets" | "tweets"

//The component
const TopHashtag = forwardRef<HTMLDivElement, CardTypeProps>(({title, ...props}, ref) => {
    const dispatch = useAppDispatch();
    const {
        links: {dashboardInfoTopHashtagsCTA: popupCTA},
    } = useConfig();

    const drillInMentions = useDrillInMentions();
    const getDrillInData = useGetDrillInData();
    const isSavedSearch = useIsSavedSearch();

    //Redux
    const {data, loading, loaded, error, loadData, isCardSelected} = useCardData({
        type,
        dataSelector: (state) => state.chart[type],
    });

    const hasData = !isEmpty(data);
    const fields = useTableFields(data);

    //Calculated values
    const popup = useMemo(
        () => (
            <InfoPopupContent
                copy={T(
                    `cards.${type}.info.${isSavedSearch ? 'savedSearchCopy' : 'quickSearchCopy'}`,
                )}
                ctaLabel={T('cards.infoCTALabel')}
                ctaUrl={popupCTA}
                title={T(`cards.${type}.info.title`)}
            />
        ),
        [popupCTA, isSavedSearch],
    );

    //Callbacks
    const onSort = ({sortKeyChanged}: {sortKeyChanged: boolean}) => {
        if (sortKeyChanged) {
            loadData();
        }
        dispatch(persistActiveQuery());
    };

    const {sortDir, sortKey = defaultSort}: {sortDir: SortDirectionType, sortKey: string } = useAppSelector(selectCardTableSortPersist(type));

    return (
        <CardLoadState
            title={
                <CardTitle title={title} type={type} tooltipComponent={popup} hasData={hasData} />
            }
            error={error}
            hasData={hasData}
            loading={loading}
            loaded={loaded}
            loadData={loadData}
            selected={isCardSelected}
            ref={ref}
            data-testid={type}
            type={type}
            {...props}
        >
            <CardTable
                fields={fields}
                items={data!}
                type={type}
                paginate={true}
                defaultSort={sortKey as sortKeyType}
                defaultSortDir={sortDir}
                onSort={onSort}
                styleProps={{
                    rowHeight: '58px',
                }}
                onClickRow={(d) => drillInMentions(getDrillInData(d))}
            />
        </CardLoadState>
    );
});

export default forwardRef<HTMLDivElement, CardTypeProps>(function TopHashTags(props, ref) {
    return <TopHashtag {...props} ref={ref} />;
});
