import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme>((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: '8px',
    },
    index: {
        color: theme.elements.topHashtagsCard.indexColor,
    },
    hashtagsDetails: {
        overflow: 'hidden',
    },
    hashtagsName: {
        ...theme.mixin.truncate,
    },
}));