import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

//Components
import CardLoadState from 'hsi/components/Card/CardLoadState';
import TopTenTable from 'hsi/components/TopTenTable';
import UniqAuthorsCTA, {useSplitColumnsStyles} from 'hsi/components/UniqAuthorsCTA';

//Hooks
import useQueryContext from 'hsi/hooks/useQueryContext';
import useIsCardInteractivityDisabled from 'hsi/hooks/useIsCardInteractivityDisabled';

//The component
export default React.forwardRef(function TopTenTableCard(
    {
        config,
        data,
        error,
        loadData,
        loaded,
        loading,
        notAvailablePerDataLimitation = false,
        title,
        type,
        onRowClick,
        ...props
    },
    ref,
) {
    const {columns} = useSplitColumnsStyles();

    const isCardInteractivityDisabled = useIsCardInteractivityDisabled();

    //Redux
    const userIsViewOnly = useSelector((state) => state.user.isViewOnly);
    const {isSavedSearch} = useQueryContext();

    //Calculated Values
    const hasData = useMemo(() => config.hasData(data), [config, data]);

    const content = useMemo(() => {
        if (!hasData) {
            return null;
        }

        //item object shape: {name, total, authorTotal, authorPercent, authorSeries:[](sparkline)}
        const showUniqueAuthorCTA =
            config.showUniqueAuthorCTA(data, isSavedSearch) && !userIsViewOnly;

        const topTenItems = config.formatData(data);

        return (
            <div className={columns}>
                <div>
                    <TopTenTable
                        items={topTenItems}
                        onRowClick={isCardInteractivityDisabled ? undefined : onRowClick}
                        itemLabel={config.label}
                        authorTotalTooltip={config.authorTotalTooltip}
                        sparklineTooltip={config.sparklineTooltip}
                        showMentions={config.showMentions(data, isSavedSearch)}
                        showUniqueAuthors={config.showUniqueAuthors(data, isSavedSearch)}
                        showSparkline={config.showSparkline(data, isSavedSearch)}
                        isCardInteractivityDisabled={isCardInteractivityDisabled}
                    />
                </div>
                {showUniqueAuthorCTA && <UniqAuthorsCTA type={type} />}
            </div>
        );
    }, [
        columns,
        config,
        data,
        hasData,
        isSavedSearch,
        type,
        userIsViewOnly,
        onRowClick,
        isCardInteractivityDisabled,
    ]);

    //Render
    return (
        <CardLoadState
            {...props}
            title={title}
            error={error}
            loading={loading}
            loaded={loaded}
            hasData={hasData}
            notAvailablePerDataLimitation={notAvailablePerDataLimitation}
            loadData={loadData}
            ref={ref}
            type={type}
        >
            {content}
        </CardLoadState>
    );
});
