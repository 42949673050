//TODO this is being replaced by methods located in utils/number - if equivalent methods exist there, please prefer them

import numeral from 'numeral';

const formatBigInt = (num: number, threshold = 1000) => {
    const n = numeral(num);
    if (num < threshold) {
        return n.format('0,0');
    } else {
        let formatted = n.format('0.0a');
        if (formatted.split('.')[0].length > 1) {
            return n.format('0a');
        } else {
            return formatted.replace('.0', '');
        }
    }
};

const formatBigIntFull = (num: number) => numeral(num).format('0,0');
const formatPercent = (num: number) => numeral(num).format('0%');
const formatPercentWithLowerBound = (num: number) => {
    if (num === 0) return '0%';
    const percentage: string = numeral(num).format('0%');
    if (percentage === '0%') return '< 1%';
    else return percentage;
};
const formatTableSortNumber = (val: any) => {
    const num = +val;

    return isNaN(num) ? 0 : num;
};

export {
    formatBigInt,
    formatBigIntFull,
    formatPercent,
    formatTableSortNumber,
    formatPercentWithLowerBound,
};
