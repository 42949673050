//TODO some cards come pre-sorted by the API, so sorting here is un-necessary

import {useState, useMemo, useCallback} from 'react';
import {DateTime} from 'luxon';
import {CardTableField} from '.';

export type SortDirectionType = 'desc' | 'asc';
export type SortedItem = {__sortindex__: number};

type OnSortChangedArg<T> = {
    sortDir: SortDirectionType;
    sortKey: keyof T;
};

type UseSortArgs<T> = {
    items: T[];
    fields: CardTableField<T>[];
    onSortChanged?: (arg: OnSortChangedArg<T>) => void;
    defaultSortKey: keyof T;
    defaultSortDir?: SortDirectionType;
    initialSortKey: keyof T;
    initialSortDir?: SortDirectionType;
};

const useSort = <T>({
    items,
    fields,
    onSortChanged,
    defaultSortKey,
    defaultSortDir,
    initialSortKey,
    initialSortDir,
}: UseSortArgs<T>) => {
    initialSortKey = initialSortKey || defaultSortKey;

    const getInitialSortDir = useCallback(
        (fromSortKey?: keyof T) =>
            initialSortDir ||
            defaultSortDir ||
            getDefaultSortDirByValue(items?.[0]?.[fromSortKey || initialSortKey]),
        [defaultSortDir, initialSortDir, initialSortKey, items],
    );

    const [sortKey, setSortKey] = useState(initialSortKey);
    const [sortDir, setSortDir] = useState(() => getInitialSortDir());

    const fieldsObj = useMemo(
        () =>
            fields.reduce((obj, item) => {
                obj[item.id as keyof T] = item;
                return obj;
            }, {} as Record<keyof T, CardTableField<T>>),
        [fields],
    );

    if (!fieldsObj[sortKey]) {
        setSortKey(initialSortKey);
        setSortDir(getInitialSortDir());
    }

    const sortedItems = useMemo(
        () =>
            [...(Array.isArray(items) && !!items?.length ? items : [])]
                .sort((a, b) => doSort(a, b, sortKey, sortDir, fieldsObj))
                .map((d, i, ls) => ({...d, __sortindex__: getSortIndex(i, ls.length, sortDir)})),
        [items, sortKey, sortDir, fieldsObj],
    );

    const onSort = (nextSortKey: keyof T) => {
        const currentSortDir = sortKey !== nextSortKey ? getInitialSortDir(nextSortKey) : sortDir
        const nextSortDir = toggleSortDir(currentSortDir);

        setSortDir(nextSortDir);
        setSortKey(nextSortKey);

        !!onSortChanged &&
            onSortChanged({
                sortDir: nextSortDir,
                sortKey: nextSortKey,
            });
    };

    return {sortedItems, sortKey, sortDir, onSort};
};

const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

const doSort = <T>(
    a: T,
    b: T,
    sortKey: keyof T,
    sortDir: SortDirectionType,
    fieldsObj: Record<keyof T, CardTableField<T>>,
) => {
    const {format, sortFormat} = fieldsObj[sortKey];
    const _a = a[sortKey];
    const _b = b[sortKey];

    if (_a && typeof _a === 'string') {
        return collator.compare(_a, _b as string) * (sortDir === 'asc' ? 1 : -1);
    }

    let formatA: string | number = '';
    let formatB: string | number = '';

    if (sortFormat) {
        formatA = sortFormat(_a, a);
        formatB = sortFormat(_b, b);
    } else if (typeof (_a !== 'string') && format) {
        formatA = String(format(_a, a)).toLowerCase();
        formatB = String(format(_b, b)).toLowerCase();
    }

    //TODO If values are strings, should probably use a natsort algorithm?

    if (sortDir === 'asc') {
        if (formatA < formatB) return -1;
        if (formatA > formatB) return 1;
        return 0;
    } else {
        if (formatA > formatB) return -1;
        if (formatA < formatB) return 1;
        return 0;
    }
};

const defaultSortDirByType = {
    number: 'desc',
    string: 'asc',
    date: 'desc',
} as const;

const getDefaultSortDirByValue = (value?: any): SortDirectionType => {
    if (!value) return 'desc';
    const type = DateTime.fromISO(value).isValid ? 'date' : typeof value;
    return defaultSortDirByType[type as keyof typeof defaultSortDirByType] || 'desc';
};

const getSortIndex = (index: number, total: number, sortDir: SortDirectionType) =>
    sortDir === 'desc' ? index : total - 1 - index;
const toggleSortDir = (sortDir: SortDirectionType) => (sortDir === 'asc' ? 'desc' : 'asc');
export const getArrowDir = (sortDir: SortDirectionType) => (sortDir === 'desc' ? 'down' : 'up');

export default useSort;
