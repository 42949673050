import React, {useCallback, useRef, useState} from 'react';
import {geoEquirectangular} from 'd3-geo';
import find from 'lodash/find';
import {ComposableMap, Geographies, Geography} from 'react-simple-maps';
import {feature as topojsonFeature} from 'topojson';

//Components
import ColorScale from 'hsi/classes/ColorScale';
import Tooltip from './Tooltip';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useElementSize from 'hsi/hooks/useElementSize';

//Other
import useStyles from './styles';
import {CT} from 'hsi/i18n';

//Consts
import {worldTopo} from './mapData';
const LEGEND_HEIGHT = 34;
const LEGEND_MARGIN_TOP = 20;
const elementSizeOptions = {width: true, height: true, x: false, y: false};

//The component
export default function ChoroplethMap({data, onItemClick}) {
    const mainRef = useRef();
    const [ref, {width, height} = {}] = useElementSize(mainRef, elementSizeOptions);
    const classes = useStyles();
    const {themeColors} = useConfig();

    const [item, setItem] = useState(null);
    const [x, setX] = useState(null);
    const [y, setY] = useState(null);

    const handleMouseEnter = useCallback((event, country) => {
        const {target} = event;
        if (target) {
            setItem(country);

            const rect = target.getBoundingClientRect();
            const mainRect = mainRef.current.getBoundingClientRect();

            if (country?.id === 'USA') {
                setX(rect.left - mainRect.left + rect.width / 5.3);
                setY(rect.top - mainRect.top + rect.height / 1.7);
            } else if (country?.id === 'FRA') {
                setX(rect.left - mainRect.left + rect.width / 1.142);
                setY(rect.top - mainRect.top + rect.height / 13);
            } else {
                setX(rect.left - mainRect.left + rect.width / 2);
                setY(rect.top - mainRect.top + rect.height / 2.5);
            }
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        setItem(null);
        setX(null);
        setY(null);
    }, []);

    const getProjection = useCallback((width, height) => {
        // PUT HERE more projections in the future when needed (albers for usa...)
        const feature = topojsonFeature(worldTopo, worldTopo.objects['WORLD-countries-110m']);
        return geoEquirectangular().rotate([-11, 0]).fitSize([width, height], feature);
    }, []);

    if (!data) return <span data-testid="noMapData" />;

    const COLORS = themeColors.choroplethMapColors.render;
    const DEFAULT_COLOR = themeColors.colorScaleDefault;
    const HOVER_COLOR = themeColors.choroplethMapColors.hover;
    const PRESSED_COLOR = themeColors.choroplethMapColors.pressed;
    const colorScale = new ColorScale(
        data.map((d) => d.total),
        COLORS,
        DEFAULT_COLOR,
    );
    const buckets = colorScale.getBuckets();
    const mapHeight = height - LEGEND_HEIGHT - LEGEND_MARGIN_TOP - 2;

    return (
        <div className={classes.map} ref={ref}>
            {!!width && (
                <ComposableMap
                    projection={getProjection(width, mapHeight)}
                    width={width}
                    height={mapHeight}
                    style={{
                        width: '100%',
                        height: 'auto',
                    }}
                >
                    <Geographies geography={worldTopo}>
                        {({geographies}) =>
                            geographies &&
                            geographies
                                .filter(({id}) => id !== 'ATA')
                                .map((geography, i) => {
                                    const country = find(data, ({id}) => id === geography.id);
                                    const value = country?.total || 0;
                                    const color = colorScale.getColor(value);

                                    return (
                                        <Geography
                                            key={i}
                                            data-testid={geography.id}
                                            geography={geography}
                                            style={{
                                                default: {
                                                    fill: color,
                                                    stroke: 'white',
                                                    strokeWidth: 0.5,
                                                    outline: 'none',
                                                },
                                                hover: {
                                                    fill: HOVER_COLOR,
                                                    stroke: 'white',
                                                    strokeWidth: 0.5,
                                                    outline: 'none',
                                                    cursor: 'pointer',
                                                },
                                                pressed: {
                                                    fill: PRESSED_COLOR,
                                                    stroke: 'white',
                                                    strokeWidth: 0.5,
                                                    outline: 'none',
                                                },
                                            }}
                                            onMouseEnter={(event) =>
                                                handleMouseEnter(event, country)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                            onClick={() => (country ? onItemClick(country) : null)}
                                        />
                                    );
                                })
                        }
                    </Geographies>
                </ComposableMap>
            )}

            <Tooltip item={item} x={x} y={y} />

            <div
                data-testid="mapBuckets"
                className={classes.legend}
                style={{marginTop: LEGEND_MARGIN_TOP, height: LEGEND_HEIGHT}}
            >
                {buckets.map(({color, label}, i) => (
                    <div className={classes.bucketItem} key={i}>
                        <div
                            className={classes.bucketColorSample}
                            style={{backgroundColor: color}}
                        />
                        <div className={classes.bucketLabel} data-testid="mapBucket">
                            {CT(label)}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
