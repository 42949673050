import makeStyles from '@mui/styles/makeStyles';
import {Theme} from 'hsi/types/theme';

export default makeStyles<Theme, {hasAvatar: boolean}>((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: ({hasAvatar}) => (hasAvatar ? 0 : '8px'),
    },
    index: {
        color: theme.elements.topAuthorsCard.indexColor,
    },
    favicon: {
        transform: `scale(${1 / 0.7})`,
    },
    avatar: {
        backgroundColor: '#eee',
        width: 40,
        transform: 'scale(0.7)',
        '.printMedia &': {
            marginRight: '10px',
        },
    },
    authorDetails: {
        overflow: 'hidden',
    },
    authorName: {
        ...theme.mixin.truncate,
    },
    location: {
        color: theme.elements.topAuthorsCard.locationColor,
        ...theme.mixin.truncate,
    },
}));
