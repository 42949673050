import React, {useMemo} from 'react';

import NameCell from 'hsi/components/CardTable/v2/NameCell';
import {formatBigInt, formatTableSortNumber} from 'hsi/utils/formatNumber';

import tns from 'hsi/i18n/tns';
const t = tns('cards.totalVolumeBySearch');

const formatMetricLabel = (label) =>
    typeof label === 'string' ? t(`tableMetricsFields.${label.toLowerCase()}`) || label : '';

export const useTableParser = ({volumeBySearch, metrics, searchIds, searchesById}) => {
    const items = useMemo(
        () =>
            searchIds.map((id) => ({
                name: searchesById[id]?.name,
                ...metrics.reduce((res, metric) => {
                    res[metric.id] = volumeBySearch?.[id]?.[metric.id];
                    return res;
                }, {}),
            })),
        [metrics, searchIds, searchesById, volumeBySearch],
    );

    const fields = useMemo(
        () => [
            {
                id: 'name',
                label: t('tableNameField'),
                width: '1.2fr',
                format: (name) => <NameCell name={name} />,
            },
            ...metrics.map(({id, label}) => ({
                id,
                sortFormat: formatTableSortNumber,
                label: formatMetricLabel(label),
                format: (value) => formatBigInt(value),
            })),
        ],
        [metrics],
    );

    return {fields, items};
};
