import {
    Children,
    PropsWithChildren,
    ReactNode,
    cloneElement,
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import useElementSize from 'hsi/hooks/useElementSize';
import {mergeRefs} from 'hsi/utils/react';
import {checkOverflow} from 'hsi/utils/html/checkOverflow';
import Tooltip from '..';
import {Placement} from '@floating-ui/react-dom-interactions';

type OverflowTooltipProps = PropsWithChildren<{
    overflowChildSelector?: string;
    offset?: number;
    active?: boolean;
    tooltip?: ReactNode;
    portal?: boolean;
    /**
     * Set to true to force the tooltip open, or false to force closed
     */
    open?: boolean;
    placement?: Placement;
    /**
     * Used to allow the tooltip to be positioned relative to a
     * different element than the one that is being monitored for overflowing
     *
     * @param element The element being checked for overflowing
     * @returns The element to position the tooltip to
     */
    getPositionElement?: (element: Element) => Element | null; //TODO better typing maybe?
}>; // & Omit<Parameters<typeof Tooltip<HTMLElement>>[0], ''>;

export default forwardRef<HTMLElement, OverflowTooltipProps>(function OverflowTooltip(
    {children, overflowChildSelector, offset, tooltip, active = true, ...rest},
    ref,
) {
    const [isOverflown, setIsOverflown] = useState(false);

    const [setNodeCallback, {width} = {width: undefined}, element] = useElementSize<HTMLElement>(
        null,
        {width: true},
    );

    const child = useMemo(() => {
        const child = Children.only(children) as any;

        return cloneElement(child, {
            ref: mergeRefs(setNodeCallback, child.ref, ref),
        });
    }, [children, ref, setNodeCallback]);

    //Callbacks
    const doOverflowCheck = useCallback(() => {
        if (!element) {
            return;
        }

        const elem = overflowChildSelector
            ? element.querySelector<HTMLElement>(overflowChildSelector)
            : element;

        setIsOverflown(elem ? checkOverflow(elem, offset) : false);

        return () => setIsOverflown(false);
    }, [element, overflowChildSelector, offset]);

    //Side effects
    useEffect(() => {
        doOverflowCheck();

        return () => setIsOverflown(false);
        // Check if you can add deps without issue
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    return (
        <>
            {active && isOverflown ? (
                <Tooltip noAria tooltip={tooltip || child} {...rest}>
                    {child}
                </Tooltip>
            ) : (
                child
            )}
        </>
    );
});
