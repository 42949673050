//TODO remove old messages?

import {
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

function defaultAnnounceStub() {
    console.log('ARIA announce method not defined - messages will not be announced');
}

const ARIAAnnounceContext =
    createContext<(message: string, priority?: 'polite' | 'assertive') => void>(
        defaultAnnounceStub,
    );

export default function useAriaAnnounce() {
    return useContext(ARIAAnnounceContext)!;
}

export type AriaAnnounceProviderProps = PropsWithChildren<{
    disabled?: boolean;
}>;

export function AriaAnnounceProvider({children, disabled}: AriaAnnounceProviderProps) {
    const [messages, setMessages] = useState<
        {id: string; time: number; message: string; priority: 'polite' | 'assertive'}[]
    >([]);

    const announce = useCallback(
        (message: string, priority: 'polite' | 'assertive' = 'polite') => {
            !disabled &&
                setMessages((messages) => [
                    ...messages,
                    {id: 'm' + messages.length, time: Date.now(), message, priority},
                ]);
        },
        [disabled],
    );

    //If this is disabled, clear all messages
    useEffect(() => {
        if (disabled) {
            setMessages([]);
        }
    }, [disabled]);

    return (
        <ARIAAnnounceContext.Provider value={announce}>
            {children}
            <div className="offscreen">
                {messages.map(({id, message, priority}) => (
                    <div key={id} aria-live={priority}>
                        {message}
                    </div>
                ))}
            </div>
        </ARIAAnnounceContext.Provider>
    );
}
