import { makeStyles } from 'tss-react/mui';

export default makeStyles<
    void,
    'light' | 'dark' | 'top' | 'right' | 'bottom' | 'left' | 'align-center'
>()((theme, _params, classes) => {
    const arrowLength = `7px`;
    const arrowBreadth = `7px`;

    return {
        top: {},
        bottom: {},
        left: {},
        right: {},

        root: {
            '--tooltip-background': theme.colors.textDefault,
            '--tooltip-distance': 0,
            background: 'var(--tooltip-background)',
            color: '#FFF',
            borderRadius: 5,
            maxWidth: '300px',
            textAlign: 'center',
            lineHeight: 1.4,

            padding: '3px 9px',

            fontSize: 13,
            fontWeight: theme.font.semiBold,
            zIndex: 999999999999,
            whiteSpace: 'normal',

            [`&.${classes.light}`]: {
                '--tooltip-background': '#FFF',
                color: theme.colors.textDefault,
                boxShadow:
                    '0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15)', //'0 0 6px 0px rgba(0,0,0,0.25)',
            },

            '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                background: 'red',
                opacity: 0,
            },

            [`&.${classes.top}:after`]: {
                left: -5,
                right: -5,
                top: '100%',
                height: 'calc(var(--tooltip-distance) + 5px)',
            },

            [`&.${classes.right}:after`]: {
                top: -5,
                bottom: -5,
                right: '100%',
                width: 'calc(var(--tooltip-distance) + 5px)',
            },

            [`&.${classes.bottom}:after`]: {
                left: -5,
                right: -5,
                bottom: '100%',
                height: 'calc(var(--tooltip-distance) + 5px)',
            },

            [`&.${classes.left}:after`]: {
                top: -5,
                bottom: -5,
                left: '100%',
                width: 'calc(var(--tooltip-distance) + 5px)',
            },
        },

        arrow: {
            position: 'absolute',
            borderStyle: 'solid',
            padding: 0,
            [`&.${classes.top}`]: {
                top: '100%',
                borderWidth: `${arrowLength} ${arrowBreadth} 0 ${arrowBreadth}`,
                borderColor: `var(--tooltip-background) transparent transparent transparent`,
            },

            [`&.${classes.bottom}`]: {
                bottom: '100%',
                borderWidth: `0 ${arrowBreadth} ${arrowLength} ${arrowBreadth}`,
                borderColor: `transparent transparent var(--tooltip-background) transparent`,
            },

            [`&.${classes.left}`]: {
                left: '100%',
                borderWidth: `${arrowLength} 0 ${arrowBreadth} ${arrowBreadth}`,
                borderColor: `transparent transparent transparent var(--tooltip-background)`,
            },

            [`&.${classes.right}`]: {
                right: '100%',
                borderWidth: `${arrowBreadth} ${arrowLength} ${arrowBreadth} 0`,
                borderColor: `transparent var(--tooltip-background) transparent transparent`,
            },
        },

        mouseMoveCatcher: {
            display: 'block',
            background: 'red',
            position: 'fixed',
            zIndex: 0,
            opacity: 0,
        },

        morePadding: {
            padding: '16px 24px',
        },

        'align-left': {
            textAlign: 'left',
        },

        'align-right': {
            textAlign: 'right',
        },

        dialog: {},

        //Themes
        light: {},
        dark: {},
        'align-center': {},
    };
});
