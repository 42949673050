import React, {useRef, useState, useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';

import {makeStyles} from 'tss-react/mui';

import IconRouter from 'hsi/components/IconRouter';
import Tooltip from 'hsi/components/Tooltip';
import {setMentionsFull} from 'hsi/slices/mentions';

import {T} from 'hsi/i18n';

import styles from './styles.js';

const useStyles = makeStyles()(styles);

const STATES = {
    OPEN: 'open',
    OPENING: 'opening',
    CLOSING: 'closing',
    CLOSED: 'closed',
};

const MentionsDrawer = ({children, isOpen, isFull, isSelectMode, toggleIsOpen}) => {
    const dispatch = useDispatch();
    const rootRef = useRef();
    const animationsRef = useRef();
    const {classes, cx} = useStyles();
    const [state, setState] = useState(null);

    const isVisible = state === STATES.OPEN || state === STATES.OPENING || state === STATES.CLOSING;
    const theme = useTheme();

    const cancelAnimation = useCallback(() => {
        //This seems to be a bug with eslint understanding the ?. operator
        //eslint-disable-next-line no-unused-expressions
        animationsRef.current?.forEach((animation) => animation.cancel());
        animationsRef.current = null;
    }, []);

    const doOpenAnimation = useCallback(() => {
        const elem = rootRef.current;

        if (!elem) {
            return;
        }

        setState(STATES.OPENING);

        const drawerAnimation = elem.animate(
            {
                marginRight: 0,
            },
            {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
                fill: 'forwards',
            },
        );

        drawerAnimation.onfinish = () => setState(STATES.OPEN);
        animationsRef.current = [drawerAnimation];
    }, [setState, theme.transitions.duration.enteringScreen, theme.transitions.easing.sharp]);

    const doCloseAnimation = useCallback(() => {
        const elem = rootRef.current;

        if (!elem) {
            return;
        }

        setState(STATES.CLOSING);

        const closedMargingRight = getComputedStyle(elem).getPropertyValue('--closedOffset');

        const drawerAnimation = elem.animate(
            {
                marginRight: closedMargingRight,
            },
            {
                easing: 'linear',
                duration: theme.transitions.duration.leavingScreen,
                fill: 'forwards',
            },
        );

        drawerAnimation.onfinish = () => setState(STATES.CLOSED);
        animationsRef.current = [drawerAnimation];
    }, [theme.transitions.duration.leavingScreen, setState]);

    const doOpen = useCallback(() => {
        switch (state) {
            case STATES.OPEN:
                return;
            case STATES.CLOSING:
                cancelAnimation();
                doOpenAnimation();
                break;
            case STATES.CLOSED:
                doOpenAnimation();
                break;
            case STATES.OPENING:
            default:
                return;
        }
    }, [state, cancelAnimation, doOpenAnimation]);

    const doClose = useCallback(() => {
        switch (state) {
            case STATES.OPEN:
                doCloseAnimation();
                break;
            case STATES.CLOSING:
                return;
            case STATES.CLOSED:
                return;
            case STATES.OPENING:
                cancelAnimation();
                doCloseAnimation();
                break;
            default:
                return;
        }
    }, [state, cancelAnimation, doCloseAnimation]);

    useEffect(
        () => {
            if (state === null) {
                //on initial load, set state based on isOpen value
                setState(isOpen ? STATES.OPEN : STATES.CLOSED);

                if (!isOpen) {
                    rootRef.current.style.marginRight = getComputedStyle(
                        rootRef.current,
                    ).getPropertyValue('--closedOffset');
                }
            } else {
                isOpen ? doOpen() : doClose();
            }
        },
        //This warning is simply wrong, useEffect should not exhaustively list
        //all it's dependencies, just the ones that trigger side effects when they change
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [isOpen],
    );

    useEffect(() => {
        //Do tidyup on unload
        return () => {
            //Do not persist fullsceen behaviour
            dispatch(setMentionsFull(false));
        };
    }, [dispatch]);

    return (
        <div ref={rootRef} className={cx(classes.root, isFull && classes.rootFull)}>
            <div className={cx(classes.inner, isFull && classes.innerFull)}>
                <div
                    onClick={toggleIsOpen}
                    className={cx(classes.openBtn, state, isSelectMode && classes.hide)}
                    data-testid="showMentionsPanel"
                >
                    <Tooltip noAria disable={isOpen} tooltip={isOpen ? T('mentionsDrawer.close') : T('mentionsDrawer.open')} portal>
                        <IconButton
                            aria-expanded={isOpen}
                            className={classes.openBtnIcon}
                            size="large"
                            aria-label={isOpen ? T('mentionsDrawer.close') : T('mentionsDrawer.open')}
                        >
                            <IconRouter
                                aria-hidden
                                name={isOpen ? 'hsi-double-arrow-right' : 'mentions'}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
                {isVisible && children}
            </div>
        </div>
    );
};

export default MentionsDrawer;
