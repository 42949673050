import React, {forwardRef} from 'react';

//Components
import TopMeasureBySearch from 'hsi/components/Cards/TopMeasureBySearch';
import NameCell from 'hsi/components/CardTable/v2/NameCell';
import SiteFavicon from 'hsi/components/SiteFavicon';

//Utils
import {drillInFilters} from 'hsi/utils/filters';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';

//Consts
const type = 'topsitesBySearch';
const dataSelector = (state) => state.chart[type];
const drillInData = ({name: domain}) => ({
    dates: null,
    filter: drillInFilters.domain(domain, true), //multiple search will always be saved search
    label: domain,
    tracking: {
        name: 'cardDrilledIn',
        type,
        value: domain,
    },
    type,
});
const nameField = {
    label: T(`cards.${type}.nameFieldLabel`),
    format: (domain) => <Domain domain={domain} />,
};

const Domain = ({domain}) => {
    const cl = useStyles();
    return (
        <div className={cl.siteDetails}>
            <SiteFavicon domain={domain} className={cl.favicon} />
            <NameCell name={domain} />
        </div>
    );
};

//The component
const TopSitesBySearch = forwardRef(function TopSitesBySearch(props, ref) {
    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={dataSelector}
            drillInData={drillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopSitesBySearch;
