import {PayloadAction} from '@reduxjs/toolkit';
import {WORD_CLOUD_DEFAULT, WORD_CLOUD_TYPES, TOTAL_VOLUME_TYPES} from 'hsi/constants/config';
import {createSlice} from '@reduxjs/toolkit';

import {CCFlagsType} from 'hsi/types/cc-flags';
import {TopAuthorsCardData, TopHashtagsCardData} from 'hsi/types/cards';
import {Breakdowns} from 'hsi/types/filters';

type volumeType = {
    id: string;
    label: string;
};

type CardTableSortType<T = any> = {
    sortDir: 'desc' | 'asc';
    sortKey: T extends any ? string : keyof T;
};

export type CardPersistStateType = {
    benchmark: {
        breakdown: string;
    };
    emotionHistory: {
        breakdown: string;
    };
    mentionsHistory: {
        breakdown: string;
        aggregate: string;
    };
    netSentimentHistory: {
        breakdown: string;
    };
    sentimentHistory: {
        breakdown: string;
    };
    topauthors: CardTableSortType<keyof TopAuthorsCardData>;
    tophashtags: CardTableSortType<keyof TopHashtagsCardData>;
    toptopicsBySearch: {
        types: string[];
        orderBy: string;
        color: string;
        size: number;
    };
    totalVolume: {
        metric1: volumeType;
        metric2: volumeType;
        metric3: volumeType;
        metric4: volumeType;
    };
    totalVolumeBySearch: {
        metric1: volumeType;
        metric2: volumeType;
        metric3: volumeType;
        metric4: volumeType;
    };
    wordCloud: {
        types: string[];
        orderBy: string;
        color: string;
        size: number;
    };
};

export type PersistedCardType = keyof CardPersistStateType;

export const initialState = {
    benchmark: {
        breakdown: 'pageTypes',
    },
    emotionHistory: {
        breakdown: 'days',
    },
    mentionsHistory: {
        breakdown: 'days',
        aggregate: 'volume',
    },
    netSentimentHistory: {
        breakdown: 'days',
    },
    sentimentHistory: {
        breakdown: 'days',
    },
    topauthors: {sortDir: 'desc', sortKey: 'value'},
    tophashtags: {sortDir: 'desc', sortKey: 'value'},
    toptopicsBySearch: {
        types: WORD_CLOUD_TYPES.map((t) => t.id),
        orderBy: 'volume',
        color: 'topicType',
        size: WORD_CLOUD_DEFAULT,
    },
    totalVolume: {
        metric1: TOTAL_VOLUME_TYPES[0],
        metric2: TOTAL_VOLUME_TYPES[1],
        metric3: TOTAL_VOLUME_TYPES[2],
        metric4: TOTAL_VOLUME_TYPES[3],
    },
    totalVolumeBySearch: {
        metric1: TOTAL_VOLUME_TYPES[0],
        metric2: TOTAL_VOLUME_TYPES[1],
        metric3: TOTAL_VOLUME_TYPES[2],
        metric4: TOTAL_VOLUME_TYPES[3],
    },
    wordCloud: {
        types: WORD_CLOUD_TYPES.map((t) => t.id),
        orderBy: 'volume',
        color: 'topicType',
        size: WORD_CLOUD_DEFAULT,
    },
};

type CardInnerPayloadType = PayloadAction<{type: PersistedCardType; value: CardPersistStateType[PersistedCardType]}>;

type updateBreakdownPayloadType = PayloadAction<{
    type: keyof Breakdowns;
    value: CardPersistStateType[keyof Breakdowns]['breakdown'];
}>;

type updateAggregatePayloadType = PayloadAction<{
    type: 'mentionsHistory';
    value: CardPersistStateType['mentionsHistory']['aggregate'];
}>;

type updateCardPersistSortType = PayloadAction<{
    type: 'topauthors' | 'tophashtags';
    value:
        | CardTableSortType<keyof TopAuthorsCardData>
        | CardTableSortType<keyof TopHashtagsCardData>;
}>;

type LoadPersistCardAction = PayloadAction<{
    cardTable: {
        topauthors: CardTableSortType<keyof TopAuthorsCardData>;
        tophashtags: CardTableSortType<keyof TopHashtagsCardData>;
    };
    filterCard: any;
    flags: CCFlagsType;
}>;

const slice = createSlice({
    name: 'cardPersistState',
    initialState,
    reducers: {
        //breakdown
        updateBreakdown: (state, {payload: {type, value}}: updateBreakdownPayloadType) => {
            if (state[type]) {
                state[type].breakdown = value;
            } else {
                state = {
                    ...state,
                    [type]: {
                        ...state[type],
                        breakdown: value,
                    },
                };
            }
            return state;
        },
        updateAggregate: (state, {payload: {type, value}}: updateAggregatePayloadType) => {
            if (state[type]) {
                state[type].aggregate = value;
            } else {
                state = {
                    ...state,
                    [type]: {
                        ...state[type],
                        aggregate: value,
                    },
                };
            }
            return state;
        },
        updateCardsInnerState: (state, {payload: {type, value}}: CardInnerPayloadType) => {
            state = {
                ...state,
                [type]: value,
            };
            return state;
        },
        updateCardPersistSort: (state, {payload: {type, value}}: updateCardPersistSortType) => {
            state[type] = value;
            return state;
        },
        loadPersistCardPersistState: (
            state,
            {payload: {cardTable, filterCard, flags}}: LoadPersistCardAction,
        ) => {
            state = {...initialState}; // reinitialise
            if (flags?.hasCardOptionsPersistance) {
                state = {
                    ...state,
                    ...cardTable,
                    ...filterCard,
                };
            }
            return state;
        },
    },
});

export const {
    updateBreakdown,
    updateAggregate,
    updateCardsInnerState,
    updateCardPersistSort,
    loadPersistCardPersistState,
} = slice.actions;
export default slice.reducer;
