import filtersConfig from './filters';
import autosuggest from './autosuggest';

import _basePageTypes from './pageTypes';
import {AppConfigType} from 'hsi/types/config';
import {AuthenticationDataTypes, TargetedDataDataTypes} from 'hsi/types/dataManagement';
import {SelectDataSourceTypes} from 'hsi/containers/DataManagement/AddDataSourceDialog/SelectDataSource';

const config = {
    svgPath: null,
    appSource: 'BW', // Just so we don't have to deal with appSource being optional
    alerts: {
        defaultAlertThresholdPercentage: 200,

        // Each value is a time in minutes. Each value must have a corresponding
        // key in the language file, under the alert.newMentionFrequency
        alertNewMentionsFrequencies: [5, 15, 30, 60, 720, 1440, 10080],

        defaultNewMentionFrequency: 1440, //Must be a value from alertNewMentionsFrequencies

        //Which of the above frequency options also gives a time of day option
        alertMentionFrequenciesWithTime: [720, 1440, 10080],
        alertMentionFrequenciesTimeOffset: 6, //Which time should be the 'first' option

        defaultMentionAlertTime: 8, //default alert hour for frequencies with an hour option

        //Which of the above frequency options also gives a day of week option
        alertMentionFrequenciesWithDay: [10080],

        defaultDayOfWeek: 1, //default day of week value for frequencies that have a day of week option

        alertMentionFrequenciesWithCalculatedSecondTime: {
            720: (mentionAlertTime: number) => {
                if (mentionAlertTime === 0) {
                    return mentionAlertTime + 12 + 'pm';
                } else if (mentionAlertTime < 12) {
                    return mentionAlertTime + 'pm';
                } else if (mentionAlertTime > 12) {
                    return mentionAlertTime - 12 + 'am';
                } else {
                    return mentionAlertTime + 'am';
                }
            },
        },

        alertNumMentionOptions: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
        alertDefaultNumMentionsPerAlert: 10, //must be a value in above list

        noDefaultAlertEmail: false,
        saveBtnPriority: 'cta',
        closeBtnPriority: 'primary',
        nameLengthLimit: 200,
    },
    toggle: {
        defaultMode: 'checkbox', //checkbox|switch
    },
    links: {
        reportExample: '/fe-api/assets/report-example',
    },
    mentionsGrid: {
        masonry: {
            // How many columns are the items displayed in. Either a positive integer,
            // or a function which takes the current container width in pixels, and
            // returns a positive integer number of colums
            getNumColumns: (width: number) => {
                return Math.max(1, Math.floor(width / 350)); //350 = min mention width
            },
            gutters: [16, 16],
            //Options used for debouncing mention card repositioning.
            //A value of null means no debouncing. This is not recommended
            debounceOptions: {
                wait: 300, //time in milliseconds
                // leading = true mean the [wait] delay is AFTER each reposition
                // Leading = false means wait [delay] is BEFORE each reposition (each new call during the wait resets the wait)
                leading: false, //RECOMMENDED = false
            },
            //How long does the transition effect for repositioning take.
            //Any valid CSS time value, or null for no transition effect
            //It is strongly recommended to combine this option with debouncing,
            //and to pick a time less than or equal to the debouncing wait value
            transitionTime: '300ms',
            infiniteScrollThreshold: 1500,
        },
        // uniform: {
        //     // How many columns are the items displayed in. Either a positive integer,
        //     // or a function which takes the current container width in pixels, and
        //     // returns a positive integer number of colums
        //     getNumColumns: (width: number) => {
        //         return Math.max(1, Math.floor(width / 350)); //350 = min mention width
        //     },
        //     gutters: [16, 16],
        //     //Options used for debouncing mention card repositioning.
        //     //A value of null means no debouncing. This is not recommended
        //     debounceOptions: {
        //         wait: 300, //time in milliseconds
        //         // leading = true mean the [wait] delay is AFTER each reposition
        //         // Leading = false means wait [delay] is BEFORE each reposition (each new call during the wait resets the wait)
        //         leading: false, //RECOMMENDED = false
        //     },
        //     uniformMaxHeight: '330px',
        // },
    },
    searchResults: {
        // Specify what 'sections' (groups of cards) are available and in which order.
        // Do not use sections called '$pinned' or '$hidden', these have special meanings & are used internally
        // Note that each section needs to have a corresponding language entry,
        // e.g. mentions required a language entry for anchors.mentions
        // Also, sections with no cards will not be displayed
        cardSectionsOrder: [
            'mentions',
            'benchmark',
            'sentiment',
            'emotion',
            'wordCloud',
            'topMeasures',
            'demographics',
            'topicWheel',
        ],

        /** Card rules specifies which of the available cards are shown, in what
         * order, what their default height is (once loaded theu revert to auto
         * height), and which section they belong. Note that may cards have
         * requirements that need to be met for them to be visible.
         * E.g. totalMentions is only shown in quicksearches
         * */
        cardRules: [
            {
                name: 'totalMentions',
                section: 'mentions',
                requires: ({queryContext}) => !!queryContext?.isQuickSearch,
                getTitle: () => 'cards.totalMentions.title',
            },
            {
                name: 'totalVolume',
                section: 'mentions',
                requires: ({queryContext}) => !!queryContext?.isSavedSearch,
                getTitle: ({queryContext}) =>
                    queryContext?.isMultipleSearch
                        ? 'cards.totalVolume.multipleSearch.title'
                        : 'cards.totalVolume.title',
            },
            {
                name: 'totalVolumeBySearch',
                section: 'mentions',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.totalVolumeBySearch.title',
            },
            {
                name: 'mentionsHistory',
                section: 'mentions',
                requires: () => true,
                peaks: ({queryContext}) => !!queryContext?.isSavedSearch,
                getTitle: ({queryContext}) =>
                    queryContext?.isMultipleSearch
                        ? {
                              title: 'cards.mentionsHistoryBySearch.title',
                              breakdownType: 'cards.breakdownBy',
                              generalDimension: 'cards.mentionsHistory.generalDimension',
                              overDimension: 'cards.mentionsHistoryBySearch.overDimension',
                          }
                        : queryContext?.isSavedSearch
                        ? {
                              title: 'cards.mentionsHistory.title',
                              breakdownType: 'cards.breakdownBy',
                              generalDimension: 'cards.mentionsHistory.generalDimension',
                              overDimension: 'cards.mentionsHistory.overDimension',
                          }
                        : 'cards.mentionsHistory.title',
            },
            {
                name: 'heatmap',
                section: 'mentions',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.heatmap.title',
            },
            {
                name: 'shareOfVoice',
                section: 'mentions',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                peaks: () => false,
                getTitle: () => 'cards.shareOfVoice.title',
            },
            {
                name: 'wordCloud',
                section: 'wordCloud',
                requires: () => true,
                getTitle: ({queryContext}) =>
                    queryContext?.isMultipleSearch
                        ? 'cards.wordCloudBySearch.title'
                        : 'cards.wordCloud.title',
            },
            {
                name: 'toptopicsBySearch',
                section: 'wordCloud',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.toptopicsBySearch.title',
            },
            {
                name: 'benchmark',
                section: 'benchmark',
                requires: ({queryContext}) => !!queryContext?.isSingleSavedSearch,
                getTitle: () => ({
                    title: 'cards.benchmark.title',
                    breakdownType: 'cards.breakdownFor',
                }),
            },
            {
                name: 'benchmarkBySearch',
                section: 'benchmark',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.benchmarkBySearch.title',
            },
            {
                name: 'sentimentVolume',
                section: 'sentiment',
                requires: () => true,
                getTitle: ({queryContext}) =>
                    queryContext?.isMultipleSearch
                        ? 'cards.sentimentVolume.multipleSearch.title'
                        : 'cards.sentimentVolume.title',
            },
            {
                name: 'sentimentVolumeBySearch',
                section: 'sentiment',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.sentimentVolumeBySearch.title',
            },
            {
                name: 'sentimentHistory',
                section: 'sentiment',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                peaks: ({queryContext}) => !!queryContext?.isSavedSearch,
                getTitle: ({queryContext}) =>
                    queryContext?.isSavedSearch
                        ? {
                              title: 'cards.sentimentHistory.title',
                              breakdownType: 'cards.breakdownBy',
                          }
                        : 'cards.sentimentHistory.title',
            },
            {
                name: 'netSentimentHistory',
                section: 'sentiment',
                requires: ({flags, queryContext}) => {
                    return (!!flags?.netSentimentCardEnabled && !!queryContext?.isSavedSearch)
                },
                getTitle: ({queryContext}) =>
                    !!queryContext?.isMultipleSearch
                        ? {
                              title: 'cards.netSentimentHistory.multipleSearch.title',
                              breakdownType: 'cards.breakdownBy',
                          }
                        : {
                              title: 'cards.netSentimentHistory.title',
                              breakdownType: 'cards.breakdownBy',
                          },
            },
            {
                name: 'emotionVolume',
                section: 'emotion',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.emotionVolume.title',
            },
            {
                name: 'emotionHistory',
                section: 'emotion',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                peaks: ({queryContext}) => !!queryContext?.isSavedSearch,
                getTitle: ({queryContext}) =>
                    queryContext?.isSavedSearch
                        ? {
                              title: 'cards.emotionHistory.title',
                              breakdownType: 'cards.breakdownBy',
                          }
                        : 'cards.emotionHistory.title',
            },
            {
                name: 'pageTypeBySearch',
                section: 'topMeasures',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.pageTypeBySearch.title',
            },
            {
                name: 'topSharedURLs',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topSharedURLs.title',
            },
            {
                name: 'topsites',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topsites.title',
            },
            {
                name: 'topsitesBySearch',
                section: 'topMeasures',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topsitesBySearch.title',
            },
            {
                name: 'topauthors',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topauthors.title',
            },
            {
                name: 'topauthorsBySearch',
                section: 'topMeasures',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topauthorsBySearch.title',
            },
            {
                name: 'tophashtags',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.tophashtags.title',
            },
            {
                name: 'tophashtagsBySearch',
                section: 'topMeasures',
                requires: ({queryContext}) => !!queryContext?.isMultipleSearch,
                getTitle: () => 'cards.tophashtagsBySearch.title',
            },
            {
                name: 'topinterests',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                additionalCall: 'interestTotals',
                additionalCallRequires: ({queryContext}) => !!queryContext?.isSingleSavedSearch,
                getTitle: () => 'cards.topinterests.title',
            },
            {
                name: 'toplanguages',
                section: 'topMeasures',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                additionalCall: 'languageAuthors',
                additionalCallRequires: ({queryContext}) => !!queryContext?.isSingleSavedSearch,
                getTitle: () => 'cards.toplanguages.title',
            },
            {
                name: 'gender',
                section: 'demographics',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.gender.title',
            },
            {
                name: 'geography',
                section: 'demographics',
                requires: () => true,
                additionalCall: 'authorCountries',
                additionalCallRequires: ({queryContext}) => !!queryContext?.isSingleSavedSearch,
                getTitle: ({queryContext}) =>
                    queryContext?.isMultipleSearch
                        ? 'cards.geographyBySearch.title'
                        : 'cards.geography.title',
            },
            {
                name: 'topicWheel',
                section: 'topicWheel',
                requires: ({queryContext}) => !queryContext?.isMultipleSearch,
                getTitle: () => 'cards.topicWheel.title',
            },
        ] as AppConfigType['searchResults']['cardRules'],
        cardHeights: {
            totalMentions: 100,
            totalVolume: 323,
            mentionsHistory: 467,
            heatmap: 500,
            wordCloud: 368,
            toptopicsBySearch: 582,
            benchmark: 735,
            sentimentVolume: 409,
            sentimentVolumeBySearch: 409,
            sentimentHistory: 467,
            netSentimentHistory: 467,
            emotionVolume: 429,
            emotionHistory: 467,
            pageTypeBySearch: 450,
            topSharedURLs: 582,
            topsites: 582,
            topsitesBySearch: 582,
            topauthors: 582,
            topauthorsBySearch: 582,
            tophashtags: 582,
            tophashtagsBySearch: 582,
            topinterests: 533,
            toplanguages: 533,
            gender: 370,
            geography: 816,
            topicWheel: 453,
            totalVolumeBySearch: 444,
        },

        //How many columns are the items displayed in. Either a positive integer,
        // or a function which takes the current container width in pixels, and
        // returns a positive integer number of colums
        getNumColumns: (searches: number) => (width: number) => {
            const gutters = 4;
            const widthPerSearch = 100;
            let minWidth = widthPerSearch * 5;
            if (searches && searches > 5) {
                minWidth = widthPerSearch * searches + gutters;
            }
            return Math.max(1, Math.floor(width / minWidth)); //420 = min card width, + gutters * 2
        },

        //Options used for debouncing card repositioning.
        //A value of null means no debouncing. This is not recommended
        cardPositionDebounceOptions: {
            wait: 300, //time in milliseconds
            //leading = true mean the [wait] delay is AFTER each reposition
            //Leading = false means wait [delay] is BEFORE each reposition (each new call during the wait resets the wait)
            leading: false, //RECOMMENDED = false
        },

        //How long does the transition effect for repositioning cards take.
        //Any valid CSS time value, or null for no transition effect
        //It is strongly recommended to combine this option with debouncing,
        //and to pick a time less than or equal to the debouncing wait value
        cardTransitionTime: '300ms',

        cardLoadOptions: {
            margin: 300, //how close to being visible (in pixels) before a card's loadData function is triggered
        },

        cardFadeOptions: {
            margin: -50, //how close to being visible (in pixels) before a card fades in (can be a negative number)
        },
    },
    topTenCard: {
        pageSize: 10,
        quickSearchLimit: 10,
    },

    reports: {
        defaultHours: 8,
        defaultMinutes: 0,
        defaultTimespan: 'P1D', //Must be a value from timespanTypes
        //see frontend/public/src/reports/models/ReportSchedule.js->INTERVAL_SPECS
        timespanTypes: {
            P1D: 'daily',
            P7D: 'weekly',
            P14D: 'fortnightly',
            P1M: 'monthly',
            P3M: 'quarterly',
        },
        timezone: 'UTC', //+ moment.utc().local().format('Z');
        tableDateFormat: 'ddd, MMM DD YYYY',
    },
    showHelp: true,

    //DO NOT ACCESS THIS DIRECTLY - use the usePageTypes or access from the reducer hooks to access this value
    _basePageTypes,
    filters: filtersConfig,
    autosuggest,

    exportType: 'brandwatch',
    noEmailDomain: false,
    maxDataSources: 1000,
    //TODO: remove after limitMaxDataSources flag gets deprecated
    limitedDataSources: 100,

    multipleSearches: {
        maxMultipleSearches: 9,
    },

    // TO REPLACE TARGETETTED DATA BELOW
    dataManagement: {
        rootUrl: '/data-management',
        tabs: [
            {
                id: TargetedDataDataTypes.FacebookPage,
                info: 'dataManagement.info.facebookPages',
                label: 'dataManagement.tabs.facebookPages',
                logo: 'logo-facebook',
                title: 'dataManagement.tabs.facebookPages',
                url: '/data-management/facebook-pages',
            },
            {
                id: TargetedDataDataTypes.InstagramAccount,
                info: 'dataManagement.info.instagramAccounts',
                label: 'dataManagement.tabs.instagramAccounts',
                logo: 'logo-instagram',
                title: 'dataManagement.tabs.instagramAccounts',
                url: '/data-management/instagram-accounts',
            },
            {
                id: TargetedDataDataTypes.InstagramHashtag,
                info: 'dataManagement.info.instagramHashtags',
                label: 'dataManagement.tabs.instagramHashtags',
                logo: 'logo-instagram',
                title: 'dataManagement.tabs.instagramHashtags',
                url: '/data-management/instagram-hashtags',
            },
            {
                id: TargetedDataDataTypes.LinkedinChannel,
                info: 'dataManagement.info.linkedinPages',
                label: 'dataManagement.tabs.linkedinPages',
                logo: 'linkedin',
                title: 'dataManagement.tabs.linkedinPages',
                url: '/data-management/linkedin-pages',
            },
            {
                id: AuthenticationDataTypes.FacebookUserAuthentication,
                info: 'dataManagement.info.whosConnected',
                label: 'dataManagement.alternateLabel.whosConnected',
                logo: 'connected',
                title: 'dataManagement.tabs.whosConnected',
                url: '/data-management/whos-connected',
            },
            {
                id: AuthenticationDataTypes.LinkedinChannelAuthentication,
                info: 'dataManagement.info.whosConnected',
                label: 'dataManagement.tabs.linkedinAuthentications',
                logo: 'connected',
                title: 'dataManagement.tabs.linkedinAuthentications',
                url: '/data-management/linkedin-authentications',
            },
        ],
        selectableDataSources: [
            {
                id: SelectDataSourceTypes.ownedFacebookPagesAndInstagramAccounts,
                logo: 'facebook-and-instagram',
                title: 'dataManagement.selectableDataSources.ownedFacebookPagesAndInstagramAccounts.title',
                desc: 'dataManagement.selectableDataSources.ownedFacebookPagesAndInstagramAccounts.desc',
                requiresAuthentication: false,
            },
            {
                id: SelectDataSourceTypes.nonOwnedFacebookPages,
                logo: 'logo-facebook',
                title: 'dataManagement.selectableDataSources.nonOwnedFacebookPages.title',
                desc: 'dataManagement.selectableDataSources.nonOwnedFacebookPages.desc',
                disabledTooltip: 'dataManagement.selectableDataSources.requiresFacebookAuth',
                requiresAuthentication: true,
            },
            {
                id: SelectDataSourceTypes.ownedLinkedinPages,
                logo: 'linkedin',
                title: 'dataManagement.selectableDataSources.ownedLinkedinPages.title',
                desc: 'dataManagement.selectableDataSources.ownedLinkedinPages.desc',
                requiresAuthentication: false,
            },
            {
                id: SelectDataSourceTypes.nonOwnedInstagramAccounts,
                logo: 'logo-instagram',
                title: 'dataManagement.selectableDataSources.nonOwnedInstagramAccounts.title',
                desc: 'dataManagement.selectableDataSources.nonOwnedInstagramAccounts.desc',
                disabledTooltip: 'dataManagement.selectableDataSources.requiresFacebookAuth',
                requiresAuthentication: true,
            },
            {
                id: SelectDataSourceTypes.instagramHashtags,
                logo: 'instagram-hashtag',
                title: 'dataManagement.selectableDataSources.instagramHashtags.title',
                desc: 'dataManagement.selectableDataSources.instagramHashtags.desc',
                disabledTooltip: 'dataManagement.selectableDataSources.requiresFacebookAuth',
                requiresAuthentication: true,
            },
        ],
    },

    targettedData: {
        // TODO: This should be removed
        sourceTypes: [
            {
                id: 'linkedin-channel',
                icon: 'linkedin',
                name: 'addTargetedDataDialog.sourceTypes.linkedinChannel.name',
                description: 'addTargetedDataDialog.sourceTypes.linkedinChannel.description',
            },
            {
                id: 'facebook-owned',
                icon: 'logo-facebook',
                name: 'addTargetedDataDialog.sourceTypes.facebookOwned.name',
                description: 'addTargetedDataDialog.sourceTypes.facebookOwned.description',
            },
            {
                id: 'instagram-owned',
                icon: 'logo-instagram',
                name: 'addTargetedDataDialog.sourceTypes.instagramOwned.name',
                description: 'addTargetedDataDialog.sourceTypes.instagramOwned.description',
            },
            {
                id: 'facebook-non-owned',
                icon: 'logo-facebook',
                name: 'addTargetedDataDialog.sourceTypes.facebookNonOwned.name',
                description: 'addTargetedDataDialog.sourceTypes.facebookNonOwned.description',
            },
            {
                id: 'instagram-hashtag',
                icon: 'logo-instagram',
                name: 'addTargetedDataDialog.sourceTypes.instagramHashtag.name',
                description: 'addTargetedDataDialog.sourceTypes.instagramHashtag.description',
            },
        ],
        addTokensPath: '/data-management',
    },
    themeColors: {
        topicWheel: '#FFFFFF00',
        queryColors: [
            '#8D56A1',
            '#57A6DA',
            '#F7C400',
            '#69C80B',
            '#F04D48',
            '#143059',
            '#F37021',
            '#327C44',
            '#C85960',
            '#766499',
        ],
    },
    createReport: {
        hasExternalReport: false,
    },
    exportMentions: {
        PDF: {
            enabled: true,
            min: 1,
            defaultNum: 20,
            max: 100,
        },
        CSV: {
            enabled: true,
            min: 1,
            defaultNum: 5000,
            max: 5000,
        },
    },
    customSnackbars: {},
    hasBrandwatchLabel: true,
    datePicker: {
        hasTimezoneIcon: false,
    },
    defaultTimezone: 'UTC',

    quickSearchDateRanges: [
        {
            id: 'today',
            label: 'datepicker.today',
            start: {days: 0},
            end: {days: 0},
        },
        {
            id: 'yesterday',
            label: 'datepicker.yesterday',
            start: {days: 1},
            end: {days: 1},
        },
        {
            id: 'last7',
            label: 'datepicker.last7',
            start: {days: 6},
            end: {days: 0},
        },
        {
            id: 'last30',
            label: 'datepicker.last30',
            start: {days: 29},
            end: {days: 0},
        },
    ],

    savedSearchDateRanges: [
        {
            id: 'today',
            label: 'datepicker.today',
            start: {days: 0},
            end: {days: 0},
        },
        {
            id: 'yesterday',
            label: 'datepicker.yesterday',
            start: {days: 1},
            end: {days: 1},
        },
        {
            id: 'last7',
            label: 'datepicker.last7',
            start: {days: 6},
            end: {days: 0},
        },
        {
            id: 'last30',
            label: 'datepicker.last30',
            start: {days: 29},
            end: {days: 0},
        },
        {
            id: 'last60',
            label: 'datepicker.last60',
            start: {days: 59},
            end: {days: 0},
        },
        {
            id: 'lastYear',
            label: 'datepicker.lastYear',
            start: {days: -1, years: 1},
            end: {days: 0},
        },
    ],
    guidedSearch: {
        socialAccounts: [
            {
                selectorType: 'twitter',
                label: 'queryBuilder.twitterAccounts',
                tooltip: 'queryBuilder.twitterAccountsTooltip',
            },
            {
                selectorType: 'facebookOwned',
                label: 'queryBuilder.fbPagesOwned',
                tooltip: 'queryBuilder.ownedFbPageTooltip',
            },
            {
                selectorType: 'facebookNonOwned',
                label: 'queryBuilder.fbPagesNonOwned',
                tooltip: 'queryBuilder.nonOwnedFbPageTooltip',
            },
            {
                selectorType: 'instagramOwned',
                label: 'queryBuilder.igAccounts',
                tooltip: 'queryBuilder.igbaTooltip',
            },
            {
                selectorType: 'instagramNonOwned',
                label: 'queryBuilder.nonOwnedInstagramAccounts',
                tooltip: 'queryBuilder.nonOwnedInstagramAccountTooltip',
            },
        ],
    },

    //We could never have any data before this point (Brandwatch was founded in 2007)
    minDataDate: '2007-01-01T00:00:00.000Z',
    hasIcons4layoutSelection: false,
    initialHeadingLevel: 1,
} as const;

export default config;
