/**
 * Results Service
 */
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import upperfirst from 'lodash/upperFirst';

import {getPreviousPeriod} from 'hsi/utils/dates';
import http from 'hsi/classes/Http';
import {getSearchParams} from 'hsi/utils/url';
import {selectCardTableSort} from 'hsi/selectors';
import {QUERY_URLS, BCR_URLS} from 'hsi/constants/urlTypes';

import {LOAD_DATA_TYPE_LIMITS} from 'hsi/constants/config';

import {LinkedinChannelIdsType} from 'hsi/types/shared';
import {QueryContextType} from 'hsi/types/query';

import {
    AllFilteringState,
    APIFilterFormat,
    Aggregates,
    Breakdowns,
    DateRange,
} from 'hsi/types/filters';
import {filterStateToAPIFormat} from 'hsi/utils/filters';
import {TimezoneID} from 'hsi/utils/timezones';
import {RootReducer} from 'hsi/reducers/rootReducer';
import {isKeyOf, isStringIn} from 'hsi/utils/typeguards/shared';

const loadDataRequestAbortControllersByType: {[key: string]: AbortController} = {};
const PEAKS_MAP = {
    mentionsHistory: 'queries',
    sentimentHistory: 'sentiment',
    emotionHistory: 'emotions',
};

//if query type = quick, allowed values ar keyof BCR_URLS
//this will hopefully change when we port to the new replacement for quicksearch
export type LoadDataTypes = keyof typeof BCR_URLS | keyof typeof QUERY_URLS;

type LoadDataArgs = {
    queryContext: QueryContextType;
    type: LoadDataTypes;
    apiFilterParams: APIFilterFormat;
    dateRange: DateRange;
    additionalQueries: number[];
    cardTables: RootReducer['cardTables'];
    linkedinChannelIds: LinkedinChannelIdsType;
    cardPersistState: RootReducer['cardPersistState'];
};

type DetectPeaksArgs = {
    queryContext: QueryContextType;
    type: keyof Breakdowns;
    filters: AllFilteringState;
    additionalQueries: number[];
    linkedinChannelIds: LinkedinChannelIdsType;
    cardPersistState: any;
};

//We can hopefully remove this when we replace the quicksearch implementation
function isSavedSearchType(type: LoadDataTypes): type is keyof typeof QUERY_URLS {
    return type in QUERY_URLS;
}

export default class ResultService {
    static abortAllLoadData() {
        Object.keys(loadDataRequestAbortControllersByType).forEach((key) => {
            !!loadDataRequestAbortControllersByType[key] &&
                loadDataRequestAbortControllersByType[key].abort();

            delete loadDataRequestAbortControllersByType[key];
        });
    }

    static abortType(type: string): void {
        !!loadDataRequestAbortControllersByType[type] &&
            loadDataRequestAbortControllersByType[type].abort();

        delete loadDataRequestAbortControllersByType[type];
    }

    static async loadData({
        queryContext,
        type,
        apiFilterParams,
        dateRange,
        additionalQueries,
        cardTables,
        linkedinChannelIds,
        cardPersistState,
    }: LoadDataArgs) {
        //abort any existing call of this type
        !!loadDataRequestAbortControllersByType[type] &&
            loadDataRequestAbortControllersByType[type].abort();

        //create new abort controller
        loadDataRequestAbortControllersByType[type] = new AbortController();
        const {signal} = loadDataRequestAbortControllersByType[type];

        if (queryContext.searchType === 'quick') {
            let queryStringParams = getSearchParams(apiFilterParams, dateRange, queryContext);
            return http
                .get(
                    '/api/projects/' +
                        queryContext.projectId +
                        BCR_URLS[type as keyof typeof BCR_URLS] +
                        queryStringParams,
                    {
                        signal,
                    },
                )
                .then((res) => res.body);
        } else {
            //We can hopefully remove this when we replace the quicksearch implementation
            if (!isSavedSearchType(type)) {
                throw new Error('Unknown type value');
            }

            //TODO stop treating queryStringParams as a string
            //If this is a saved search
            let queryStringParams = getSearchParams(
                apiFilterParams,
                dateRange,
                queryContext,
                additionalQueries,
                linkedinChannelIds,
            );

            if (LOAD_DATA_TYPE_LIMITS[type as keyof typeof LOAD_DATA_TYPE_LIMITS]) {
                queryStringParams +=
                    '&limit=' + LOAD_DATA_TYPE_LIMITS[type as keyof typeof LOAD_DATA_TYPE_LIMITS];
            }

            // Nothing I love more than special rules for individual data types //
            if (['toptopicsBySearch', 'wordCloud'].includes(type)) {
                queryStringParams += '&metrics=sentiment,gender,trending,timeSeries';
                queryStringParams += '&extract=' + queryContext.additionalParams.extract;
                queryStringParams += '&orderBy=' + queryContext.additionalParams.orderBy;
            }

            if (['topauthors'].includes(type)) {
                queryStringParams +=
                    '&orderBy=' +
                    tableSortKeyToApi(
                        selectCardTableSort('topauthors', queryContext?.savedSearchId)({cardTables})
                            ?.sortKey,
                    );
            } else if (type === 'tophashtags') {
                const sort = selectCardTableSort(
                    'tophashtags',
                    queryContext?.savedSearchId,
                )({cardTables});

                if (sort?.sortKey && sort.sortKey !== 'value') {
                    //defaults to volume, and 'value' = 'volume'
                    queryStringParams += `&orderBy=${sort.sortKey}`;
                }
            }

            if (isStringIn(type, ['benchmark', 'benchmarkBySearch'])) {
                const prevQueryStringParams = getSearchParams(
                    apiFilterParams,
                    {
                        ...getPreviousPeriod(
                            dateRange.startDate,
                            dateRange.endDate,
                            (dateRange?.timezone as TimezoneID) ||
                                (queryContext?.timezone as TimezoneID),
                        ),
                        relativeRange: null,
                        timezone: dateRange.timezone,
                    },
                    queryContext,
                    additionalQueries,
                    linkedinChannelIds,
                );

                const baseQuery =
                    type === 'benchmark'
                        ? QUERY_URLS[type].replace('{breakdown}', cardPersistState[type]?.breakdown)
                        : QUERY_URLS[type];

                return Promise.all([
                    http
                        .get(
                            '/api/projects/' +
                                queryContext.projectId +
                                baseQuery +
                                queryStringParams,
                            {signal},
                        )
                        .then((res) => ({
                            ['current' + upperfirst(res.body.dimension1)]: res.body,
                        })),
                    http
                        .get(
                            '/api/projects/' +
                                queryContext.projectId +
                                baseQuery +
                                prevQueryStringParams,
                            {signal},
                        )
                        .then((res) => ({
                            ['previous' + upperfirst(res.body.dimension1)]: res.body,
                        })),
                ]).then((results) => results.reduce((acc, curr) => ({...acc, ...curr}), {}));
            } else if (isStringIn(type, ['totalVolume', 'totalVolumeBySearch'])) {
                /*
                    Mentions volume over time and the showNoData general message depend on the mentions
                    volume. This is a workaround to be sure volume gets called everytime no matter what
                    */
                const allProxyMetrics = ['avgFollowers', 'retweetRate'] as const;
                const {projectId} = queryContext;
                const metrics = ['volume', ...queryContext.additionalParams.metrics];
                const bwApiCalls = omit(pick(QUERY_URLS[type], metrics), allProxyMetrics);

                const queriesMap: string[] = Object.values(bwApiCalls).map((url: string) =>
                    url.replace('{breakdown}', cardPersistState[type as keyof Breakdowns]?.breakdown as string),
                );

                const prevQueryStringParams = getSearchParams(
                    apiFilterParams,
                    {
                        ...getPreviousPeriod(
                            dateRange.startDate,
                            dateRange.endDate,
                            (dateRange?.timezone as TimezoneID) ||
                                (queryContext?.timezone as TimezoneID),
                        ),
                        relativeRange: null,
                        timezone: dateRange?.timezone,
                    },
                    queryContext,
                    additionalQueries,
                    linkedinChannelIds,
                );

                //TODO better typing for the returned value here
                const proxyCalls = allProxyMetrics.reduce<Promise<any>[]>((output, metric) => {
                    if (metric in QUERY_URLS[type]) {
                        output.push(
                            http
                                .get(
                                    `${QUERY_URLS[type][metric]}${queryStringParams}&projectId=${projectId}`,
                                    {signal},
                                )
                                .then((results) => ({
                                    [metric]: {current: results.body},
                                })),
                        );

                        output.push(
                            http
                                .get(
                                    `${QUERY_URLS[type][metric]}${prevQueryStringParams}&projectId=${projectId}`,
                                    {signal},
                                )
                                .then((results) => ({
                                    [metric]: {previous: results.body},
                                })),
                        );
                    }

                    return output;
                }, []);

                const currentCalls = queriesMap.map((urlChunk) =>
                    http
                        .get(`/api/projects/${projectId}${urlChunk}${queryStringParams}`, {signal})
                        .then((results) => ({
                            [results.body.aggregate]: {current: results.body},
                        })),
                );

                const previousCalls: Promise<any>[] = queriesMap.map((urlChunk) =>
                    http
                        .get(`/api/projects/${projectId}${urlChunk}${prevQueryStringParams}`, {
                            signal,
                        })
                        .then((results) => ({
                            [results.body.aggregate]: {previous: results.body},
                        })),
                );

                return Promise.all(currentCalls.concat(previousCalls).concat(proxyCalls)).then(
                    ([x, ...rest]) => merge(x, ...rest),
                );
            } else {
                let baseQuery = QUERY_URLS[type] as string;

                if (isKeyOf(type, cardPersistState)) {
                    baseQuery = baseQuery.replace('{breakdown}', cardPersistState[type as keyof Breakdowns].breakdown);
                }

                if (isKeyOf(type, cardPersistState)) {
                    baseQuery = baseQuery.replace('{aggregate}', cardPersistState[type as keyof Aggregates].aggregate);
                }

                return http
                    .get(
                        '/api/projects/' + queryContext.projectId + baseQuery + queryStringParams,
                        {signal},
                    )
                    .then((res) => res.body);
            }
        }
    }

    static async detectPeaks({
        queryContext,
        type,
        filters,
        additionalQueries,
        linkedinChannelIds,
        cardPersistState,
    }: DetectPeaksArgs) {
        let dimension = PEAKS_MAP[type as keyof typeof PEAKS_MAP];
        let timeDimension = cardPersistState[type].breakdown;
        let queryStringParams = getSearchParams(
            filterStateToAPIFormat(filters.filters, filters.allFiltersConfig),
            filters.dateRange,
            queryContext,
            additionalQueries,
            linkedinChannelIds,
        );

        let peakParams = '&peaksLimit=20';

        return http
            .get(
                `/api/projects/${queryContext.project.id}/data/peaks/${timeDimension}/${dimension}` +
                    queryStringParams +
                    peakParams,
            )
            .then((res) => res.body);
    }
}

//Helpers
const sortKeyToApi = {
    value: 'volume',
    reachEstimate: 'reachEstimate',
    twitterFollowers: 'reachEstimate',
} as const;

const tableSortKeyToApi = (sortKey: string) =>
    sortKeyToApi[sortKey as keyof typeof sortKeyToApi] || 'volume';
