import React, {forwardRef, useCallback} from 'react';

//Components
import TopMeasureBySearch from 'hsi/components/Cards/TopMeasureBySearch';
import NameCell from 'hsi/components/CardTable/v2/NameCell';

//Utils
import {drillInFilters} from 'hsi/utils/filters';

//Other
import tns from 'hsi/i18n/tns';
import useIsSavedSearch from 'hsi/hooks/useIsSavedSearch';
const t = tns('cards.topauthorsBySearch');

//Consts
const type = 'topauthorsBySearch';
const dataSelector = (state) => state.chart[type];

const nameField = {
    label: t('nameFieldLabel'),
    format: (authorName) => <NameCell name={authorName} />,
};

//The component
const TopAuthorsBySearch = forwardRef(function TopAuthorsBySearch(props, ref) {
    const isSavedSearch = useIsSavedSearch();

    const drillInData = useCallback(
        ({name: authorName}) => ({
            dates: null,
            filter: drillInFilters.author(authorName, undefined, isSavedSearch),
            label: authorName,
            tracking: {
                name: 'cardDrilledIn',
                type,
                value: authorName,
            },
            type,
        }),
        [isSavedSearch],
    );

    return (
        <TopMeasureBySearch
            type={type}
            dataSelector={dataSelector}
            drillInData={drillInData}
            nameField={nameField}
            ref={ref}
            {...props}
        />
    );
});

export default TopAuthorsBySearch;
