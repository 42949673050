import {useState, forwardRef, useCallback, ReactNode} from 'react';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

//Components
import ConditionalWrap from 'hsi/components/ConditionalWrap';
import ExportMentionsDialog from './ExportMentionsDialog';
import IconRouter from 'hsi/components/IconRouter';
import Popover from 'hsi/components/Popover';
import SaveSearchTooltipDialog from 'hsi/components/SaveSearchTooltipDialog';
import Tooltip from 'hsi/components/Tooltip';

//Hooks
import useConfig from 'hsi/hooks/useConfig';
import useQueryContext from 'hsi/hooks/useQueryContext';

//Other
import {T} from 'hsi/i18n';
import useStyles from './styles';
import useIsViewOnly from 'hsi/hooks/useIsViewOnly';
import {useAppSelector} from 'hsi/hooks/useRedux';
import {QueryContextType} from 'hsi/types/query';

//Just the button
const ExportButton = forwardRef<HTMLButtonElement, Parameters<typeof IconButton>[0]>(
    ({disabled, onClick, className, ...rest}, ref) => {
        const {classes} = useStyles();

        return (
            <IconButton
                className={cn(classes.exportMenuBtn, disabled && 'disabled', className)}
                aria-disabled={disabled}
                aria-label={T('mentionsContainer.export.exportMentions')}
                onClick={disabled ? undefined : onClick}
                {...rest}
                ref={ref}
                size="large">
                <IconRouter name="cloud-download" className={classes.exportMenuIcon} />
                <span className={classes.exportMenuLabel} aria-hidden>
                    {T('mentionsContainer.export.export')}
                </span>
            </IconButton>
        );
    },
);

type ExportMenuProps = {
    isSavedSearch: boolean;
};

//The component
export default function ExportMenu({isSavedSearch}: ExportMenuProps) {
    const {classes} = useStyles();
    const {appSource} = useConfig();
    const exportsEnabled = useAppSelector(
        (state) => state.user.account.client.dailyMentionExportLimit > 0,
    );
    const canSaveSearch = useAppSelector((state) => state.user.canSaveSearch);
    const isViewOnly = useIsViewOnly();

    //new state
    const [showDialog, setShowDialog] = useState(false);

    const queryContext = useQueryContext();

    const wrapFunc = useCallback(
        (children: ReactNode) =>
            appSource === 'HSI' ? (
                <Tooltip noAria tooltip={T('mentionsContainer.export.exportMentions')}>
                    {children}
                </Tooltip>
            ) : (
                children
            ),
        [appSource],
    );

    if (!exportsEnabled) {
        //Show export button, with 'pricing plan' popup
        return (
            <Popover
                portal
                content={
                    <div className={classes.exportsDisabled}>
                        {T('mentionsContainer.export.exportDisabled')}
                    </div>
                }
            >
                <ExportButton />
            </Popover>
        );
    } else if (!isSavedSearch && canSaveSearch) {
        //show Export button as disabled, with 'save search' tooltip
        return (
            <SaveSearchTooltipDialog
                distance={5}
                messageId="export"
                placement="bottom"
                theme={'light'}
            >
                <ExportButton disabled />
            </SaveSearchTooltipDialog>
        );
    } else if (!isSavedSearch && !canSaveSearch) {
        //show nothing
        return null;
    }

    //Show export button with on click menu
    return (
        <>
            <ConditionalWrap wrap={wrapFunc}>
                <ExportButton onClick={() => setShowDialog(true)} />
            </ConditionalWrap>
            <ExportMentionsDialog
                queryContext={showDialog ? (queryContext as QueryContextType) : undefined}
                onClose={() => setShowDialog(false)}
                checkExportAllowance={!isViewOnly}
            />
        </>
    );
}
